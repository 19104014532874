<div class="item-container">
  @for (slot of slots; track slot) {
  <div
    class="item-slot"
    [class.item-background]="(slot.template || slot.slot) && slot.canShow()"
  >
    @if (!slot.template) {
    <ng-container
      *ngTemplateOutlet="
        itemGenericTemplate;
        context: createContext(slot, player())
      "
    ></ng-container>
    } @if (slot.template === 'coin') {
    <ng-container
      *ngTemplateOutlet="
        itemCoinTemplate;
        context: createContext(slot, player())
      "
    ></ng-container>
    } @if (slot.template === 'hand') {
    <ng-container
      *ngTemplateOutlet="
        itemHandTemplate;
        context: createContext(slot, player())
      "
    ></ng-container>
    }
  </div>
  }
</div>

<ng-template #itemGenericTemplate let-slot="slot" let-player="player">
  @if (!(player.items.equipment || {})[slot.slot]) {
  <span class="item-slot-bg-text">{{ slot.name }}</span>
  } @if ((player.items.equipment || {})[slot.slot]) {
  <app-item
    [viewingPlayer]="player"
    [showCount]="slot.slot === 'ammo'"
    [showOunces]="slot.slot === 'potion'"
    [item]="player.items.equipment[slot.slot]"
  ></app-item>
  }
</ng-template>

<ng-template #itemHandTemplate let-slot="slot" let-player="player">
  @if (!(player.items.equipment || {})[slot.slot]) {
  <span class="item-slot-bg-text">{{ slot.name }}</span>
  } @if ((player.items.equipment || {})[slot.slot]) {
  <app-item
    [showOunces]="true"
    [showValue]="canShowValue(slot.slot, player.items.equipment[slot.slot])"
    [viewingPlayer]="player"
    [item]="player.items.equipment[slot.slot]"
  ></app-item>
  }
</ng-template>

<ng-template #itemCoinTemplate let-slot="slot" let-player="player">
  @if (!hideGold()) {
  <app-item
    [showValue]="true"
    [viewingPlayer]="player"
    [item]="{
      name: 'Gold Coin',
      mods: {
        currency: 'gold',
        itemClass: 'Coin',
        value: player.currency.gold
      },
      uuid: ''
    }"
  ></app-item>
  }
</ng-template>
