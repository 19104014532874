{
    "Chase": {
        "macro": "chase",
        "mode": "lockActivation",
        "isDefault": true,
        "name": "Chase",
        "icon": "hooded-figure",
        "color": "#000000",
        "tooltipDesc": "Move towards a target."
    },
    "Climb": {
        "macro": "climbup",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Climb",
        "icon": "mountain-climbing",
        "color": "#D2691E",
        "tooltipDesc": "Climb up or down on this tile."
    },
    "Look At": {
        "name": "Look At",
        "macro": "consider",
        "color": "#8A6948",
        "mode": "clickToTarget",
        "tooltipDesc": "Look at a target, getting a full description of them and their gear.",
        "isDefault": true,
        "icon": "look-at"
    },
    "Show Skills": {
        "macro": "show skills",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Show Skills",
        "icon": "checklist",
        "color": "#000000",
        "tooltipDesc": "See all of your skills and skill levels in text form."
    },
    "Show Stats": {
        "macro": "show stats",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Show Stats",
        "icon": "checklist",
        "color": "#000000",
        "tooltipDesc": "See all of your stats and their base level in text form."
    },
    "Stairs": {
        "macro": "up",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Stairs",
        "icon": "3d-stairs",
        "color": "#404040",
        "tooltipDesc": "Go up or down stairs on this tile."
    },
    "Clear Buffer": {
        "macro": "!clear",
        "key": "ESCAPE",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Clear Buffer",
        "icon": "ultrasound",
        "color": "#000000",
        "tooltipDesc": "Clear the command buffer, negating all future commands."
    },
    "Drink": {
        "macro": "!drink",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Drink",
        "icon": "potion-ball",
        "color": "#2020B2",
        "tooltipDesc": "Drink a potion from your potion slot."
    },
    "Look At Ground": {
        "macro": "~look",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Look At Ground",
        "icon": "semi-closed-eye",
        "color": "#665600",
        "tooltipDesc": "Look at the ground without searching containers."
    },
    "Restore": {
        "macro": "restore",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Restore",
        "icon": "quicksand",
        "color": "#8A6948",
        "tooltipDesc": "Revive and go back to a respawn point."
    },
    "Search": {
        "macro": "~search",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Search",
        "icon": "cash",
        "color": "#665600",
        "tooltipDesc": "Look at the ground and search containers for items."
    },
    "Attack": {
        "macro": "attack",
        "mode": "lockActivation",
        "isDefault": true,
        "name": "Attack",
        "icon": "blade-drag",
        "color": "#530000",
        "tooltipDesc": "Physically attack a target with the item in your right hand."
    },
    "Hide": {
        "macro": "hide",
        "mode": "autoActivate",
        "isDefault": true,
        "name": "Hide",
        "icon": "hidden",
        "color": "#cccccc",
        "bgColor": "#000000",
        "tooltipDesc": "Hide in the shadows."
    },
    "Kick": {
        "macro": "kick",
        "mode": "lockActivation",
        "isDefault": true,
        "name": "Kick",
        "icon": "barefoot",
        "color": "#530000",
        "tooltipDesc": "Physically attack a target with your boots."
    },
    "Steal": {
        "macro": "steal",
        "mode": "lockActivation",
        "isDefault": true,
        "name": "Steal",
        "icon": "take-my-money",
        "color": "#7F6B00",
        "tooltipDesc": "Steal gold and items from your target."
    },
    "Throw": {
        "macro": "throw right",
        "mode": "lockActivation",
        "isDefault": true,
        "name": "Throw",
        "icon": "thrown-spear",
        "color": "#530000",
        "tooltipDesc": "Throw an item at a target."
    },
    "Memorize": {
        "macro": "memorize",
        "mode": "clickToActivate",
        "isDefault": true,
        "name": "Memorize",
        "icon": "brain",
        "color": "#000",
        "tooltipDesc": "Memorize your current location for teleporting."
    },
    "Forget": {
        "macro": "forget",
        "mode": "clickToActivate",
        "isDefault": true,
        "name": "Forget",
        "icon": "brain",
        "color": "#00a",
        "tooltipDesc": "Forget one of your previous teleport locations."
    },
    "Empty": {
        "macro": "empty right",
        "mode": "clickToActivate",
        "isDefault": true,
        "name": "Empty",
        "icon": "square-bottle",
        "color": "#000",
        "tooltipDesc": "Empty a bottle in your hands."
    },
    "Fill": {
        "macro": "fill right",
        "mode": "clickToActivate",
        "isDefault": true,
        "name": "Fill",
        "icon": "square-bottle",
        "color": "#00a",
        "tooltipDesc": "Fill a bottle based on the liquid you are swimming in."
    },
    "Quaff": {
        "macro": "quaff",
        "mode": "clickToActivate",
        "isDefault": true,
        "name": "Quaff",
        "icon": "drinking",
        "color": "#00a",
        "tooltipDesc": "Drink the liquid you are swimming in."
    },
    "Charge": {
        "macro": "charge",
        "mode": "lockActivation",
        "isDefault": true,
        "name": "Charge",
        "icon": "running-ninja",
        "color": "#530000",
        "tooltipDesc": "Charge towards an enemy, attacking with the item in your right hand."
    },
    "Alchemy": {
        "macro": "tradeskill Alchemy",
        "mode": "autoActivate",
        "name": "Alchemy",
        "icon": "apothecary",
        "color": "#0a0",
        "tooltipDesc": "Open the alchemy window."
    },
    "Metalworking": {
        "macro": "tradeskill Metalworking",
        "mode": "autoActivate",
        "name": "Metalworking",
        "icon": "blacksmith",
        "color": "#a00",
        "tooltipDesc": "Open the metalworking window."
    },
    "Spellforging": {
        "macro": "tradeskill Spellforging",
        "mode": "autoActivate",
        "name": "Spellforging",
        "icon": "minerals",
        "color": "#f0f",
        "tooltipDesc": "Open the spellforging window."
    },
    "Disenchant": {
        "macro": "disenchant",
        "mode": "autoActivate",
        "name": "Disenchant",
        "icon": "sparkles",
        "color": "#f0f",
        "tooltipDesc": "Disenchant the item in your right hand or matching items in your sack."
    },
    "Gemcrafting": {
        "macro": "tradeskill Gemcrafting",
        "mode": "autoActivate",
        "name": "Gemcrafting",
        "icon": "gem-chain",
        "color": "#aa7d00",
        "tooltipDesc": "Open the gemcrafting window."
    },
    "Shatter": {
        "macro": "shatter",
        "mode": "autoActivate",
        "name": "Shatter",
        "icon": "gems",
        "color": "#aa7d00",
        "tooltipDesc": "Break the item in your right hand or matching items in your sack."
    },
    "Weavefabricating": {
        "macro": "tradeskill Weavefabricating",
        "mode": "autoActivate",
        "name": "Weavefabricating",
        "icon": "robe",
        "color": "#aa00a2",
        "tooltipDesc": "Open the weavefabricating window."
    },
    "Tear": {
        "macro": "tear",
        "mode": "autoActivate",
        "name": "Tear",
        "icon": "bowie-knife",
        "color": "#aa00a2",
        "tooltipDesc": "Tear the item in your right hand or matching items in your sack."
    },
    "Foodmaking": {
        "macro": "tradeskill Foodmaking",
        "mode": "autoActivate",
        "name": "Foodmaking",
        "icon": "meat",
        "color": "#00f",
        "tooltipDesc": "Open the foodmaking window."
    },
    "ImbueFrost": {
        "name": "ImbueFrost",
        "macro": "cast imbuefrost",
        "color": "#00b",
        "mode": "clickToTarget",
        "tooltipDesc": "Augment physical attacks to do bonus ice damage. Cost: 100 MP",
        "icon": "magic-palm",
        "for": "ImbueFrost"
    },
    "ImbueFlame": {
        "name": "ImbueFlame",
        "macro": "cast imbueflame",
        "color": "#a00",
        "mode": "clickToTarget",
        "tooltipDesc": "Augment physical attacks to do bonus fire damage. Cost: 100 MP",
        "icon": "magic-palm",
        "for": "ImbueFlame"
    },
    "ImbueEnergy": {
        "name": "ImbueEnergy",
        "macro": "cast imbueenergy",
        "color": "#a0a",
        "mode": "clickToTarget",
        "tooltipDesc": "Augment physical attacks to do bonus energy damage. Cost: 100 MP",
        "icon": "magic-palm",
        "for": "ImbueEnergy"
    },
    "DarkVision": {
        "name": "DarkVision",
        "macro": "cast darkvision",
        "color": "#000",
        "mode": "clickToTarget",
        "tooltipDesc": "See in the dark. Cost: 25 MP",
        "icon": "angry-eyes",
        "for": "DarkVision"
    },
    "TrueSight": {
        "name": "TrueSight",
        "macro": "cast truesight",
        "color": "#00a",
        "mode": "clickToTarget",
        "tooltipDesc": "See hidden walls and invisible creatures. Cost: 25 MP",
        "icon": "all-seeing-eye",
        "for": "TrueSight"
    },
    "EagleEye": {
        "name": "EagleEye",
        "macro": "cast eagleeye",
        "color": "#f00",
        "mode": "clickToTarget",
        "tooltipDesc": "See through trees and waters. Cost: 25 MP",
        "icon": "dead-eye",
        "for": "EagleEye"
    },
    "SecondWind": {
        "name": "SecondWind",
        "macro": "cast secondwind",
        "color": "#a0a",
        "mode": "clickToTarget",
        "tooltipDesc": "Prevent gear loss on strip/eat. Cost: 100 MP",
        "icon": "wing-cloak",
        "for": "SecondWind"
    },
    "FleetOfFoot": {
        "name": "FleetOfFoot",
        "macro": "cast fleetoffoot",
        "color": "#0aa",
        "mode": "clickToTarget",
        "tooltipDesc": "Take less damage from falls and be immune to prone. Cost: 25 MP",
        "icon": "wingfoot",
        "for": "FleetOfFoot"
    },
    "Invisibility": {
        "name": "Invisibility",
        "macro": "cast invisibility",
        "color": "#000",
        "mode": "clickToTarget",
        "tooltipDesc": "Make your target invisible. Cost: 100 MP",
        "icon": "invisible",
        "for": "Invisibility"
    },
    "BarFire": {
        "name": "BarFire",
        "macro": "cast barfire",
        "color": "#dc143c",
        "mode": "clickToTarget",
        "tooltipDesc": "Resist some fire damage. Cost: 20 MP",
        "icon": "rosa-shield",
        "for": "BarFire"
    },
    "BarFrost": {
        "name": "BarFrost",
        "macro": "cast barfrost",
        "color": "#000080",
        "mode": "clickToTarget",
        "tooltipDesc": "Resist some frost damage. Cost: 20 MP",
        "icon": "rosa-shield",
        "for": "BarFrost"
    },
    "BarWater": {
        "name": "BarWater",
        "macro": "cast barwater",
        "color": "#208aec",
        "mode": "clickToTarget",
        "tooltipDesc": "Resist some water damage. Cost: 20 MP",
        "icon": "rosa-shield",
        "for": "BarWater"
    },
    "BarNecro": {
        "name": "BarNecro",
        "macro": "cast barnecro",
        "color": "#0a0",
        "mode": "clickToTarget",
        "tooltipDesc": "Resist some necrotic, poison, and disease damage. Cost: 20 MP",
        "icon": "rosa-shield",
        "for": "BarNecro"
    },
    "Aid": {
        "name": "Aid",
        "macro": "cast aid",
        "color": "#00c",
        "mode": "clickToTarget",
        "tooltipDesc": "Increased DEX and offensive capabilities. Cost: 100 MP",
        "icon": "three-friends",
        "for": "Aid"
    },
    "Regen": {
        "name": "Regen",
        "macro": "cast regen",
        "color": "#00a",
        "mode": "clickToTarget",
        "tooltipDesc": "Grant regeneration to a single target. Cost: 25 MP.",
        "icon": "star-swirl",
        "for": "Regen"
    },
    "Protection": {
        "name": "Protection",
        "macro": "cast protection",
        "color": "#a0a",
        "mode": "clickToTarget",
        "tooltipDesc": "Resist some physical damage. Cost: 65 MP",
        "icon": "energy-shield",
        "for": "Protection"
    },
    "Absorption": {
        "name": "Absorption",
        "macro": "cast absorption",
        "color": "#a0a",
        "mode": "clickToTarget",
        "tooltipDesc": "Resist some magical damage. Cost: 65 MP",
        "icon": "magic-swirl",
        "for": "Absorption"
    },
    "Haste": {
        "name": "Haste",
        "macro": "cast haste",
        "color": "#0a0",
        "mode": "clickToTarget",
        "tooltipDesc": "Act twice as fast. Cost: 200 MP",
        "icon": "time-trap",
        "for": "Haste"
    },
    "PowerwordBarNecro": {
        "macro": "powerword barnecro",
        "mode": "autoActivate",
        "name": "PowerwordBarNecro",
        "icon": "rosa-shield",
        "color": "#1b390e",
        "tooltipDesc": "Give your entire party necrotic resistance. Cost: 150 MP.",
        "for": "PowerwordBarNecro"
    },
    "PowerwordBarFire": {
        "macro": "powerword barfire",
        "mode": "autoActivate",
        "name": "PowerwordBarFire",
        "icon": "rosa-shield",
        "color": "#dc143c",
        "tooltipDesc": "Give your entire party fire resistance. Cost: 150 MP.",
        "for": "PowerwordBarFire"
    },
    "PowerwordBarFrost": {
        "macro": "powerword barfrost",
        "mode": "autoActivate",
        "name": "PowerwordBarFrost",
        "icon": "rosa-shield",
        "color": "#000080",
        "tooltipDesc": "Give your entire party frost resistance. Cost: 150 MP.",
        "for": "PowerwordBarFrost"
    },
    "Vital Essence": {
        "name": "Vital Essence",
        "macro": "cast vitalessence",
        "color": "#0a0",
        "mode": "clickToTarget",
        "tooltipDesc": "Increased HP and AC. Cost: 100 MP",
        "icon": "bell-shield",
        "for": "VitalEssence"
    },
    "Holy Aura": {
        "name": "Holy Aura",
        "macro": "cast holyaura",
        "color": "#aa0",
        "mode": "clickToTarget",
        "tooltipDesc": "Make a single target invulnerable to some damage. Cost: 150 MP.",
        "icon": "aura",
        "for": "HolyAura"
    },
    "Autoheal": {
        "name": "Autoheal",
        "macro": "cast autoheal",
        "color": "#00c",
        "mode": "clickToTarget",
        "tooltipDesc": "Automatically heal when your HP gets low. Cost: 100 MP",
        "icon": "self-love",
        "for": "Autoheal"
    },
    "MagicMirror": {
        "name": "MagicMirror",
        "macro": "cast magicmirror",
        "color": "#a0a",
        "mode": "clickToTarget",
        "tooltipDesc": "Increase your chance to reflect magical damage. Cost: 125 MP",
        "icon": "mirror-mirror",
        "for": "MagicMirror"
    },
    "FrostspikesAura": {
        "name": "FrostspikesAura",
        "macro": "cast frostspikesaura",
        "color": "#000080",
        "mode": "clickToTarget",
        "tooltipDesc": "Make a single target reflect physical damage and counter it with ice. Cost: 100 MP.",
        "icon": "barbed-coil",
        "for": "FrostspikesAura"
    },
    "FirethornsAura": {
        "name": "FirethornsAura",
        "macro": "cast firethornsaura",
        "color": "#f00",
        "mode": "clickToTarget",
        "tooltipDesc": "Make a single target reflect physical damage and counter it with fire. Cost: 100 MP.",
        "icon": "barbed-coil",
        "for": "FirethornsAura"
    },
    "Lifepool": {
        "name": "Lifepool",
        "macro": "cast lifepool",
        "color": "#f00",
        "mode": "clickToTarget",
        "tooltipDesc": "Increase the maximum health of the target. Cost: 100 MP.",
        "icon": "ball-glow",
        "for": "Lifepool"
    },
    "Manapool": {
        "name": "Manapool",
        "macro": "cast manapool",
        "color": "#00f",
        "mode": "clickToTarget",
        "tooltipDesc": "Increase the maximum mana of the target. Cost: 100 MP.",
        "icon": "ball-glow",
        "for": "Manapool"
    },
    "Barkskin": {
        "name": "Barkskin",
        "macro": "cast barkskin",
        "color": "#964b00",
        "mode": "clickToTarget",
        "tooltipDesc": "Increase the physical resistance of the target. Cost: 35 MP.",
        "icon": "tree-branch",
        "for": "Barkskin"
    },
    "BulgingMuscles": {
        "name": "BulgingMuscles",
        "macro": "cast bulgingmuscles",
        "color": "#f00",
        "mode": "clickToTarget",
        "tooltipDesc": "Increase the physical damage of the target. Cost: 50 MP.",
        "icon": "strong",
        "for": "BulgingMuscles"
    },
    "Spellshield": {
        "name": "Spellshield",
        "macro": "cast spellshield",
        "color": "#f0f",
        "mode": "clickToTarget",
        "tooltipDesc": "Increase the saving throw capabilities of the target. Cost: 50 MP.",
        "icon": "shield-echoes",
        "for": "Spellshield"
    },
    "DeftStrikes": {
        "name": "DeftStrikes",
        "macro": "cast deftstrikes",
        "color": "#f00",
        "mode": "clickToTarget",
        "tooltipDesc": "Increase the number of attacks for the target. Cost: 50 MP.",
        "icon": "sword-brandish",
        "for": "DeftStrikes"
    },
    "CombatPower": {
        "name": "CombatPower",
        "macro": "cast combatpower",
        "color": "#f00",
        "mode": "clickToTarget",
        "tooltipDesc": "Increase the defense and offense of the target. Cost: 50 MP.",
        "icon": "swords-emblem",
        "for": "CombatPower"
    },
    "ThreateningAura": {
        "name": "ThreateningAura",
        "macro": "cast threateningaura",
        "color": "#f00",
        "mode": "clickToTarget",
        "tooltipDesc": "Increase the threat generation of the target. Cost: 50 MP.",
        "icon": "aura",
        "for": "ThreateningAura"
    },
    "Antidote": {
        "mode": "clickToTarget",
        "name": "Antidote",
        "macro": "cast antidote",
        "icon": "miracle-medecine",
        "color": "#0a0",
        "tooltipDesc": "Cure poison from a single target. Cost: 10 MP",
        "for": "Antidote"
    },
    "Vision": {
        "mode": "clickToTarget",
        "name": "Vision",
        "macro": "cast vision",
        "icon": "evil-eyes",
        "color": "#0a0",
        "tooltipDesc": "Cure blindness from a single target. Cost: 10 MP",
        "for": "Vision"
    },
    "Poison": {
        "name": "Poison",
        "macro": "cast poison",
        "color": "#0a0",
        "mode": "clickToTarget",
        "tooltipDesc": "Poison a single target. Cost: 25 MP.",
        "icon": "poison-gas",
        "for": "Poison"
    },
    "Venom": {
        "name": "Venom",
        "macro": "cast venom",
        "color": "#0a0",
        "mode": "clickToTarget",
        "tooltipDesc": "Inflict venom on a single target. Cost: 30 MP.",
        "icon": "dripping-goo",
        "for": "Venom"
    },
    "Disease": {
        "name": "Disease",
        "macro": "cast disease",
        "color": "#0c0",
        "mode": "clickToTarget",
        "tooltipDesc": "Inflict disease on a single target. Cost: 25 MP.",
        "icon": "death-juice",
        "for": "Disease"
    },
    "Plague": {
        "name": "Plague",
        "macro": "cast plague",
        "color": "#0c0",
        "mode": "clickToTarget",
        "tooltipDesc": "Inflict plague on a single target. Cost: 15 MP.",
        "icon": "death-zone",
        "for": "Plague"
    },
    "Stun": {
        "name": "Stun",
        "macro": "cast stun",
        "color": "#990",
        "mode": "clickToTarget",
        "tooltipDesc": "Stun a single target. Cost: 25 MP.",
        "icon": "knockout",
        "for": "Stun"
    },
    "Snare": {
        "name": "Snare",
        "macro": "cast snare",
        "color": "#0a0",
        "mode": "clickToTarget",
        "tooltipDesc": "Snare a single target. Cost: 25 MP.",
        "icon": "light-thorny-triskelion",
        "for": "Snare"
    },
    "Daze": {
        "name": "Daze",
        "macro": "cast daze",
        "color": "#0aa",
        "mode": "clickToTarget",
        "tooltipDesc": "Daze a single target. Cost: 25 MP.",
        "icon": "knockout",
        "for": "Daze"
    },
    "Blind": {
        "name": "Blind",
        "macro": "cast blind",
        "color": "#900",
        "mode": "clickToTarget",
        "tooltipDesc": "Blind a single target. Cost: 25 MP.",
        "icon": "evil-eyes",
        "for": "Blind"
    },
    "Debilitate": {
        "name": "Debilitate",
        "macro": "cast debilitate",
        "color": "#a00",
        "mode": "clickToTarget",
        "tooltipDesc": "Make all hidden attacks against your target count as backstabs. Cost: 100 MP",
        "icon": "one-eyed",
        "for": "Debilitate"
    },
    "Fear": {
        "name": "Fear",
        "macro": "cast fear",
        "color": "#a00",
        "mode": "clickToTarget",
        "tooltipDesc": "Make your target run away from you. Cost: 50 MP",
        "icon": "screaming",
        "for": "Fear"
    },
    "Afflict": {
        "mode": "lockActivation",
        "name": "Afflict",
        "macro": "cast afflict",
        "icon": "bolas",
        "color": "#bd5900",
        "tooltipDesc": "Inflict necrotic damage on a single target. Cost: 10 MP.",
        "for": "Afflict"
    },
    "Magic Missile": {
        "name": "Magic Missile",
        "macro": "cast magicmissile",
        "color": "#a0a",
        "mode": "lockActivation",
        "tooltipDesc": "Inflict energy damage on a single target. Cost: 5 MP.",
        "icon": "missile-swarm",
        "for": "MagicMissile"
    },
    "Magic Bolt": {
        "name": "Magic Bolt",
        "mode": "lockActivation",
        "macro": "cast magicbolt",
        "icon": "burning-dot",
        "color": "#a0a",
        "tooltipDesc": "Inflict energy damage on a single target. Cost: 20 MP",
        "for": "MagicBolt"
    },
    "Combust": {
        "mode": "lockActivation",
        "name": "Combust",
        "macro": "cast combust",
        "icon": "burning-dot",
        "color": "#dc143c",
        "tooltipDesc": "Inflict fire damage on a single target. Cost: 10 MP",
        "for": "Combust"
    },
    "Hail": {
        "mode": "lockActivation",
        "name": "Hail",
        "macro": "cast hail",
        "icon": "burning-dot",
        "color": "#000080",
        "tooltipDesc": "Inflict ice damage on a single target. Cost: 10 MP",
        "for": "Hail"
    },
    "Holy Fire": {
        "name": "Holy Fire",
        "mode": "lockActivation",
        "macro": "cast holyfire",
        "icon": "fireflake",
        "color": "#f50",
        "tooltipDesc": "Inflict fire damage on a single target and cast light. Cost: 25 MP",
        "for": "HolyFire"
    },
    "Energy Wave": {
        "name": "Energy Wave",
        "mode": "autoActivate",
        "macro": "cast energywave",
        "icon": "beams-aura",
        "color": "#a0a",
        "tooltipDesc": "Inflict energy damage in a 5x5 AoE from your location and push each target. Cost: 35 MP.",
        "for": "EnergyWave"
    },
    "Antipode": {
        "mode": "lockActivation",
        "name": "Antipode",
        "macro": "cast antipode",
        "icon": "frostfire",
        "color": "#505",
        "tooltipDesc": "Inflict ice and fire damage on a single target. Cost: 25 MP",
        "for": "Antipode"
    },
    "Ice Mist": {
        "name": "Ice Mist",
        "mode": "clickToTarget",
        "macro": "cast icemist",
        "icon": "kaleidoscope-pearls",
        "color": "#000080",
        "tooltipDesc": "Inflict ice damage in a 3x3 AoE. Cost: 25 MP",
        "for": "IceMist"
    },
    "Fire Mist": {
        "name": "Fire Mist",
        "mode": "clickToTarget",
        "macro": "cast firemist",
        "icon": "kaleidoscope-pearls",
        "color": "#DC143C",
        "tooltipDesc": "Inflict fire damage in a 3x3 AoE. Cost: 25 MP",
        "for": "FireMist"
    },
    "Drain": {
        "mode": "clickToTarget",
        "name": "Drain",
        "macro": "cast drain",
        "icon": "wind-hole",
        "color": "#af0000",
        "tooltipDesc": "Drain health from your target. Cost: 10 MP",
        "for": "Drain"
    },
    "Asper": {
        "mode": "clickToTarget",
        "name": "Asper",
        "macro": "cast asper",
        "icon": "wind-hole",
        "color": "#0059bd",
        "tooltipDesc": "Drain mana from your target. Cost: 10 MP",
        "for": "Asper"
    },
    "Rift Slash": {
        "name": "Rift Slash",
        "mode": "lockActivation",
        "macro": "cast riftslash",
        "icon": "running-ninja",
        "color": "#a0a",
        "tooltipDesc": "Warp through the ether to your target and attack them. Cost: 10 MP",
        "for": "RiftSlash"
    },
    "Cure": {
        "mode": "clickToTarget",
        "name": "Cure",
        "macro": "cast cure",
        "icon": "tentacle-heart",
        "color": "#080",
        "tooltipDesc": "Heal a single target. Cost: 5 MP.",
        "for": "Cure"
    },
    "Powerword Heal": {
        "name": "Powerword Heal",
        "macro": "powerword heal",
        "mode": "autoActivate",
        "icon": "tentacle-heart",
        "color": "#0f0",
        "tooltipDesc": "Heal your entire party. Cost: 100 MP.",
        "for": "PowerwordHeal"
    },
    "Revive": {
        "mode": "autoActivate",
        "name": "Revive",
        "macro": "cast revive",
        "icon": "quicksand",
        "color": "#080",
        "tooltipDesc": "Revive a player on the ground on your tile. Cost: 50 MP",
        "for": "Revive"
    },
    "Resurrect": {
        "mode": "autoActivate",
        "name": "Resurrect",
        "macro": "cast resurrect",
        "icon": "quicksand",
        "color": "#800",
        "tooltipDesc": "Resurrect a corpse to fight for you.",
        "for": "Resurrect"
    },
    "Shred": {
        "mode": "lockActivation",
        "name": "Shred",
        "macro": "cast shred",
        "icon": "bleeding-wound",
        "color": "#bd5900",
        "tooltipDesc": "Shred the opponents HP.",
        "for": "Shred"
    },
    "Power Ballad": {
        "name": "Power Ballad",
        "macro": "song powerballadsong",
        "color": "#f00",
        "mode": "autoActivate",
        "tooltipDesc": "Sing a song that buffs nearby allies' offensive stats. Cost: 5 Stealth/round",
        "icon": "music-spell",
        "for": "PowerBalladSong"
    },
    "Wistful Fugue": {
        "name": "Wistful Fugue",
        "macro": "song wistfulfuguesong",
        "color": "#00f",
        "mode": "autoActivate",
        "tooltipDesc": "Sing a song that debuffs nearby enemies' defensive stats. Cost: 5 Stealth/round",
        "icon": "music-spell",
        "for": "WistfulFugueSong"
    },
    "Deadly Dirge": {
        "name": "Deadly Dirge",
        "macro": "song deadlydirgesong",
        "color": "#808",
        "mode": "autoActivate",
        "tooltipDesc": "Sing a song that does sonic damage to nearby enemies. Cost: 5 Stealth/round",
        "icon": "music-spell",
        "for": "DeadlyDirgeSong"
    },
    "Tranquil Trill": {
        "name": "Tranquil Trill",
        "macro": "song tranquiltrillsong",
        "color": "#080",
        "mode": "autoActivate",
        "tooltipDesc": "Sing a song that heals nearby allies. Cost: 5 Stealth/round",
        "icon": "music-spell",
        "for": "TranquilTrillSong"
    },
    "Aria of Refuge": {
        "name": "Aria of Refuge",
        "macro": "song ariaofrefugesong",
        "color": "#aa0",
        "mode": "autoActivate",
        "tooltipDesc": "Sing a song that buffs nearby allies' resistances. Cost: 5 Stealth/round",
        "icon": "music-spell",
        "for": "AriaOfRefugeSong"
    },
    "Nightmare Serenade": {
        "name": "Nightmare Serenade",
        "macro": "song nightmareserenadesong",
        "color": "#0aa",
        "mode": "autoActivate",
        "tooltipDesc": "Sing a song that debuffs nearby enemies' resistances. Cost: 5 Stealth/round",
        "icon": "music-spell",
        "for": "NightmareSerenadeSong"
    },
    "Volcano Stance": {
        "name": "Volcano Stance",
        "macro": "cast volcanostance",
        "color": "#dc143c",
        "mode": "autoActivate",
        "tooltipDesc": "Adopt a molten offensive stance, boosting outgoing fire damage, making physical attacks hit for bonus fire damage, increasing offense and lowering defense.",
        "icon": "fire-silhouette",
        "for": "VolcanoStance"
    },
    "Glacier Stance": {
        "name": "Glacier Stance",
        "macro": "cast glacierstance",
        "color": "#000080",
        "mode": "autoActivate",
        "tooltipDesc": "Adopt a frozen defensive stance, boosting outgoing frost damage, making physical attackers take frost damage, increasing defense and lowering offense.",
        "icon": "frozen-orb",
        "for": "GlacierStance"
    },
    "Wizard Stance": {
        "name": "Wizard Stance",
        "macro": "cast wizardstance",
        "color": "#a0a",
        "mode": "autoActivate",
        "tooltipDesc": "Adopt a stance of true wizardry, lowering incoming damage from all sources and automatically firing bolts of energy when attacked magically.",
        "icon": "burning-passion",
        "for": "WizardStance"
    },
    "Parry Stance": {
        "name": "Parry Stance",
        "macro": "art parrystance",
        "color": "#000",
        "mode": "autoActivate",
        "tooltipDesc": "Adopt a defensive stance, taunting foes, increasing defense and lowering offense.",
        "icon": "surrounded-shield",
        "for": "ParryStance"
    },
    "Rage Stance": {
        "name": "Rage Stance",
        "macro": "art ragestance",
        "color": "#000",
        "mode": "autoActivate",
        "tooltipDesc": "Adopt an offensive stance, generating rage, increasing offense and lowering defense.",
        "icon": "swords-power",
        "for": "RageStance"
    },
    "Turtle Stance": {
        "name": "Turtle Stance",
        "macro": "art turtlestance",
        "color": "#000",
        "mode": "autoActivate",
        "tooltipDesc": "Adopt an defensive stance, increasing defense and AGI and lowering offense.",
        "icon": "turtle-shell",
        "for": "TurtleStance"
    },
    "Tiger Stance": {
        "name": "Tiger Stance",
        "macro": "art tigerstance",
        "color": "#000",
        "mode": "autoActivate",
        "tooltipDesc": "Adopt an offensive stance, increasing offense and STR and lowering defense.",
        "icon": "tiger-head",
        "for": "TigerStance"
    },
    "Find Familiar (Light)": {
        "name": "Find Familiar (Light)",
        "macro": "findfamiliar light",
        "mode": "autoActivate",
        "icon": "double-ringed-orb",
        "color": "#000",
        "tooltipDesc": "Summon a spirit of light to heal allies. Cost: 50 MP",
        "for": "FindFamiliarLight"
    },
    "Find Familiar (Nature)": {
        "name": "Find Familiar (Nature)",
        "macro": "findfamiliar nature",
        "mode": "autoActivate",
        "icon": "tree-branch",
        "color": "#000",
        "tooltipDesc": "Summon a spirit of nature to debuff enemies. Cost: 50 MP",
        "for": "FindFamiliarNature"
    },
    "Find Familiar (Water)": {
        "name": "Find Familiar (Water)",
        "macro": "findfamiliar water",
        "mode": "autoActivate",
        "icon": "water-splash",
        "color": "#000",
        "tooltipDesc": "Summon a spirit of water to buff allies. Cost: 50 MP",
        "for": "FindFamiliarWater"
    },
    "Find Familiar (Spider)": {
        "name": "Find Familiar (Spider)",
        "macro": "findfamiliar spider",
        "mode": "autoActivate",
        "icon": "spider-alt",
        "color": "#000",
        "tooltipDesc": "Summon a chillspider to fight for you in combat. Cost: 50 MP",
        "for": "FindFamiliarSpider"
    },
    "Find Familiar (Salamander)": {
        "name": "Find Familiar (Salamander)",
        "macro": "findfamiliar salamander",
        "mode": "autoActivate",
        "icon": "salamander",
        "color": "#000",
        "tooltipDesc": "Summon a salamander to fight for you in combat. Cost: 50 MP",
        "for": "FindFamiliarSalamander"
    },
    "Find Familiar (Bear)": {
        "name": "Find Familiar (Bear)",
        "macro": "findfamiliar bear",
        "mode": "autoActivate",
        "icon": "polar-bear",
        "color": "#000",
        "tooltipDesc": "Summon a bear to fight for you in combat. Cost: 50 MP",
        "for": "FindFamiliarBear"
    },
    "Find Familiar (Wolf)": {
        "name": "Find Familiar (Wolf)",
        "macro": "findfamiliar wolf",
        "mode": "autoActivate",
        "icon": "wolf-head",
        "color": "#000",
        "tooltipDesc": "Summon a wolf to fight for you in combat. Cost: 50 MP",
        "for": "FindFamiliarWolf"
    },
    "Shadow Clones": {
        "name": "Shadow Clones",
        "macro": "findfamiliar shadowclones",
        "mode": "autoActivate",
        "icon": "dark-squad",
        "color": "#000",
        "tooltipDesc": "Summon shadow clones to help you slaughter your foes. Cost: 100 MP",
        "for": "FindFamiliarShadowClones"
    },
    "Distraction": {
        "name": "Distraction",
        "macro": "findfamiliar distraction",
        "mode": "autoActivate",
        "icon": "dark-squad",
        "color": "#000",
        "tooltipDesc": "Summon a distraction to confuse your enemies. Cost: 100 MP",
        "for": "FindFamiliarDistraction"
    },
    "Find Familiar (Snowman)": {
        "name": "Find Familiar (Snowman)",
        "macro": "findfamiliar snowman",
        "mode": "autoActivate",
        "icon": "snowman",
        "color": "#000",
        "tooltipDesc": "Summon a snowman to fight for you in combat. Cost: 0 MP",
        "for": "FindFamiliarSnowman"
    },
    "Find Familiar (Turkey)": {
        "name": "Find Familiar (Turkey)",
        "macro": "findfamiliar turkey",
        "color": "#000",
        "mode": "autoActivate",
        "tooltipDesc": "Summon a turkey to fight for you in combat. Cost: 0 MP",
        "icon": "bird-twitter",
        "for": "FindFamiliarTurkey"
    },
    "Find Familiar (Golem)": {
        "name": "Find Familiar (Golem)",
        "macro": "findfamiliar golem",
        "mode": "autoActivate",
        "icon": "rock-2",
        "color": "#000",
        "tooltipDesc": "Summon a golem to fight for you in combat. Cost: 0 MP",
        "for": "FindFamiliarGolem"
    },
    "Find Familiar (Dragon)": {
        "name": "Find Familiar (Dragon)",
        "macro": "findfamiliar dragon",
        "color": "#000",
        "mode": "autoActivate",
        "tooltipDesc": "Summon a dragon to slay enemies. Cost: 150 MP",
        "icon": "dragon-spiral",
        "for": "FindFamiliarDragon"
    },
    "Mug": {
        "mode": "lockActivation",
        "name": "Mug",
        "macro": "cast mug",
        "icon": "hooded-assassin",
        "color": "#530000",
        "tooltipDesc": "Mug your target, dealing damage and stealing from them.",
        "for": "Mug"
    },
    "Backstab": {
        "macro": "backstab",
        "mode": "clickToTarget",
        "name": "Backstab",
        "icon": "backstab",
        "color": "#530000",
        "tooltipDesc": "Backstab your target from the shadows.",
        "for": "Backstab"
    },
    "Set": {
        "macro": "set",
        "mode": "clickToTarget",
        "name": "Set",
        "icon": "quake-stomp",
        "color": "#530000",
        "tooltipDesc": "Set a trap from your hand. Traps can be purchased from thief vendors.",
        "for": "Set"
    },
    "Disarm": {
        "macro": "disarm",
        "mode": "clickToTarget",
        "name": "Disarm",
        "icon": "quake-stomp",
        "color": "#000053",
        "tooltipDesc": "Disarm a trap on an adjacent tile.",
        "for": "Disarm"
    },
    "Apply": {
        "name": "Apply",
        "macro": "apply",
        "color": "#050",
        "mode": "autoActivate",
        "tooltipDesc": "Apply the effects of a bottle to your weapon strikes.",
        "icon": "bloody-sword",
        "for": "Apply"
    },
    "Shadowmeld": {
        "bgColor": "#00c",
        "mode": "autoActivate",
        "name": "Shadowmeld",
        "macro": "cast shadowmeld",
        "icon": "hidden",
        "color": "#ccc",
        "tooltipDesc": "Become one with the shadows for a brief time. Cost: 100 MP.",
        "for": "Shadowmeld"
    },
    "Disguise": {
        "mode": "autoActivate",
        "name": "Disguise",
        "macro": "cast disguise",
        "icon": "duality",
        "color": "#111",
        "tooltipDesc": "Blend in with your enemies. Cost: 100 MP.",
        "for": "Disguise"
    },
    "Chain Kunai": {
        "name": "Chain Kunai",
        "mode": "clickToTarget",
        "macro": "cast chainkunai",
        "icon": "harpoon-chain",
        "color": "#111",
        "tooltipDesc": "Attack an enemy, pull it to you, and lock it in place briefly. Cost: 25 MP.",
        "for": "ChainKunai"
    },
    "Multi Shot": {
        "name": "Multi Shot",
        "mode": "lockActivation",
        "macro": "cast multishot",
        "icon": "double-shot",
        "color": "#000",
        "tooltipDesc": "Shoot multiple arrows at a foe.",
        "for": "MultiShot"
    },
    "Identify": {
        "mode": "autoActivate",
        "name": "Identify",
        "macro": "cast identify",
        "icon": "uncertainty",
        "color": "#665600",
        "tooltipDesc": "Identify the attributes of the item in your right hand. Cost: 15 MP",
        "for": "Identify"
    },
    "Foodsense": {
        "mode": "autoActivate",
        "name": "Foodsense",
        "macro": "cast foodsense",
        "icon": "meat",
        "color": "#665600",
        "tooltipDesc": "Sense the bonuses of the food in your right hand. Cost: 15 MP",
        "for": "Foodsense"
    },
    "Gemsense": {
        "mode": "autoActivate",
        "name": "Gemsense",
        "macro": "cast gemsense",
        "icon": "gem-chain",
        "color": "#665600",
        "tooltipDesc": "Sense the bonuses of the gem in your right hand. Cost: 15 MP",
        "for": "Gemsense"
    },
    "Succor": {
        "name": "Succor",
        "macro": "cast succor",
        "color": "#080",
        "mode": "autoActivate",
        "tooltipDesc": "Create a consumable that will let you return to your current location. Cost: 25 MP",
        "icon": "blackball",
        "for": "Succor"
    },
    "Wellspring": {
        "mode": "autoActivate",
        "name": "Wellspring",
        "macro": "cast wellspring",
        "icon": "holy-water",
        "color": "#080",
        "tooltipDesc": "Create a consumable that will quell satiation. Cost: 25 MP",
        "for": "Wellspring"
    },
    "Conjure Healing": {
        "name": "Conjure Healing",
        "mode": "autoActivate",
        "macro": "cast conjurehealing",
        "icon": "brandy-bottle",
        "color": "#080",
        "tooltipDesc": "Create a consumable that will heal you. Cost: 25 MP",
        "for": "ConjureHealing"
    },
    "Augury": {
        "name": "Augury",
        "macro": "cast augury",
        "color": "#000",
        "mode": "clickToTarget",
        "tooltipDesc": "Use the birds to divine the location of your target. Cost: 25 MP",
        "icon": "dove",
        "for": "Augury"
    },
    "Push": {
        "mode": "clickToTarget",
        "name": "Push",
        "macro": "cast push",
        "icon": "swirl-string",
        "color": "#000",
        "tooltipDesc": "Push a single target around. Cost: 15 MP",
        "for": "Push"
    },
    "Pull": {
        "mode": "clickToTarget",
        "name": "Pull",
        "macro": "cast pull",
        "icon": "swirl-string",
        "color": "#000",
        "tooltipDesc": "Pull a single target to you. Cost: 15 MP",
        "for": "Pull"
    },
    "Dispel": {
        "mode": "clickToTarget",
        "name": "Dispel",
        "macro": "cast dispel",
        "icon": "broken-shield",
        "color": "#000",
        "tooltipDesc": "Remove a buff from a target. Cost: 100 MP",
        "for": "Dispel"
    },
    "Darkness": {
        "mode": "clickToTarget",
        "name": "Darkness",
        "macro": "cast darkness",
        "icon": "dust-cloud",
        "color": "#000",
        "tooltipDesc": "Make it harder to see in a certain area. Cost: 15 MP",
        "for": "Darkness"
    },
    "Light": {
        "mode": "clickToTarget",
        "name": "Light",
        "macro": "cast light",
        "icon": "candle-light",
        "color": "#aa0",
        "tooltipDesc": "Remove darkness from a certain area. Cost: 15 MP",
        "for": "Light"
    },
    "Conjure Weapon": {
        "name": "Conjure Weapon",
        "mode": "autoActivate",
        "macro": "cast conjuresword",
        "icon": "broadsword",
        "color": "#a0a",
        "tooltipDesc": "Conjure a sword for yourself. Cost: 25 MP",
        "for": "ConjureSword"
    },
    "Conjure Shield": {
        "name": "Conjure Shield",
        "mode": "autoActivate",
        "macro": "cast conjureshield",
        "icon": "round-shield",
        "color": "#a0a",
        "tooltipDesc": "Conjure a shield for yourself. Cost: 25 MP",
        "for": "ConjureShield"
    },
    "Teleport": {
        "name": "Teleport",
        "macro": "cast teleport",
        "color": "#a0a",
        "mode": "autoActivate",
        "tooltipDesc": "Teleport to a previously-memorized location. Memorize new locations with the memorize command and forget them with the forget command. Cost: 100 MP",
        "icon": "teleport",
        "for": "Teleport"
    },
    "Mass Teleport": {
        "name": "Mass Teleport",
        "macro": "cast massteleport",
        "color": "#a0a",
        "mode": "autoActivate",
        "tooltipDesc": "Teleport all players on your tile to a previously-memorized location. Memorize new locations with the memorize command and forget them with the forget command. Cost: 100 MP",
        "icon": "vortex",
        "for": "MassTeleport"
    },
    "Vortex": {
        "name": "Vortex",
        "macro": "cast vortex",
        "color": "#333",
        "mode": "autoActivate",
        "tooltipDesc": "Create a vortex which sucks in nearby items. Cost: 100 MP",
        "icon": "wind-hole",
        "for": "Vortex"
    },
    "Transmute": {
        "mode": "clickToTarget",
        "name": "Transmute",
        "macro": "cast transmute",
        "icon": "coins-pile",
        "color": "#665600",
        "tooltipDesc": "Convert the items on the chosen tile to gold. Cost: 15 MP",
        "for": "Transmute"
    },
    "Fate": {
        "mode": "autoActivate",
        "name": "Fate",
        "macro": "cast fate",
        "icon": "solar-time",
        "color": "#000",
        "tooltipDesc": "???",
        "for": "Fate"
    },
    "Reincarnate": {
        "mode": "autoActivate",
        "name": "Reincarnate",
        "macro": "cast reincarnate",
        "icon": "life-in-the-balance",
        "color": "#dc143c",
        "tooltipDesc": "Bring a strong foe back to life.",
        "for": "Reincarnate"
    },
    "Cleave": {
        "mode": "autoTarget",
        "macro": "art cleave",
        "name": "Cleave",
        "icon": "blade-fall",
        "color": "#f00",
        "tooltipDesc": "Cleave your current target. Cost: 10 Rage",
        "for": "Cleave"
    },
    "Ragerang": {
        "mode": "autoTarget",
        "macro": "art ragerang",
        "name": "Ragerang",
        "icon": "boomerang-cross",
        "color": "#f00",
        "tooltipDesc": "Throw your weapon at current target for massive damage. Cost: 10 Rage",
        "for": "Ragerang"
    },
    "Mad Dash": {
        "name": "Mad Dash",
        "mode": "autoTarget",
        "macro": "art maddash",
        "icon": "fire-dash",
        "color": "#f00",
        "tooltipDesc": "Dash madly at a creature in sight, attacking them and generating 30 Rage.",
        "for": "MadDash"
    },
    "Provoke": {
        "mode": "autoTarget",
        "macro": "art provoke",
        "name": "Provoke",
        "icon": "enrage",
        "color": "#f00",
        "tooltipDesc": "Provoke your current target. Cost: 5 Rage",
        "for": "Provoke"
    },
    "Mocking Shout": {
        "name": "Mocking Shout",
        "mode": "autoActivate",
        "macro": "art mockingshout",
        "icon": "shouting",
        "color": "#f00",
        "tooltipDesc": "Provoke all enemies around you. Cost: 25 Rage",
        "for": "MockingShout"
    },
    "Thruststrike": {
        "mode": "lockActivation",
        "macro": "art thruststrike",
        "name": "Thruststrike",
        "icon": "saber-slash",
        "color": "#f00",
        "tooltipDesc": "Charge towards an enemy, attacking with the item in your right hand. Deals more damage than Charge.",
        "for": "Thruststrike"
    },
    "Blindstrike": {
        "mode": "autoActivate",
        "macro": "art blindstrike",
        "name": "Blindstrike",
        "icon": "sword-tie",
        "color": "#f00",
        "tooltipDesc": "Attack a random enemy on your tile with a slightly weak attack. You can use this even if you can't see the target.",
        "for": "Blindstrike"
    },
    "Multistrike": {
        "mode": "autoActivate",
        "macro": "art multistrike",
        "name": "Multistrike",
        "icon": "sword-spin",
        "color": "#f00",
        "tooltipDesc": "Attack a group of enemies on your tile at the expense of defense.",
        "for": "Multistrike"
    },
    "Jumpkick": {
        "mode": "lockActivation",
        "macro": "art jumpkick",
        "name": "Jumpkick",
        "icon": "high-kick",
        "color": "#530000",
        "tooltipDesc": "Jump to and kick an enemy.",
        "for": "Jumpkick"
    },
    "Sweep": {
        "mode": "autoActivate",
        "macro": "art sweep",
        "name": "Sweep",
        "icon": "foot-trip",
        "color": "#a00",
        "tooltipDesc": "Attack multiple creatures on your tile with a kick attack at the expense of defense.",
        "for": "Sweep"
    },
    "Rapidpunch": {
        "mode": "lockActivation",
        "macro": "art rapidpunch",
        "name": "Rapidpunch",
        "icon": "fire-punch",
        "color": "#a00",
        "tooltipDesc": "Repeatedly punch a target.",
        "for": "Rapidpunch"
    },
    "Boost": {
        "name": "Boost",
        "macro": "art boost",
        "color": "#000",
        "mode": "autoActivate",
        "tooltipDesc": "Boost your STR, DEX, and AGI by +3.",
        "icon": "fist",
        "for": "Boost"
    },
    "Shield": {
        "macro": "art shield",
        "mode": "autoActivate",
        "name": "Shield",
        "icon": "vibrating-shield",
        "color": "#a00",
        "tooltipDesc": "Increase your physical and magical resistance for a period of time.",
        "for": "Shield"
    },
    "Cleanse": {
        "name": "Cleanse",
        "macro": "cast cleanse",
        "color": "#080",
        "mode": "clickToTarget",
        "tooltipDesc": "Remove all curses from a single target. Cost: 100 MP.",
        "icon": "warlock-eye",
        "for": "Cleanse"
    }
}