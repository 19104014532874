<div class="lobby">
  <app-lobby
    class="window-container"
    [class.hidden]="optionsService.hideLobbyInGame && gameService.inGame()"
  ></app-lobby>

  <app-char-select
    class="window-container"
    [class.hidden]="gameService.inGame()"
  ></app-char-select>

  <app-session-stats
    class="window-container"
    [class.hidden]="gameService.inGame() || stats() === null"
  ></app-session-stats>
</div>
