"use strict";

exports.__esModule = true;
exports.inherits = inherits;
function inherits(parent, child) {
  var props = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var extended = Object.create(parent.prototype);
  for (var p in props) {
    extended[p] = props[p];
  }
  extended.constructor = child;
  child.prototype = extended;
  return child;
}