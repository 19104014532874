import { produce, createDraft, finishDraft } from 'immer';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * An utility function, that determines if provided target is defined and not nullable
 *
 * @template T
 * @param {?} target - Value to check for being `undefined` or `null`
 * @return {?} - True if the passed target is not `undefined` and not `null`
 */
function isDefined(target) {
  return target !== undefined && target !== null;
}
/**
 * An utility function, that determines if provided object is valid
 *
 * @template T
 * @param {?} ctx - `StateContext` plain object
 * @return {?} - True if provided object is valid and has necessary methods
 */
function isValidContext(ctx) {
  return isDefined(ctx) && typeof ctx.getState === 'function' && typeof ctx.setState === 'function';
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * An adapter function for the `produce` from `immer` library
 *
 * @deprecated - use immutable helpers from ngxs v3.4.x
 * @template T
 * @param {?} ctx - Reference to the `StateContext` plain object
 * @param {?} recipe - Function that receives a proxy of the current state
 * @return {?} - New state or throws an error
 */
function produce$1(ctx, recipe) {
  /** @type {?} */
  const invalidContext = !isValidContext(ctx);
  if (invalidContext) {
    throw new Error('You should provide `StateContext` object as the first argument of the `produce` function');
  }
  return ctx.setState( /** @type {?} */ /**
                                        * @param {?} state
                                        * @return {?}
                                        */
  state => produce(state, recipe));
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @template T
 */
class ImmutableStateContext {
  /**
   * @param {?} ctx
   */
  constructor(ctx) {
    this.ctx = ctx;
    this.frozenState = null;
    ImmutableStateContext.autobindStateContext(this);
  }
  /**
   * @private
   * @param {?} context
   * @return {?}
   */
  static autobindStateContext(context) {
    for (const prop of Object.getOwnPropertyNames(Object.getPrototypeOf(context))) {
      if (prop === 'constructor' || typeof context[prop] !== 'function') {
        continue;
      }
      context[prop] = context[prop].bind(context);
    }
  }
  /**
   * @return {?}
   */
  getState() {
    this.frozenState = /** @type {?} */createDraft(this.ctx.getState());
    return this.frozenState;
  }
  /**
   * @param {?} val
   * @return {?}
   */
  setState(val) {
    /** @type {?} */
    let state;
    if (typeof val === 'function') {
      /** @type {?} */
      let newState;
      /** @type {?} */
      const oldState = /** @type {?} */createDraft(this.ctx.getState());
      /** @type {?} */
      const operator = /** @type {?} */val;
      /** @type {?} */
      const mutatedOldState = operator(oldState);
      if (this.frozenState === mutatedOldState) {
        newState = finishDraft(this.frozenState);
        finishDraft(oldState);
      } else {
        /** @type {?} */
        const mutateOutsideOperator = oldState !== mutatedOldState;
        if (mutateOutsideOperator) {
          newState = mutatedOldState;
          finishDraft(oldState);
        } else {
          newState = finishDraft(mutatedOldState);
        }
      }
      state = newState;
    } else {
      state = finishDraft(val);
    }
    this.frozenState = null;
    return this.ctx.setState(state);
  }
  /**
   * @param {?} val
   * @return {?}
   */
  patchState(val) {
    return this.ctx.patchState( /** @type {?} */finishDraft(val));
  }
  /**
   * @param {?} actions
   * @return {?}
   */
  dispatch(actions) {
    return this.ctx.dispatch(actions);
  }
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @deprecated - use ImmutableContext instead Mutation
 * @return {?}
 */
function Mutation() {
  return (
    /**
    * @param {?} _target
    * @param {?} _key
    * @param {?} descriptor
    * @return {?}
    */
    function (_target, _key, descriptor) {
      /** @type {?} */
      const method = descriptor.value;
      descriptor.value =
      /**
      * @param {?} ctx
      * @param {?} action
      * @param {...?} args
      * @return {?}
      */
      function (ctx, action, ...args) {
        return method.apply(this, [new ImmutableStateContext(ctx), action, ...args]);
      };
      return descriptor;
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @return {?}
 */
function ImmutableContext() {
  return (
    /**
    * @param {?} _target
    * @param {?} _key
    * @param {?} descriptor
    * @return {?}
    */
    function (_target, _key, descriptor) {
      /** @type {?} */
      const method = descriptor.value;
      descriptor.value =
      /**
      * @param {?} ctx
      * @param {?} action
      * @param {...?} args
      * @return {?}
      */
      function (ctx, action, ...args) {
        return method.apply(this, [new ImmutableStateContext(ctx), action, ...args]);
      };
      return descriptor;
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * @return {?}
 */
function ImmutableSelector() {
  return (
    /**
    * @param {?} _target
    * @param {?} _key
    * @param {?} descriptor
    * @return {?}
    */
    function (_target, _key, descriptor) {
      /** @type {?} */
      const method = descriptor.value;
      descriptor.value =
      /**
      * @param {?} state
      * @param {...?} args
      * @return {?}
      */
      function (state, ...args) {
        return method.apply(this, [createDraft(state), ...args]);
      };
      return descriptor;
    }
  );
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

export { ImmutableContext, ImmutableSelector, produce$1 as produce, Mutation };

