<h1 mat-dialog-title>
  {{ data.title || 'Are you sure?' }}
</h1>

<div mat-dialog-content>
  @if (data.extraData?.npcSprite) {
    <app-npc [sprite]="data.extraData.npcSprite"></app-npc>
  }
  {{ data.content || 'Are you sure?' }}
</div>

<div mat-dialog-actions>
  <button mat-button
    [mat-dialog-close]="false"
    cdkFocusInitial>
    {{ data.extraData?.cancelText || 'Cancel' }}
  </button>

  <button mat-button
    [mat-dialog-close]="true"
    color="primary">
    {{ data.extraData?.okText || 'OK' }}
  </button>
</div>