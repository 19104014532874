<app-window
  windowName="equipmentQuick"
  [head]="headTemplate"
  [body]="bodyTemplate"
  [canHide]="true"
  [initialHide]="true"
  [defaultX]="300"
  [defaultY]="300"
  [defaultWidth]="350"
  [defaultHeight]="110"
></app-window>

<ng-template #headTemplate>Hands</ng-template>

<ng-template #bodyTemplate>
  @if (player(); as player) {
  <div class="item-container">
    @for (slot of slots; track slot) {
    <div
      class="item-slot"
      [class.item-background]="slot.template || slot.slot"
      [class.selected]="uiService.selected.equipment[slot.slot]"
      [dropScope]="slot.scope || slot.slot"
      (onDrop)="uiService.buildAndDoDropAction($event, slot.dropScope)"
      droppable
    >
      @if (!slot.template) {
      <ng-container
        *ngTemplateOutlet="
          itemGenericTemplate;
          context: createContext(slot, player)
        "
      ></ng-container>
      }
      <!-- -->
      @if (slot.template === 'coin') {
      <ng-container
        *ngTemplateOutlet="
          itemCoinTemplate;
          context: createContext(slot, player)
        "
      ></ng-container>
      }
      <!-- -->
      @if (slot.template === 'hand') {
      <ng-container
        *ngTemplateOutlet="
          itemHandTemplate;
          context: createContext(slot, player)
        "
      ></ng-container>
      }
    </div>
    }
  </div>
  }
</ng-template>

<ng-template #itemGenericTemplate let-slot="slot" let-player="player">
  @if (!(player.items.equipment || {})[slot.slot]) {
  <span class="item-slot-bg-text">{{ slot.name }}</span>
  }
  <!-- -->
  @if ((player.items.equipment || {})[slot.slot]) {
  <app-item
    [context]="'Equipment'"
    [contextSlot]="slot.slot"
    [viewingPlayer]="player"
    [showCount]="slot.slot === 'ammo'"
    [showOunces]="slot.slot === 'potion'"
    [item]="player.items.equipment[slot.slot]"
  ></app-item>
  }
</ng-template>

<ng-template #itemHandTemplate let-slot="slot" let-player="player">
  @if (!(player.items.equipment || {})[slot.slot]) {
  <span class="item-slot-bg-text">{{ slot.name }}</span>
  }

  <!-- -->
  @if ((player.items.equipment || {})[slot.slot]) {
  <app-item
    [context]="slot.hand"
    contextSlot="_"
    [showOunces]="true"
    [showValue]="canShowValue(slot.slot, player.items.equipment[slot.slot])"
    [viewingPlayer]="player"
    [item]="player.items.equipment[slot.slot]"
  ></app-item>
  }
</ng-template>

<ng-template #itemCoinTemplate let-slot="slot" let-player="player">
  <app-item
    context="Coin"
    contextSlot="_"
    [showValue]="true"
    [viewingPlayer]="player"
    [item]="{
      name: 'Gold Coin',
      mods: {
        currency: 'gold',
        itemClass: 'Coin',
        value: player.currency.gold
      },
      uuid: ''
    }"
  ></app-item>
</ng-template>
