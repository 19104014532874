<app-window
  windowName="ground"
  [head]="headTemplate"
  [body]="bodyTemplate"
  [canHide]="true"
  [initialHide]="true"
  [defaultX]="4"
  [defaultY]="710"
  [defaultWidth]="380"
  [defaultHeight]="optionsService.biggerGroundWindow ? 336 : 208"
></app-window>

<ng-template #headTemplate>Ground</ng-template>

<ng-template #bodyTemplate>
  @let groups = groundGroups(); @if (ground(); as currentGround) {
  <div
    class="full-height"
    [class.big-ground]="optionsService.biggerGroundWindow"
    dropScope="ground"
    (onDrop)="uiService.buildAndDoDropAction($event, 'ground')"
    droppable
  >
    @if (!groups || groups.length === 0) {
    <div class="true-center background-text full-height">
      You don't see any items here
    </div>
    }

    <!---->
    @if (groups.length > 0) {
    <div class="item-classes">
      @for (itemGroup of groups; track $index) {
      <app-item
        (click)="changeItemClass(itemGroup.itemClass)"
        [class.selected]="itemGroup.itemClass === currentItemClass()"
        [size]="'normal'"
        [showDesc]="false"
        context="GroundGroup"
        [contextSlot]="itemGroup.itemClass"
        [showCount]="itemGroup.itemClass !== 'Coin'"
        [showValue]="itemGroup.itemClass === 'Coin'"
        [count]="itemGroup.count"
        [viewingPlayer]="player()"
        [item]="{
          name: itemGroup.name,
          mods: { sprite: itemGroup.sprite, value: itemGroup.value || 0 },
          uuid: ''
        }"
      ></app-item>
      }
    </div>

    <!---->
    @if (optionsService.biggerGroundWindow) {
    <div class="item-split">
      {{ currentItemClass() }}
    </div>
    }

    <div class="item-sub">
      @if (!currentItemClass()) {
      <div class="true-center background-text full-height full-width">
        Select an item category
      </div>
      }

      <!---->
      @for (item of (currentGround[currentItemClass()] || []); track $index) {
      <app-item
        [size]="'normal'"
        context="Ground"
        [contextSlot]="item.item.uuid"
        [count]="item.count"
        [viewingPlayer]="player()"
        [showCount]="currentItemClass() !== 'Coin'"
        [showValue]="currentItemClass() === 'Coin'"
        [item]="item.item"
      ></app-item>
      }
    </div>
    }
  </div>
  }
</ng-template>
