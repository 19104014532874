"use strict";

exports.__esModule = true;
function createTokenClass() {
  return function (value) {
    if (value) {
      this.v = value;
    }
  };
}
exports.createTokenClass = createTokenClass;