import { Subject } from 'rxjs';
export class QueueingSubject extends Subject {
  constructor() {
    super(...arguments);
    this.queuedValues = [];
  }
  next(value) {
    if (this.closed || this.observers.length) super.next(value);else this.queuedValues.push(value);
  }
  _subscribe(subscriber) {
    const subscription = super._subscribe(subscriber);
    if (this.queuedValues.length) {
      this.queuedValues.forEach(value => super.next(value));
      this.queuedValues.splice(0);
    }
    return subscription;
  }
}
