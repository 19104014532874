<h1 mat-dialog-title>{{ data?.title || 'No Title' }}</h1>
<div mat-dialog-content>
  @if (data.extraData?.npcSprite >= 0) {
  <app-npc [sprite]="data.extraData.npcSprite"></app-npc>
  } @if (data.extraData?.itemName && !data.extraData.displayItemSprite) {
  <app-item
    [item]="{ name: data.extraData.itemName, mods: {}, uuid: '' }"
  ></app-item>
  } @if (data.extraData?.itemName && data.extraData.displayItemSprite) {
  <app-item
    [item]="{
      name: data.extraData.itemName,
      mods: { sprite: data.extraData.displayItemSprite },
      uuid: ''
    }"
  ></app-item>
  }

  <div [innerHTML]="data?.content || 'I don\'t know what to say.'"></div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close] cdkFocusInitial>Close</button>
</div>
