{
    "AncientGrip": {
        "isAncient": true,
        "valuePerTier": 5,
        "name": "Ancient Grip",
        "desc": "Decrease your chance of dropping items on death by +$5$%.",
        "icon": "drop-weapon",
        "iconBgColor": "#aa5c39"
    },
    "AncientPotions": {
        "isAncient": true,
        "valuePerTier": 500,
        "name": "Ancient Potions",
        "desc": "Increase the healing from all potions by +$500$.",
        "icon": "bubbling-flask",
        "iconBgColor": "#aa5c39"
    },
    "AncientFortitude": {
        "isAncient": true,
        "statsGiven": {
            "savingThrow": 3
        },
        "name": "Ancient Fortitude",
        "desc": "Increase your saving throw to resist spells by +$3$.",
        "icon": "brain",
        "iconBgColor": "#aa5c39"
    },
    "AncientAnger": {
        "isAncient": true,
        "statsGiven": {
            "threatMultiplier": 0.05
        },
        "name": "Ancient Anger",
        "desc": "Increase the amount of agro you generate by +$5$%.",
        "icon": "unfriendly-fire",
        "iconBgColor": "#aa5c39"
    },
    "AncientSilence": {
        "isAncient": true,
        "statsGiven": {
            "threatMultiplier": -0.05
        },
        "name": "Ancient Silence",
        "desc": "Decrease the amount of agro you generate by +$5$%.",
        "icon": "diamonds-smile",
        "iconBgColor": "#aa5c39"
    },
    "AbsorptiveArmor": {
        "isAncient": true,
        "statsGiven": {
            "mitigation": 1
        },
        "name": "Absorptive Armor",
        "desc": "Increases your natural mitigation by +$1$.",
        "icon": "chest-armor",
        "iconBgColor": "#aa5c39"
    },
    "CommandingPower": {
        "isAncient": true,
        "statsGiven": {
            "damageFactor": 0.05
        },
        "name": "Commanding Power",
        "desc": "Increases your outgoing damage by +$5$%.",
        "icon": "crossed-swords",
        "iconBgColor": "#aa5c39"
    },
    "QuickRecovery": {
        "isAncient": true,
        "statsGiven": {
            "hpregen": 5
        },
        "name": "Quick Recovery",
        "desc": "Increases your natural health regeneration by +$5$.",
        "icon": "psychic-waves",
        "iconBgColor": "#aa5c39"
    },
    "ArmorManifestation": {
        "isAncient": true,
        "statsGiven": {
            "armorClass": 3
        },
        "name": "Armor Manifestation",
        "desc": "Increases your natural armor class by +$3$.",
        "icon": "armor-vest",
        "iconBgColor": "#aa5c39"
    },
    "DefensiveManifestation": {
        "isAncient": true,
        "statsGiven": {
            "defense": 3
        },
        "name": "Defensive Manifestation",
        "desc": "Increases your natural defense by +$3$.",
        "icon": "body-balance",
        "iconBgColor": "#aa5c39"
    },
    "OffensiveManifestation": {
        "isAncient": true,
        "statsGiven": {
            "offense": 3
        },
        "name": "Offensive Manifestation",
        "desc": "Increases your natural offense by +$3$.",
        "icon": "sword-clash",
        "iconBgColor": "#aa5c39"
    },
    "EnergyManifestation": {
        "isAncient": true,
        "statsGiven": {
            "energyResist": 10
        },
        "name": "Energy Manifestation",
        "desc": "Increases your natural energy resistance by +$10$.",
        "icon": "magic-swirl",
        "iconBgColor": "#aa5c39"
    },
    "IceManifestation": {
        "isAncient": true,
        "statsGiven": {
            "iceResist": 10
        },
        "name": "Ice Manifestation",
        "desc": "Increases your natural ice resistance by +$10$.",
        "icon": "melting-ice-cube",
        "iconBgColor": "#aa5c39"
    },
    "FireManifestation": {
        "isAncient": true,
        "statsGiven": {
            "fireResist": 10
        },
        "name": "Fire Manifestation",
        "desc": "Increases your natural fire resistance by +$10$.",
        "icon": "celebration-fire",
        "iconBgColor": "#aa5c39"
    },
    "NecroticManifestation": {
        "isAncient": true,
        "statsGiven": {
            "necroticResist": 10
        },
        "name": "Necrotic Manifestation",
        "desc": "Increases your natural necrotic resistance by +$10$.",
        "icon": "plasma-bolt",
        "iconBgColor": "#aa5c39"
    },
    "DiseaseManifestation": {
        "isAncient": true,
        "statsGiven": {
            "diseaseResist": 10
        },
        "name": "Disease Manifestation",
        "desc": "Increases your natural disease resistance by +$10$.",
        "icon": "death-zone",
        "iconBgColor": "#aa5c39"
    },
    "PoisonManifestation": {
        "isAncient": true,
        "statsGiven": {
            "poisonResist": 10
        },
        "name": "Poison Manifestation",
        "desc": "Increases your natural poison resistance by +$10$.",
        "icon": "poison-gas",
        "iconBgColor": "#aa5c39"
    },
    "MachleumSkin": {
        "isAncient": true,
        "statsGiven": {
            "physicalResist": 5
        },
        "name": "Machleum Skin",
        "desc": "Increases your natural physical damage resistance by +$5$.",
        "icon": "frozen-body",
        "iconBgColor": "#aa5c39"
    },
    "MachleumAura": {
        "isAncient": true,
        "statsGiven": {
            "magicalResist": 5
        },
        "name": "Machleum Aura",
        "desc": "Increases your natural magical damage resistance by +$5$.",
        "icon": "aura",
        "iconBgColor": "#aa5c39"
    },
    "SurvivalTechniques": {
        "isAncient": true,
        "statsGiven": {
            "hp": 10
        },
        "name": "Survival Techniques",
        "desc": "Increase your natural maximum health by +$10$.",
        "icon": "health-increase",
        "iconBgColor": "#aa5c39"
    },
    "Clearcasting": {
        "valuePerTier": 3,
        "name": "Clearcasting",
        "desc": "Increase the chance of not consuming mana when casting a spell by $3$%.",
        "icon": "magic-swirl"
    },
    "CalmMind": {
        "statsGiven": {
            "mpregen": 2
        },
        "name": "Calm Mind",
        "desc": "Gain +$2$ to your mana regeneration.",
        "icon": "psychic-waves"
    },
    "ManaPool": {
        "statsGiven": {
            "mp": 10
        },
        "name": "Mana Pool",
        "desc": "Gain +$10$ additional mana.",
        "icon": "drink-me"
    },
    "EffectivePotions": {
        "valuePerTier": 50,
        "name": "Effective Potions",
        "desc": "Increase the healing from all potions by +$50$.",
        "icon": "bubbling-flask"
    },
    "DeathGrip": {
        "valuePerTier": 10,
        "name": "Death Grip",
        "desc": "Decrease your chance of dropping items on death by +$10$%.",
        "icon": "drop-weapon"
    },
    "NaturalArmor": {
        "statsGiven": {
            "armorClass": 2
        },
        "name": "Natural Armor",
        "desc": "Increases your natural Armor Class by +$2$.",
        "icon": "internal-injury"
    },
    "CarefulTouch": {
        "valuePerTier": 10,
        "name": "Careful Touch",
        "desc": "Decrease the damage your items take by +$10$%.",
        "icon": "blacksmith"
    },
    "InternalFortitude": {
        "statsGiven": {
            "savingThrow": 2
        },
        "name": "Internal Fortitude",
        "desc": "Increase your saving throw to resist spells by +$2$.",
        "icon": "brain"
    },
    "CarrotVision": {
        "statsGiven": {
            "perception": 2
        },
        "name": "Carrot Vision",
        "desc": "Increase your perception by +$2$.",
        "icon": "carrot"
    },
    "AngryFace": {
        "statsGiven": {
            "threatMultiplier": 0.1
        },
        "name": "Angry Face",
        "desc": "Increase the amount of agro you generate by +$10$%.",
        "icon": "unfriendly-fire"
    },
    "SilentStrikes": {
        "statsGiven": {
            "threatMultiplier": -0.1
        },
        "name": "Silent Strikes",
        "desc": "Decrease the amount of agro you generate by +$10$%.",
        "icon": "diamonds-smile"
    },
    "EtherRecombobulation": {
        "valuePerTier": 0.1,
        "name": "Ether Recombulation",
        "desc": "When reviving, you restore an additional +$10$% HP.",
        "icon": "glowing-artifact"
    },
    "RecombobulativeBarrier": {
        "valuePerTier": 2,
        "name": "Recombulative Barrier",
        "desc": "When reviving, you have additional +$2$ ticks of invulnerability.",
        "icon": "surrounded-shield"
    },
    "BigBelt": {
        "name": "Big Belt",
        "desc": "You can now place halberds into your belt.",
        "icon": "halberd"
    },
    "SweetTooth": {
        "valuePerTier": 5,
        "name": "Sweet Tooth",
        "desc": "All food boosts your HP and MP regen by +$5$%.",
        "icon": "candy-canes"
    },
    "SlowDigestion": {
        "valuePerTier": 0.2,
        "name": "Slow Digestion",
        "desc": "Increase the duration of food by +$20$%.",
        "icon": "blackcurrant"
    },
    "WintersEmbrace": {
        "valuePerTier": 10,
        "name": "Winters Embrace",
        "desc": "When chilling an opponent, you have a +$10$% chance to freeze them solid instead.",
        "icon": "frozen-orb"
    },
    "Unimbued": {
        "name": "Unimbued",
        "desc": "This trait is really just a placeholder for items that can be imbued.",
        "icon": "uncertainty"
    },
    "HealingFocus": {
        "statsGiven": {
            "healingBoostPercent": 10
        },
        "name": "Healing Focus",
        "desc": "Heal $10$% more health when you use Restoration magic.",
        "icon": "health-increase"
    },
    "NecroticFocus": {
        "statsGiven": {
            "necroticBoostPercent": 10
        },
        "name": "Necrotic Focus",
        "desc": "Deal +$10$% more necrotic damage.",
        "icon": "plasma-bolt"
    },
    "NecroticWard": {
        "valuePerTier": 0.1,
        "name": "Necrotic Ward",
        "desc": "Protect against +$10$% more necrotic damage.",
        "icon": "transportation-rings"
    },
    "TotemSpecialty": {
        "valuePerTier": 0.05,
        "name": "Totem Specialty",
        "desc": "Spells cost -$5$% while holding a totem.",
        "icon": "grapple"
    },
    "HolyAffliction": {
        "statsGiven": {
            "spellCriticalPercent": 3
        },
        "name": "Holy Affliction",
        "desc": "Gain +$3$% spell critical chance.",
        "icon": "large-wound"
    },
    "IrresistibleStun": {
        "valuePerTier": 2,
        "name": "Irresistible Stun",
        "desc": "Increase your Stun rolls and duration by +$2$.",
        "icon": "knockout"
    },
    "DazingOutlook": {
        "valuePerTier": 2,
        "name": "Dazing Outlook",
        "desc": "Increase your Daze rolls, duration and potency by +$2$.",
        "icon": "knockout"
    },
    "Roots": {
        "valuePerTier": 5,
        "name": "Roots",
        "desc": "Increase your Snare rolls by +5 and roots the target in place.",
        "icon": "light-thorny-triskelion"
    },
    "BlindingLight": {
        "valuePerTier": 2,
        "name": "Blinding Light",
        "desc": "Increase your Blind rolls and duration by +$2$.",
        "icon": "evil-eyes"
    },
    "EffectiveSupporter": {
        "valuePerTier": 0.1,
        "name": "Effective Supporter",
        "desc": "Increase the duration of all buffs by +$10$%.",
        "icon": "burning-passion"
    },
    "DebilitatingDisease": {
        "valuePerTier": 0.25,
        "name": "Debilitating Disease",
        "desc": "Your Disease spell will also lower the CON, WIL, and Accuracy of the target.",
        "icon": "death-juice"
    },
    "ContagiousPlague": {
        "valuePerTier": 3,
        "name": "Contagious Plague",
        "desc": "Your Plague spell will spread to a nearby target not suffering from Plague once every 3s.",
        "icon": "death-zone"
    },
    "Pandemic": {
        "valuePerTier": 1,
        "name": "Pandemic",
        "desc": "Your Plague spell will spread to +$1$ nearby target immediately.",
        "icon": "telepathy"
    },
    "DiseaseFocus": {
        "statsGiven": {
            "diseaseBoostPercent": 10
        },
        "name": "Disease Focus",
        "desc": "Deal +$10$% more disease damage.",
        "icon": "death-zone"
    },
    "SnapHeal": {
        "name": "Snap Heal",
        "desc": "Cast Heal on a target automatically after reviving them.",
        "icon": "heart-beats"
    },
    "ShadowStepper": {
        "name": "Shadow Stepper",
        "desc": "Gain +$5$ stealth.",
        "statsGiven": {
            "stealth": 5
        },
        "icon": "hidden"
    },
    "FamiliarFortitude": {
        "valuePerTier": 0.2,
        "name": "Familiar Fortitude",
        "desc": "Familiars gain +$20$% HP.",
        "icon": "double-ringed-orb"
    },
    "FamiliarStrength": {
        "valuePerTier": 2,
        "name": "Familiar Strength",
        "desc": "Familiars gain +$2$% STR/INT/WIS.",
        "icon": "double-ringed-orb"
    },
    "FamiliarFists": {
        "name": "Familiar Fists",
        "desc": "Familiars gain the ability to rapidpunch.",
        "icon": "fire-punch"
    },
    "DivineDancing": {
        "name": "Divine Dancing",
        "desc": "Move around automatically while stunned.",
        "valuePerTier": 1,
        "icon": "boot-kick"
    },
    "CombatFortitude": {
        "valuePerTier": 1,
        "name": "Combat Fortitude",
        "desc": "Reduce your chance of getting stunned by physical attacks.",
        "icon": "defibrilate"
    },
    "MissileBarrage": {
        "valuePerTier": 2,
        "name": "Missile Barrage",
        "desc": "Shoot 2 more magic missiles when attacking.",
        "icon": "missile-swarm"
    },
    "BouncingMissiles": {
        "valuePerTier": 10,
        "name": "Bouncing Missiles",
        "desc": "Magic Missiles have a +$10$% chance to bounce to another target.",
        "icon": "missile-swarm"
    },
    "BiggerBottles": {
        "valuePerTier": 2,
        "name": "Bigger Bottles",
        "desc": "Conjure Healing will now be +$2$x more potent, and have +$2$x more ounces.",
        "icon": "brandy-bottle"
    },
    "ConcussiveBolt": {
        "valuePerTier": 3,
        "name": "Concussive Bolt",
        "desc": "Magic Bolt has a +$4$% chance of stunning.",
        "icon": "burning-dot"
    },
    "EnergyFocus": {
        "statsGiven": {
            "energyBoostPercent": 10
        },
        "name": "Energy Focus",
        "desc": "Deal +$10$% more energy damage.",
        "icon": "plasma-bolt"
    },
    "ThermalBarrier": {
        "valuePerTier": 0.1,
        "name": "Thermal Barrier",
        "desc": "Protect against +$10$% more fire and ice damage.",
        "icon": "transportation-rings"
    },
    "WandSpecialty": {
        "valuePerTier": 0.05,
        "name": "Wand Specialty",
        "desc": "Spells cost -$5$% while holding a wand.",
        "icon": "fairy-wand"
    },
    "EnergyWaveWiden": {
        "valuePerTier": 1,
        "name": "Energy Wave Widen",
        "desc": "Energy Wave extends outwards an additional +$1$ tile.",
        "icon": "beams-aura"
    },
    "ExpandedMemory": {
        "valuePerTier": 5,
        "name": "Expanded Memory",
        "desc": "You can memorize an additional +$5$ teleport locations.",
        "icon": "teleport"
    },
    "ForgedFire": {
        "valuePerTier": 3,
        "name": "Forged Fire",
        "desc": "Your fire spells burn the opponent more quickly.",
        "icon": "flame-spin"
    },
    "ChilledCore": {
        "valuePerTier": 3,
        "name": "Chilled Core",
        "desc": "Your ice spells chill the opponent more quickly.",
        "icon": "ice-spell-cast"
    },
    "FireMistWiden": {
        "valuePerTier": 1,
        "name": "Fire Mist Widen",
        "desc": "Your Fire Mist spell will extend outwards and additional +$1$ tile.",
        "icon": "kaleidoscope-pearls"
    },
    "IceMistWiden": {
        "valuePerTier": 1,
        "name": "Ice Mist Widen",
        "desc": "Your Ice Mist spell will extend outwards and additional +$1$ tile.",
        "icon": "kaleidoscope-pearls"
    },
    "LightenArmor": {
        "valuePerTier": 1,
        "name": "Lighten Armor",
        "desc": "Armor no longer hinders your spellcasting.",
        "icon": "kevlar"
    },
    "DrainSlash": {
        "valuePerTier": 5,
        "name": "Drain Slash",
        "desc": "You have a +$5$% chance of casting Drain when physically attacking an enemy.",
        "icon": "running-ninja"
    },
    "AsperSlash": {
        "valuePerTier": 5,
        "name": "Asper Slash",
        "desc": "You have a +$5$% chance of casting Asper when physically attacking an enemy.",
        "icon": "running-ninja"
    },
    "StrongMind": {
        "valuePerTier": 0.05,
        "name": "Strong Mind",
        "desc": "When attacking, add +$5$% of your INT to your attack stat.",
        "icon": "brain"
    },
    "FireFocus": {
        "statsGiven": {
            "fireBoostPercent": 10
        },
        "name": "Fire Focus",
        "desc": "Deal +$10$% more fire damage.",
        "icon": "plasma-bolt"
    },
    "IceFocus": {
        "statsGiven": {
            "iceBoostPercent": 10
        },
        "name": "Ice Focus",
        "desc": "Deal +$10$% more ice damage.",
        "icon": "plasma-bolt"
    },
    "ExpansiveKnowledge": {
        "name": "Expansive Knowledge",
        "desc": "Your identify spell can identify the finest details of an item.",
        "icon": "uncertainty"
    },
    "MagicalStrikes": {
        "name": "Magical Strikes",
        "desc": "Your wands can be equipped in your Ammo slot, and apply to physical attacks.",
        "icon": "orb-wand"
    },
    "OffhandFinesse": {
        "valuePerTier": 0.1,
        "name": "Offhand Finesse",
        "desc": "Increase your offhand attack damage by +$10$%.",
        "icon": "crossed-sabres"
    },
    "ImprovedSteal": {
        "valuePerTier": 20,
        "name": "Improved Steal",
        "desc": "Increase the chance of a successful steal by +$20$%.",
        "icon": "take-my-money"
    },
    "ImprovedHide": {
        "valuePerTier": 1,
        "name": "Improved Hide",
        "desc": "Your stealth meter is drained more slowly when surrounded by enemies.",
        "icon": "hidden"
    },
    "NimbleFingers": {
        "valuePerTier": 5,
        "name": "Nimble Fingers",
        "desc": "Increase the chance of a successful steal by +$5$%, and increases the amount of gold you take per steal.",
        "icon": "take-my-money"
    },
    "DarkerShadows": {
        "statsGiven": {
            "stealth": 2
        },
        "name": "Darker Shadows",
        "desc": "Increases your Stealth stat by +$2$.",
        "icon": "hidden"
    },
    "DarknessWiden": {
        "valuePerTier": 1,
        "name": "Darkness Widen",
        "desc": "Your Darkness spell will extend outwards and additional +$1$ tile.",
        "icon": "dust-cloud"
    },
    "BetterBackstab": {
        "valuePerTier": 0.5,
        "name": "Better Backstab",
        "desc": "Your backstab damage is increased by +$50$%.",
        "icon": "backstab"
    },
    "ThrownTraps": {
        "valuePerTier": 3,
        "name": "Thrown Traps",
        "desc": "You can now set your traps anywhere, instead of only adjacent to you.",
        "icon": "beveled-star"
    },
    "ReusableTraps": {
        "valuePerTier": 1,
        "name": "Reusable Traps",
        "desc": "Your traps now trigger an additional +$1$ time(s).",
        "icon": "regeneration"
    },
    "StrongerTraps": {
        "valuePerTier": 1,
        "name": "Stronger Traps",
        "desc": "Your traps are now +$1$x stronger.",
        "icon": "beveled-star"
    },
    "WiderTraps": {
        "valuePerTier": 2,
        "name": "Wider Traps",
        "desc": "Your area-of-effect traps are now +$2$ tiles wider.",
        "icon": "kaleidoscope-pearls"
    },
    "GentleStep": {
        "name": "Gentle Step",
        "desc": "You no longer trigger traps by walking over them.",
        "icon": "wingfoot"
    },
    "LockpickSpecialty": {
        "valuePerTier": 1,
        "name": "Lockpick Specialty",
        "desc": "Your skill when picking locks is considered to be +$1$.",
        "icon": "unlocking"
    },
    "Appraise": {
        "name": "Appraise",
        "desc": "You innately know the value of items.",
        "icon": "receive-money"
    },
    "PhilosophersStone": {
        "valuePerTier": 10,
        "name": "Philosophers Stone",
        "desc": "Your transmutes generate +$10$% more gold.",
        "icon": "coins"
    },
    "EnhancedApplications": {
        "valuePerTier": 1200,
        "name": "Enhanced Applications",
        "desc": "Your applied effects last +$1200$ seconds longer.",
        "icon": "bloody-sword"
    },
    "CorrosivePoison": {
        "name": "Corrosive Poison",
        "desc": "Your poison will also lower the mitigation of the afflicted target.",
        "icon": "poison-gas"
    },
    "DegenerativeVenom": {
        "name": "Degenerative Venom",
        "desc": "Your venom will also lower the perception of the afflicted target.",
        "icon": "dripping-goo"
    },
    "ShadowSheath": {
        "valuePerTier": 0.1,
        "name": "Shadow Sheath",
        "desc": "Your weapons are +$10$% easier to conceal.",
        "icon": "thrown-knife"
    },
    "ShadowDaggers": {
        "valuePerTier": 3,
        "name": "Shadow Daggers",
        "desc": "Your non-backstab attacks have a +$3$% chance to become backstabs.",
        "icon": "daggers"
    },
    "ShadowSwap": {
        "valuePerTier": 2,
        "name": "Shadow Swap",
        "desc": "You have a +$2$% chance to automatically become hidden after attacking.",
        "icon": "shadow-follower"
    },
    "PoisonFocus": {
        "statsGiven": {
            "poisonBoostPercent": 10
        },
        "name": "Poison Focus",
        "desc": "Deal +$10$% more poison damage.",
        "icon": "poison-gas"
    },
    "TripleShot": {
        "valuePerTier": 1,
        "name": "Triple Shot",
        "desc": "You fire an additional arrow when using Multi Shot.",
        "icon": "double-shot"
    },
    "EndlessQuiver": {
        "valuePerTier": 5,
        "name": "Endless Quiver",
        "desc": "You have a +$5$% chance to not consume ammo when shooting.",
        "icon": "quiver"
    },
    "StrongShots": {
        "valuePerTier": 5,
        "name": "Strong Shots",
        "desc": "Increase the damage of your arrows by +$1$ tier.",
        "icon": "middle-arrow"
    },
    "OffensiveEncore": {
        "valuePerTier": 1,
        "name": "Offensive Encore",
        "desc": "Restore +$1$ MP when attacking while singing.",
        "icon": "musical-notes"
    },
    "Shadowsong": {
        "name": "Shadowsong",
        "desc": "You can sing while hidden.",
        "icon": "musical-score"
    },
    "DirgeOfCerberus": {
        "valuePerTier": 1,
        "name": "Dirge of Cerberus",
        "desc": "Your Deadly Dirge attacks an additional +$1$ time(s).",
        "icon": "music-spell"
    },
    "SoothingTranquility": {
        "valuePerTier": 1,
        "name": "Soothing Tranquility",
        "desc": "Your Tranquil Trill heals an additional +$1$ time(s).",
        "icon": "music-spell"
    },
    "DefensiveVoice": {
        "valuePerTier": 5,
        "name": "Defensive Voice",
        "desc": "Your defense is increased by +$5$ while singing.",
        "icon": "sing"
    },
    "ShieldingVoice": {
        "valuePerTier": 15,
        "name": "Shielding Voice",
        "desc": "Your magical and physical resistance is increased by +$15$ while singing.",
        "icon": "sing"
    },
    "ReflectingVoice": {
        "valuePerTier": 2,
        "name": "Reflecting Voice",
        "desc": "Your spell reflect chance is increased by +$2$ while singing.",
        "icon": "sing"
    },
    "BoomerangArm": {
        "name": "Boomerang Arm",
        "desc": "All weapons are treated as returning.",
        "icon": "boomerang"
    },
    "Multithrow": {
        "valuePerTier": 1,
        "name": "Multithrow",
        "desc": "All thrown weapons attack an additional time.",
        "icon": "boomerang-cross"
    },
    "BouncingThrows": {
        "valuePerTier": 5,
        "name": "Bouncing Throws",
        "desc": "All thrown weapons have a +$5$% chance to bounce to another target.",
        "icon": "bouncing-sword"
    },
    "BouncingArrows": {
        "valuePerTier": 5,
        "name": "Bouncing Arrows",
        "desc": "All ranged weapons have a +$5$% chance to bounce to another target.",
        "icon": "arrow-cluster"
    },
    "VortexWiden": {
        "valuePerTier": 1,
        "name": "Vortex Widen",
        "desc": "Your Vortex spell will extend outwards and additional +$1$ tile.",
        "icon": "wind-hole"
    },
    "ReplenishingShadows": {
        "valuePerTier": 0.1,
        "name": "Replenishing Shadows",
        "desc": "+$10$% of your MP regen will apply to stealth while hidden.",
        "icon": "cross-mark"
    },
    "HiddenHealing": {
        "valuePerTier": 0.2,
        "name": "Hidden Healing",
        "desc": "+$20$% of your Hide stealth will apply to your HP regeneration while hidden.",
        "icon": "hidden"
    },
    "ThiefGrip": {
        "name": "Thief Grip",
        "desc": "Your shortswords and daggers attack with the profile of a longsword.",
        "icon": "dagger-rose"
    },
    "SharpVision": {
        "statsGiven": {
            "accuracy": 1
        },
        "name": "Sharp Vision",
        "desc": "Increases your Accuracy stat by +$1$.",
        "icon": "bullseye"
    },
    "SwordTricks": {
        "statsGiven": {
            "offense": 1
        },
        "name": "Sword Tricks",
        "desc": "Increases your Offense stat by +$1$.",
        "icon": "sword-clash"
    },
    "FunkyMoves": {
        "statsGiven": {
            "defense": 1
        },
        "name": "Funky Moves",
        "desc": "Increases your Defense stat by +$1$.",
        "icon": "wingfoot"
    },
    "Swashbuckler": {
        "valuePerTier": 0.1,
        "name": "Swashbuckler",
        "desc": "Reduce your chance of an outgoing glancing blow by +$10$%.",
        "icon": "spiral-thrust"
    },
    "Riposte": {
        "valuePerTier": 3,
        "name": "Riposte",
        "desc": "Increase your chance of counter-attacking while parrying by +$3$%.",
        "icon": "spinning-sword"
    },
    "ForcefulStrike": {
        "statsGiven": {
            "physicalDamageBoostPercent": 10
        },
        "name": "Forceful Strike",
        "desc": "Gain +$10$% to your physical damage.",
        "icon": "striped-sword"
    },
    "GlowingWeapon": {
        "valuePerTier": 5,
        "name": "Glowing Weapon",
        "desc": "Increase your chance of parry applying to a magical attack by +$5$%.",
        "icon": "glowing-hands"
    },
    "ViciousAssault": {
        "valuePerTier": 5,
        "name": "Vicious Assault",
        "desc": "Increase your chance of attacking a second time while enraged by +$5$%.",
        "icon": "fire-dash"
    },
    "BoostedBoost": {
        "valuePerTier": 2,
        "name": "Boosted Boost",
        "desc": "Increase the stats boosted from Boost by +$2$.",
        "icon": "fist"
    },
    "Shieldbearer": {
        "name": "Shieldbearer",
        "desc": "Shields also work in your right hand.",
        "icon": "edged-shield"
    },
    "SilverSkin": {
        "statsGiven": {
            "physicalResist": 10
        },
        "name": "Silver Skin",
        "desc": "Ignore +$10$ physical damage.",
        "icon": "leg-armor"
    },
    "HolyProtection": {
        "statsGiven": {
            "magicalResist": 10
        },
        "name": "Holy Protection",
        "desc": "Ignore +$10$ magical damage.",
        "icon": "holy-symbol"
    },
    "SterlingArmor": {
        "valuePerTier": 5,
        "name": "Sterling Armor",
        "desc": "Increase your chance of ignoring incoming glancing blows by +$5$%.",
        "icon": "chest-armor"
    },
    "DoubleThrust": {
        "name": "Double Thrust",
        "desc": "Perform a second, weaker attack when using Thruststrike.",
        "icon": "saber-slash"
    },
    "ReflectiveCoating": {
        "valuePerTier": 2,
        "name": "Reflective Coating",
        "desc": "Increase your chance of reflecting spells by +$2$% per shield you hold.",
        "icon": "mirror-mirror"
    },
    "Multitarget": {
        "valuePerTier": 4,
        "name": "Multitarget",
        "desc": "Increase the number of targets Multistrike can hit by +$4$.",
        "icon": "sword-spin"
    },
    "Multifocus": {
        "valuePerTier": 10,
        "name": "Multifocus",
        "desc": "Reduce the defensive penalty of Multistrike by -$10$%.",
        "icon": "sword-spin"
    },
    "MartialAcuity": {
        "valuePerTier": 1,
        "name": "Martial Acuity",
        "desc": "Increase your to-hit when attacking by +$1$.",
        "icon": "armor-punch"
    },
    "MartialAgility": {
        "valuePerTier": 0.2,
        "name": "Martial Agility",
        "desc": "Increase your chance of dodging a melee attack by +$20$% while you have an empty right hand.",
        "icon": "wingfoot"
    },
    "Punchkick": {
        "name": "Punchkick",
        "desc": "When using Jumpkick, you will also punch your target.",
        "icon": "high-kick"
    },
    "BrassKnuckles": {
        "valuePerTier": 1,
        "name": "Brass Knuckles",
        "desc": "Increase the damage of your fist attacks by +$1$ tier.",
        "icon": "brass-knuckles"
    },
    "StrongSweep": {
        "valuePerTier": 0.1,
        "name": "Strong Sweep",
        "desc": "Increase the damage of your sweep attacks by +$10$%.",
        "icon": "foot-trip"
    },
    "ImprovedRapidpunch": {
        "valuePerTier": 1,
        "name": "Improved Rapidpunch",
        "desc": "Reduce the accuracy penalty and increase the number of hits for Rapidpunch.",
        "icon": "fire-punch"
    },
    "UnarmoredSavant": {
        "valuePerTier": 60,
        "name": "Unarmored Savant",
        "desc": "Your base mitigation will be 60 if you have a fur, robe, or cloak in your main armor slot. This bonus is lowered if you are holding anything in your right hand.",
        "icon": "robe"
    },
    "StunningFist": {
        "valuePerTier": 2,
        "name": "Stunning Fist",
        "desc": "Increase the chance of stunning an opponent with your fist hits.",
        "icon": "thor-fist"
    },
    "ConsumingRage": {
        "name": "Consuming Rage",
        "desc": "Periodically consume Rage while attacking to boost your damage.",
        "icon": "enrage"
    },
    "TitanGrip": {
        "name": "Titan Grip",
        "desc": "Use any two-handed weapon in one hand.",
        "icon": "mailed-fist"
    },
    "BalancedGrip": {
        "name": "Balanced Grip",
        "desc": "Use any one-handed weapon in your offhand.",
        "icon": "switch-weapon"
    },
    "DeepCuts": {
        "valuePerTier": 0.05,
        "name": "Deep Cuts",
        "desc": "Your cleaves cut deep, and have a 10% chance to bleed the target for an additional $5$% of your cleave damage over 15 seconds.",
        "icon": "bloody-sword"
    },
    "MaceSpecialty": {
        "statsGiven": {
            "maceBonus": 1
        },
        "name": "Mace Specialty",
        "desc": "Your advanced mastery of maces grants you an additional mace skill level.",
        "icon": "flanged-mace"
    },
    "AxeSpecialty": {
        "statsGiven": {
            "axeBonus": 1
        },
        "name": "Axe Specialty",
        "desc": "Your advanced mastery of axes grants you an additional axe skill level.",
        "icon": "battered-axe"
    },
    "DaggerSpecialty": {
        "statsGiven": {
            "daggerBonus": 1
        },
        "name": "Dagger Specialty",
        "desc": "Your advanced mastery of daggers grants you an additional dagger skill level.",
        "icon": "bone-knife"
    },
    "SwordSpecialty": {
        "statsGiven": {
            "swordBonus": 1
        },
        "name": "Sword Specialty",
        "desc": "Your advanced mastery of swords grants you an additional sword skill level.",
        "icon": "gladius"
    },
    "ShortswordSpecialty": {
        "statsGiven": {
            "shortswordBonus": 1
        },
        "name": "Shortsword Specialty",
        "desc": "Your advanced mastery of shortswords grants you an additional shortsword skill level.",
        "icon": "broad-dagger"
    },
    "PolearmSpecialty": {
        "statsGiven": {
            "polearmBonus": 1
        },
        "name": "Polearm Specialty",
        "desc": "Your advanced mastery of polearms grants you an additional polearm skill level.",
        "icon": "sharp-halberd"
    },
    "MagicalSpecialty": {
        "statsGiven": {
            "wandBonus": 1
        },
        "name": "Magical Specialty",
        "desc": "Your advanced mastery of magical weapons grants you an additional magical weapons skill level.",
        "icon": "crystal-wand"
    },
    "StaffSpecialty": {
        "statsGiven": {
            "staffBonus": 1
        },
        "name": "Staff Specialty",
        "desc": "Your advanced mastery of staves grants you an additional staff skill level.",
        "icon": "bo"
    },
    "MartialSpecialty": {
        "statsGiven": {
            "martialBonus": 1
        },
        "name": "Martial Specialty",
        "desc": "Your advanced mastery of martial arts grants you an additional martial skill level.",
        "icon": "mailed-fist"
    },
    "BowSpecialty": {
        "statsGiven": {
            "rangedBonus": 1
        },
        "name": "Bow Specialty",
        "desc": "Your advanced mastery of bows grants you an additional bow skill level.",
        "icon": "crossbow"
    },
    "ThrowingSpecialty": {
        "statsGiven": {
            "throwingBonus": 1
        },
        "name": "Throwing Specialty",
        "desc": "Your advanced mastery of throwing weapons grants you an additional throwing skill level.",
        "icon": "discobolus"
    },
    "TwohandedSpecialty": {
        "statsGiven": {
            "twohandedBonus": 1
        },
        "name": "Twohanded Specialty",
        "desc": "Your advanced mastery of two-handed weapons grants you an additional two-handed skill level.",
        "icon": "croc-sword"
    },
    "ImbueFrost": {
        "name": "Imbue Frost",
        "desc": "Augment physical attacks to do bonus ice damage. Cost: 100 MP",
        "borderColor": "#00a",
        "spellGiven": "ImbueFrost",
        "icon": "magic-palm"
    },
    "ImbueFlame": {
        "name": "Imbue Flame",
        "desc": "Augment physical attacks to do bonus fire damage. Cost: 100 MP",
        "borderColor": "#a00",
        "spellGiven": "ImbueFlame",
        "icon": "magic-palm"
    },
    "ImbueEnergy": {
        "name": "Imbue Energy",
        "desc": "Augment physical attacks to do bonus energy damage. Cost: 100 MP",
        "borderColor": "#a0a",
        "spellGiven": "ImbueEnergy",
        "icon": "magic-palm"
    },
    "DarkVision": {
        "name": "Dark Vision",
        "desc": "See in the dark. Cost: 25 MP",
        "borderColor": "#ccc",
        "spellGiven": "DarkVision",
        "icon": "angry-eyes"
    },
    "TrueSight": {
        "name": "True Sight",
        "desc": "See hidden walls and invisible creatures. Cost: 25 MP",
        "borderColor": "#ccc",
        "spellGiven": "TrueSight",
        "icon": "all-seeing-eye"
    },
    "EagleEye": {
        "name": "Eagle Eye",
        "desc": "See through trees and waters. Cost: 25 MP",
        "borderColor": "#ccc",
        "spellGiven": "EagleEye",
        "icon": "dead-eye"
    },
    "SecondWind": {
        "name": "Second Wind",
        "desc": "Prevent gear loss on strip/eat. Cost: 100 MP",
        "borderColor": "#ccc",
        "spellGiven": "SecondWind",
        "icon": "wing-cloak"
    },
    "FleetOfFoot": {
        "name": "Fleet of Foot",
        "desc": "Take less damage from falls and be immune to prone. Cost: 25 MP",
        "borderColor": "#ccc",
        "spellGiven": "FleetOfFoot",
        "icon": "wingfoot"
    },
    "Invisibility": {
        "name": "Invisibility",
        "desc": "Make your target invisible. Cost: 100 MP",
        "borderColor": "#ccc",
        "spellGiven": "Invisibility",
        "icon": "invisible"
    },
    "BarFire": {
        "name": "Bar Fire",
        "desc": "Resist some fire damage. Cost: 20 MP",
        "borderColor": "#a00",
        "spellGiven": "BarFire",
        "icon": "rosa-shield"
    },
    "BarFrost": {
        "name": "Bar Frost",
        "desc": "Resist some frost damage. Cost: 20 MP",
        "borderColor": "#00a",
        "spellGiven": "BarFrost",
        "icon": "rosa-shield"
    },
    "BarWater": {
        "name": "Bar Water",
        "desc": "Resist some water damage. Cost: 20 MP",
        "borderColor": "#00f",
        "spellGiven": "BarWater",
        "icon": "rosa-shield"
    },
    "BarNecro": {
        "name": "Bar Necro",
        "desc": "Resist some necrotic, poison, and disease damage. Cost: 20 MP",
        "borderColor": "#0f0",
        "spellGiven": "BarNecro",
        "icon": "rosa-shield"
    },
    "Aid": {
        "name": "Aid",
        "desc": "Increased DEX and offensive capabilities. Cost: 100 MP",
        "borderColor": "#ccc",
        "iconColor": "#000",
        "spellGiven": "Aid",
        "icon": "three-friends"
    },
    "Regen": {
        "name": "Regen",
        "desc": "Grant regeneration to a single target. Cost: 25 MP.",
        "borderColor": "#0a0",
        "spellGiven": "Regen",
        "icon": "star-swirl"
    },
    "Protection": {
        "name": "Protection",
        "desc": "Resist some physical damage. Cost: 65 MP",
        "borderColor": "#ccc",
        "spellGiven": "Protection",
        "icon": "energy-shield"
    },
    "Absorption": {
        "name": "Absorption",
        "desc": "Resist some magical damage. Cost: 65 MP",
        "borderColor": "#ccc",
        "spellGiven": "Absorption",
        "icon": "magic-swirl"
    },
    "Haste": {
        "name": "Haste",
        "desc": "Act twice as fast. Cost: 200 MP",
        "borderColor": "#ccc",
        "spellGiven": "Haste",
        "icon": "time-trap"
    },
    "PowerwordBarNecro": {
        "name": "Powerword Bar Necro",
        "borderColor": "#0a0",
        "desc": "Give your entire party necrotic resistance. Cost: 150 MP.",
        "icon": "rosa-shield",
        "spellGiven": "PowerwordBarNecro"
    },
    "PowerwordBarFire": {
        "name": "Powerword Bar Fire",
        "borderColor": "#a00",
        "desc": "Give your entire party fire resistance. Cost: 150 MP.",
        "icon": "rosa-shield",
        "spellGiven": "PowerwordBarFire"
    },
    "PowerwordBarFrost": {
        "name": "Powerword Bar Frost",
        "borderColor": "#00a",
        "desc": "Give your entire party frost resistance. Cost: 150 MP.",
        "icon": "rosa-shield",
        "spellGiven": "PowerwordBarFrost"
    },
    "VitalEssence": {
        "name": "Vital Essence",
        "desc": "Increased HP and AC. Cost: 100 MP",
        "borderColor": "#0a0",
        "iconColor": "#000",
        "spellGiven": "VitalEssence",
        "icon": "bell-shield"
    },
    "HolyAura": {
        "name": "Holy Aura",
        "desc": "Make a single target invulnerable to some damage. Cost: 150 MP.",
        "borderColor": "#ccc",
        "spellGiven": "HolyAura",
        "icon": "aura"
    },
    "Autoheal": {
        "name": "Autoheal",
        "desc": "Automatically heal when your HP gets low. Cost: 100 MP",
        "borderColor": "#aa0",
        "iconColor": "#000",
        "spellGiven": "Autoheal",
        "icon": "self-love"
    },
    "MagicMirror": {
        "name": "Magic Mirror",
        "desc": "Increase your chance to reflect magical damage. Cost: 125 MP",
        "borderColor": "#ccc",
        "spellGiven": "MagicMirror",
        "icon": "mirror-mirror"
    },
    "FrostspikesAura": {
        "name": "Frostspikes Aura",
        "desc": "Make a single target reflect physical damage and counter it with ice. Cost: 100 MP.",
        "borderColor": "#000080",
        "spellGiven": "FrostspikesAura",
        "icon": "barbed-coil"
    },
    "FirethornsAura": {
        "name": "Firethorns Aura",
        "desc": "Make a single target reflect physical damage and counter it with fire. Cost: 100 MP.",
        "borderColor": "#f00",
        "spellGiven": "FirethornsAura",
        "icon": "barbed-coil"
    },
    "Lifepool": {
        "name": "Lifepool",
        "desc": "Increase the maximum health of the target. Cost: 100 MP.",
        "borderColor": "#f00",
        "spellGiven": "Lifepool",
        "icon": "ball-glow"
    },
    "Manapool": {
        "name": "Manapool",
        "desc": "Increase the maximum mana of the target. Cost: 100 MP.",
        "borderColor": "#f00",
        "spellGiven": "Manapool",
        "icon": "ball-glow"
    },
    "Barkskin": {
        "name": "Barkskin",
        "desc": "Increase the physical resistance of the target. Cost: 35 MP.",
        "borderColor": "#f00",
        "spellGiven": "Barkskin",
        "icon": "tree-branch"
    },
    "BulgingMuscles": {
        "name": "BulgingMuscles",
        "desc": "Increase the physical damage of the target. Cost: 50 MP.",
        "borderColor": "#f00",
        "spellGiven": "BulgingMuscles",
        "icon": "strong"
    },
    "Spellshield": {
        "name": "Spellshield",
        "desc": "Increase the saving throw capabilities of the target. Cost: 50 MP.",
        "borderColor": "#f00",
        "spellGiven": "Spellshield",
        "icon": "shield-echoes"
    },
    "DeftStrikes": {
        "name": "DeftStrikes",
        "desc": "Increase the number of attacks for the target. Cost: 50 MP.",
        "borderColor": "#f00",
        "spellGiven": "DeftStrikes",
        "icon": "sword-brandish"
    },
    "CombatPower": {
        "name": "CombatPower",
        "desc": "Increase the defense and offense of the target. Cost: 50 MP.",
        "borderColor": "#f00",
        "spellGiven": "CombatPower",
        "icon": "swords-emblem"
    },
    "ThreateningAura": {
        "name": "ThreateningAura",
        "desc": "Increase the threat generation of the target. Cost: 50 MP.",
        "borderColor": "#f00",
        "spellGiven": "ThreateningAura",
        "icon": "aura"
    },
    "Antidote": {
        "borderColor": "#0a0",
        "name": "Antidote",
        "desc": "Cure poison from a single target. Cost: 10 MP",
        "icon": "miracle-medecine",
        "spellGiven": "Antidote"
    },
    "Vision": {
        "borderColor": "#0a0",
        "name": "Vision",
        "desc": "Cure blindness from a single target. Cost: 10 MP",
        "icon": "evil-eyes",
        "spellGiven": "Vision"
    },
    "Poison": {
        "name": "Poison",
        "desc": "Poison a single target. Cost: 25 MP.",
        "borderColor": "#0a0",
        "spellGiven": "Poison",
        "icon": "poison-gas"
    },
    "Venom": {
        "name": "Venom",
        "desc": "Inflict venom on a single target. Cost: 30 MP.",
        "borderColor": "#0a0",
        "spellGiven": "Venom",
        "icon": "dripping-goo"
    },
    "Disease": {
        "name": "Disease",
        "desc": "Inflict disease on a single target. Cost: 25 MP.",
        "borderColor": "#0c0",
        "spellGiven": "Disease",
        "icon": "death-juice"
    },
    "Plague": {
        "name": "Plague",
        "desc": "Inflict plague on a single target. Cost: 15 MP.",
        "borderColor": "#0c0",
        "spellGiven": "Plague",
        "icon": "death-zone"
    },
    "Stun": {
        "name": "Stun",
        "desc": "Stun a single target. Cost: 25 MP.",
        "borderColor": "#ccc",
        "spellGiven": "Stun",
        "icon": "knockout"
    },
    "Snare": {
        "name": "Snare",
        "desc": "Snare a single target. Cost: 25 MP.",
        "borderColor": "#ccc",
        "spellGiven": "Snare",
        "icon": "light-thorny-triskelion"
    },
    "Daze": {
        "name": "Daze",
        "desc": "Daze a single target. Cost: 25 MP.",
        "borderColor": "#ccc",
        "spellGiven": "Daze",
        "icon": "knockout"
    },
    "Blind": {
        "name": "Blind",
        "desc": "Blind a single target. Cost: 25 MP.",
        "borderColor": "#ccc",
        "spellGiven": "Blind",
        "icon": "evil-eyes"
    },
    "Debilitate": {
        "name": "Debilitate",
        "desc": "Make all hidden attacks against your target count as backstabs. Cost: 100 MP",
        "borderColor": "#ccc",
        "spellGiven": "Debilitate",
        "icon": "one-eyed"
    },
    "Fear": {
        "name": "Fear",
        "desc": "Make your target run away from you. Cost: 50 MP",
        "borderColor": "#ccc",
        "spellGiven": "Fear",
        "icon": "screaming"
    },
    "Afflict": {
        "borderColor": "#0f0",
        "name": "Afflict",
        "desc": "Inflict necrotic damage on a single target. Cost: 10 MP.",
        "icon": "bolas",
        "spellGiven": "Afflict"
    },
    "MagicMissile": {
        "name": "Magic Missile",
        "desc": "Inflict energy damage on a single target. Cost: 5 MP.",
        "borderColor": "#a0a",
        "spellGiven": "MagicMissile",
        "icon": "missile-swarm"
    },
    "MagicBolt": {
        "name": "Magic Bolt",
        "borderColor": "#a0a",
        "desc": "Inflict energy damage on a single target. Cost: 20 MP",
        "icon": "burning-dot",
        "spellGiven": "MagicBolt"
    },
    "Combust": {
        "borderColor": "#a00",
        "name": "Combust",
        "desc": "Inflict fire damage on a single target. Cost: 10 MP",
        "icon": "burning-dot",
        "spellGiven": "Combust"
    },
    "Hail": {
        "borderColor": "#00a",
        "name": "Hail",
        "desc": "Inflict ice damage on a single target. Cost: 10 MP",
        "icon": "burning-dot",
        "spellGiven": "Hail"
    },
    "HolyFire": {
        "name": "Holy Fire",
        "borderColor": "#a00",
        "desc": "Inflict fire damage on a single target and cast light. Cost: 25 MP",
        "icon": "fireflake",
        "spellGiven": "HolyFire"
    },
    "EnergyWave": {
        "name": "Energy Wave",
        "borderColor": "#a0a",
        "desc": "Inflict energy damage in a 5x5 AoE from your location and push each target. Cost: 35 MP.",
        "icon": "beams-aura",
        "spellGiven": "EnergyWave"
    },
    "Antipode": {
        "borderColor": "#00a",
        "name": "Antipode",
        "desc": "Inflict ice and fire damage on a single target. Cost: 25 MP",
        "icon": "frostfire",
        "spellGiven": "Antipode"
    },
    "IceMist": {
        "name": "Ice Mist",
        "borderColor": "#00a",
        "desc": "Inflict ice damage in a 3x3 AoE. Cost: 25 MP",
        "icon": "kaleidoscope-pearls",
        "spellGiven": "IceMist"
    },
    "FireMist": {
        "name": "Fire Mist",
        "borderColor": "#a00",
        "desc": "Inflict fire damage in a 3x3 AoE. Cost: 25 MP",
        "icon": "kaleidoscope-pearls",
        "spellGiven": "FireMist"
    },
    "Drain": {
        "borderColor": "#ccc",
        "name": "Drain",
        "desc": "Drain health from your target. Cost: 10 MP",
        "icon": "wind-hole",
        "spellGiven": "Drain"
    },
    "Asper": {
        "borderColor": "#ccc",
        "name": "Asper",
        "desc": "Drain mana from your target. Cost: 10 MP",
        "icon": "wind-hole",
        "spellGiven": "Asper"
    },
    "RiftSlash": {
        "name": "Rift Slash",
        "borderColor": "#a0a",
        "desc": "Warp through the ether to your target and attack them. Cost: 10 MP",
        "icon": "running-ninja",
        "spellGiven": "RiftSlash"
    },
    "Cure": {
        "borderColor": "#aa0",
        "name": "Cure",
        "desc": "Heal a single target. Cost: 5 MP.",
        "icon": "tentacle-heart",
        "spellGiven": "Cure"
    },
    "PowerwordHeal": {
        "name": "Powerword Heal",
        "borderColor": "#aa0",
        "desc": "Heal your entire party. Cost: 100 MP.",
        "icon": "tentacle-heart",
        "spellGiven": "PowerwordHeal"
    },
    "Revive": {
        "borderColor": "#aa0",
        "name": "Revive",
        "desc": "Revive a player on the ground on your tile. Cost: 50 MP",
        "icon": "quicksand",
        "spellGiven": "Revive"
    },
    "Resurrect": {
        "borderColor": "#a00",
        "name": "Resurrect",
        "desc": "Resurrect a corpse to fight for you.",
        "icon": "quicksand",
        "spellGiven": "Resurrect"
    },
    "Shred": {
        "borderColor": "#a00",
        "name": "Shred",
        "desc": "Shred the opponents HP.",
        "icon": "bleeding-wound",
        "spellGiven": "Shred"
    },
    "PowerBalladSong": {
        "name": "Power Ballad",
        "desc": "Sing a song that buffs nearby allies' offensive stats. Cost: 5 Stealth/round",
        "borderColor": "#ccc",
        "spellGiven": "PowerBalladSong",
        "icon": "music-spell"
    },
    "WistfulFugueSong": {
        "name": "Wistful Fugue",
        "desc": "Sing a song that debuffs nearby enemies' defensive stats. Cost: 5 Stealth/round",
        "borderColor": "#ccc",
        "spellGiven": "WistfulFugueSong",
        "icon": "music-spell"
    },
    "DeadlyDirgeSong": {
        "name": "Deadly Dirge",
        "desc": "Sing a song that does sonic damage to nearby enemies. Cost: 5 Stealth/round",
        "borderColor": "#808",
        "spellGiven": "DeadlyDirgeSong",
        "icon": "music-spell"
    },
    "TranquilTrillSong": {
        "name": "Tranquil Trill",
        "desc": "Sing a song that heals nearby allies. Cost: 5 Stealth/round",
        "borderColor": "#080",
        "spellGiven": "TranquilTrillSong",
        "icon": "music-spell"
    },
    "AriaOfRefugeSong": {
        "name": "Aria of Refuge",
        "desc": "Sing a song that buffs nearby allies' resistances. Cost: 5 Stealth/round",
        "borderColor": "#ccc",
        "spellGiven": "AriaOfRefugeSong",
        "icon": "music-spell"
    },
    "NightmareSerenadeSong": {
        "name": "Nightmare Serenade",
        "desc": "Sing a song that debuffs nearby enemies' resistances. Cost: 5 Stealth/round",
        "borderColor": "#ccc",
        "spellGiven": "NightmareSerenadeSong",
        "icon": "music-spell"
    },
    "VolcanoStance": {
        "name": "Volcano Stance",
        "desc": "Adopt a molten offensive stance, boosting outgoing fire damage, making physical attacks hit for bonus fire damage, increasing offense and lowering defense.",
        "borderColor": "#a00",
        "spellGiven": "VolcanoStance",
        "icon": "fire-silhouette"
    },
    "GlacierStance": {
        "name": "Glacier Stance",
        "desc": "Adopt a frozen defensive stance, boosting outgoing frost damage, making physical attackers take frost damage, increasing defense and lowering offense.",
        "borderColor": "#00a",
        "spellGiven": "GlacierStance",
        "icon": "frozen-orb"
    },
    "WizardStance": {
        "name": "Wizard Stance",
        "desc": "Adopt a stance of true wizardry, lowering incoming damage from all sources and automatically firing bolts of energy when attacked magically.",
        "borderColor": "#a0a",
        "spellGiven": "WizardStance",
        "icon": "burning-passion"
    },
    "ParryStance": {
        "name": "Parry Stance",
        "desc": "Adopt a defensive stance, taunting foes, increasing defense and lowering offense.",
        "borderColor": "#ccc",
        "spellGiven": "ParryStance",
        "icon": "surrounded-shield"
    },
    "RageStance": {
        "name": "Rage Stance",
        "desc": "Adopt an offensive stance, generating rage, increasing offense and lowering defense.",
        "borderColor": "#ccc",
        "spellGiven": "RageStance",
        "icon": "swords-power"
    },
    "TurtleStance": {
        "name": "Turtle Stance",
        "desc": "Adopt an defensive stance, increasing defense and AGI and lowering offense.",
        "borderColor": "#ccc",
        "spellGiven": "TurtleStance",
        "icon": "turtle-shell"
    },
    "TigerStance": {
        "name": "Tiger Stance",
        "desc": "Adopt an offensive stance, increasing offense and STR and lowering defense.",
        "borderColor": "#ccc",
        "spellGiven": "TigerStance",
        "icon": "tiger-head"
    },
    "FindFamiliarLight": {
        "name": "Find Familiar (Light)",
        "borderColor": "#ccc",
        "desc": "Summon a spirit of light to heal allies. Cost: 50 MP",
        "icon": "double-ringed-orb",
        "spellGiven": "FindFamiliarLight"
    },
    "FindFamiliarNature": {
        "name": "Find Familiar (Nature)",
        "borderColor": "#ccc",
        "desc": "Summon a spirit of nature to debuff enemies. Cost: 50 MP",
        "icon": "tree-branch",
        "spellGiven": "FindFamiliarNature"
    },
    "FindFamiliarWater": {
        "name": "Find Familiar (Water)",
        "borderColor": "#ccc",
        "desc": "Summon a spirit of water to buff allies. Cost: 50 MP",
        "icon": "water-splash",
        "spellGiven": "FindFamiliarWater"
    },
    "FindFamiliarSpider": {
        "name": "Find Familiar (Spider)",
        "borderColor": "#ccc",
        "desc": "Summon a chillspider to fight for you in combat. Cost: 50 MP",
        "icon": "spider-alt",
        "spellGiven": "FindFamiliarSpider"
    },
    "FindFamiliarSalamander": {
        "name": "Find Familiar (Salamander)",
        "borderColor": "#ccc",
        "desc": "Summon a salamander to fight for you in combat. Cost: 50 MP",
        "icon": "salamander",
        "spellGiven": "FindFamiliarSalamander"
    },
    "FindFamiliarBear": {
        "name": "Find Familiar (Bear)",
        "borderColor": "#ccc",
        "desc": "Summon a bear to fight for you in combat. Cost: 50 MP",
        "icon": "polar-bear",
        "spellGiven": "FindFamiliarBear"
    },
    "FindFamiliarWolf": {
        "name": "Find Familiar (Wolf)",
        "borderColor": "#ccc",
        "desc": "Summon a wolf to fight for you in combat. Cost: 50 MP",
        "icon": "wolf-head",
        "spellGiven": "FindFamiliarWolf"
    },
    "FindFamiliarShadowClones": {
        "name": "Shadow Clones",
        "borderColor": "#ccc",
        "desc": "Summon shadow clones to help you slaughter your foes. Cost: 100 MP",
        "icon": "dark-squad",
        "spellGiven": "FindFamiliarShadowClones"
    },
    "FindFamiliarDistraction": {
        "name": "Distraction",
        "borderColor": "#ccc",
        "desc": "Summon a distraction to confuse your enemies. Cost: 100 MP",
        "icon": "dark-squad",
        "spellGiven": "FindFamiliarDistraction"
    },
    "FindFamiliarSnowman": {
        "name": "Find Familiar (Snowman)",
        "borderColor": "#ccc",
        "desc": "Summon a snowman to fight for you in combat. Cost: 0 MP",
        "icon": "snowman",
        "spellGiven": "FindFamiliarSnowman"
    },
    "FindFamiliarTurkey": {
        "name": "Find Familiar (Turkey)",
        "desc": "Summon a turkey to fight for you in combat. Cost: 0 MP",
        "borderColor": "#ccc",
        "spellGiven": "FindFamiliarTurkey",
        "icon": "bird-twitter"
    },
    "FindFamiliarGolem": {
        "name": "Find Familiar (Golem)",
        "borderColor": "#ccc",
        "desc": "Summon a golem to fight for you in combat. Cost: 0 MP",
        "icon": "rock-2",
        "spellGiven": "FindFamiliarGolem"
    },
    "FindFamiliarDragon": {
        "name": "Find Familiar (Dragon)",
        "desc": "Summon a dragon to slay enemies. Cost: 150 MP",
        "spellGiven": "FindFamiliarDragon",
        "icon": "dragon-spiral"
    },
    "Mug": {
        "desc": "You can now mug marks, dealing damage and stealing from them.",
        "borderColor": "#ccc",
        "name": "Mug",
        "icon": "hooded-assassin",
        "spellGiven": "Mug"
    },
    "Backstab": {
        "desc": "You can now backstab targets if you're hidden from them.",
        "borderColor": "#ccc",
        "name": "Backstab",
        "icon": "backstab",
        "spellGiven": "Backstab"
    },
    "Set": {
        "borderColor": "#ccc",
        "name": "Set",
        "desc": "Set a trap from your hand. Traps can be purchased from thief vendors.",
        "icon": "quake-stomp",
        "spellGiven": "Set"
    },
    "Disarm": {
        "borderColor": "#ccc",
        "name": "Disarm",
        "desc": "Disarm a trap on an adjacent tile.",
        "icon": "quake-stomp",
        "spellGiven": "Disarm"
    },
    "Apply": {
        "name": "Apply",
        "desc": "Apply the effects of a bottle to your weapon strikes.",
        "borderColor": "#ccc",
        "spellGiven": "Apply",
        "icon": "bloody-sword"
    },
    "Shadowmeld": {
        "borderColor": "#333",
        "name": "Shadowmeld",
        "desc": "Become one with the shadows for a brief time. Cost: 100 MP.",
        "icon": "hidden",
        "spellGiven": "Shadowmeld"
    },
    "Disguise": {
        "borderColor": "#333",
        "name": "Disguise",
        "desc": "Blend in with your enemies. Cost: 100 MP.",
        "icon": "duality",
        "spellGiven": "Disguise"
    },
    "ChainKunai": {
        "name": "Chain Kunai",
        "borderColor": "#333",
        "desc": "Attack an enemy, pull it to you, and lock it in place briefly. Cost: 25 MP.",
        "icon": "harpoon-chain",
        "spellGiven": "ChainKunai"
    },
    "MultiShot": {
        "name": "Multi Shot",
        "borderColor": "#ccc",
        "desc": "Shoot multiple arrows at a foe.",
        "icon": "double-shot",
        "spellGiven": "MultiShot"
    },
    "Identify": {
        "borderColor": "#ccc",
        "name": "Identify",
        "desc": "Identify the attributes of the item in your right hand. Cost: 15 MP",
        "icon": "uncertainty",
        "spellGiven": "Identify"
    },
    "Foodsense": {
        "borderColor": "#ccc",
        "name": "Foodsense",
        "desc": "Sense the bonuses of the food in your right hand. Cost: 15 MP",
        "icon": "meat",
        "spellGiven": "Foodsense"
    },
    "Gemsense": {
        "borderColor": "#ccc",
        "name": "Gemsense",
        "desc": "Sense the bonuses of the gem in your right hand. Cost: 15 MP",
        "icon": "gem-chain",
        "spellGiven": "Gemsense"
    },
    "Succor": {
        "name": "Succor",
        "desc": "Create a consumable that will let you return to your current location. Cost: 25 MP",
        "borderColor": "#aa0",
        "spellGiven": "Succor",
        "icon": "blackball"
    },
    "Wellspring": {
        "borderColor": "#aa0",
        "name": "Wellspring",
        "desc": "Create a consumable that will quell satiation. Cost: 25 MP",
        "icon": "holy-water",
        "spellGiven": "Wellspring"
    },
    "ConjureHealing": {
        "name": "Conjure Healing",
        "borderColor": "#aa0",
        "desc": "Create a consumable that will heal you. Cost: 25 MP",
        "icon": "brandy-bottle",
        "spellGiven": "ConjureHealing"
    },
    "Augury": {
        "name": "Augury",
        "desc": "Use the birds to divine the location of your target. Cost: 25 MP",
        "borderColor": "#ccc",
        "spellGiven": "Augury",
        "icon": "dove"
    },
    "Push": {
        "borderColor": "#ccc",
        "name": "Push",
        "desc": "Push a single target around. Cost: 15 MP",
        "icon": "swirl-string",
        "spellGiven": "Push"
    },
    "Pull": {
        "borderColor": "#ccc",
        "name": "Pull",
        "desc": "Pull a single target to you. Cost: 15 MP",
        "icon": "swirl-string",
        "spellGiven": "Pull"
    },
    "Dispel": {
        "borderColor": "#ccc",
        "name": "Dispel",
        "desc": "Remove a buff from a target. Cost: 100 MP",
        "icon": "broken-shield",
        "spellGiven": "Dispel"
    },
    "Darkness": {
        "borderColor": "#ccc",
        "name": "Darkness",
        "desc": "Make it harder to see in a certain area. Cost: 15 MP",
        "icon": "dust-cloud",
        "spellGiven": "Darkness"
    },
    "Light": {
        "borderColor": "#ccc",
        "name": "Light",
        "desc": "Remove darkness from a certain area. Cost: 15 MP",
        "icon": "candle-light",
        "spellGiven": "Light"
    },
    "ConjureSword": {
        "name": "Conjure Weapon",
        "borderColor": "#a0a",
        "desc": "Conjure a sword for yourself. Cost: 25 MP",
        "icon": "broadsword",
        "spellGiven": "ConjureSword"
    },
    "ConjureShield": {
        "name": "Conjure Shield",
        "borderColor": "#a0a",
        "desc": "Conjure a shield for yourself. Cost: 25 MP",
        "icon": "round-shield",
        "spellGiven": "ConjureShield"
    },
    "Teleport": {
        "name": "Teleport",
        "desc": "Teleport to a previously-memorized location. Memorize new locations with the memorize command and forget them with the forget command. Cost: 100 MP",
        "borderColor": "#a0a",
        "spellGiven": "Teleport",
        "icon": "teleport"
    },
    "MassTeleport": {
        "name": "Mass Teleport",
        "desc": "Teleport all players on your tile to a previously-memorized location. Memorize new locations with the memorize command and forget them with the forget command. Cost: 100 MP",
        "borderColor": "#a0a",
        "spellGiven": "MassTeleport",
        "icon": "vortex"
    },
    "Vortex": {
        "name": "Vortex",
        "desc": "Create a vortex which sucks in nearby items. Cost: 100 MP",
        "borderColor": "#333",
        "spellGiven": "Vortex",
        "icon": "wind-hole"
    },
    "Transmute": {
        "name": "Transmute",
        "borderColor": "#665600",
        "desc": "Convert the items on the chosen tile to gold. Cost: 15 MP",
        "icon": "coins-pile",
        "spellGiven": "Transmute"
    },
    "Fate": {
        "borderColor": "#333",
        "name": "Fate",
        "desc": "???",
        "icon": "solar-time",
        "spellGiven": "Fate"
    },
    "Reincarnate": {
        "borderColor": "#333",
        "name": "Reincarnate",
        "desc": "Bring a strong foe back to life.",
        "icon": "life-in-the-balance",
        "spellGiven": "Reincarnate"
    },
    "Cleave": {
        "desc": "Cleave a target, consuming 10 Rage and dealing massive damage.",
        "borderColor": "#ccc",
        "name": "Cleave",
        "icon": "blade-fall",
        "spellGiven": "Cleave"
    },
    "Ragerang": {
        "desc": "Throw your weapon at a target, consuming 10 Rage and dealing massive damage.",
        "borderColor": "#ccc",
        "name": "Ragerang",
        "icon": "boomerang-cross",
        "spellGiven": "Ragerang"
    },
    "MadDash": {
        "name": "Mad Dash",
        "desc": "Dash madly at a creature in sight, attacking them and generating 30 Rage.",
        "borderColor": "#ccc",
        "icon": "fire-dash",
        "spellGiven": "MadDash"
    },
    "Provoke": {
        "desc": "Provoke a target, consuming 5 Rage and drawing their attention.",
        "borderColor": "#ccc",
        "name": "Provoke",
        "icon": "enrage",
        "spellGiven": "Provoke"
    },
    "MockingShout": {
        "name": "Mocking Shout",
        "desc": "Provoke all targets around you, consuming 25 Rage and drawing their attention.",
        "borderColor": "#ccc",
        "icon": "shouting",
        "spellGiven": "MockingShout"
    },
    "Thruststrike": {
        "name": "Thruststrike",
        "borderColor": "#ccc",
        "desc": "Charge towards an enemy, attacking with the item in your right hand. Deals more damage than Charge.",
        "icon": "saber-slash",
        "spellGiven": "Thruststrike"
    },
    "Blindstrike": {
        "name": "Blindstrike",
        "borderColor": "#ccc",
        "desc": "Attack a random enemy on your tile with a slightly weak attack. You can use this even if you can't see the target.",
        "icon": "sword-tie",
        "spellGiven": "Blindstrike"
    },
    "Multistrike": {
        "name": "Multistrike",
        "borderColor": "#ccc",
        "desc": "Attack a group of enemies on your tile at the expense of defense.",
        "icon": "sword-spin",
        "spellGiven": "Multistrike"
    },
    "Jumpkick": {
        "name": "Jumpkick",
        "borderColor": "#ccc",
        "desc": "Jump to and kick an enemy.",
        "icon": "high-kick",
        "spellGiven": "Jumpkick"
    },
    "Sweep": {
        "name": "Sweep",
        "borderColor": "#ccc",
        "desc": "Attack multiple creatures on your tile with a kick attack at the expense of defense.",
        "icon": "foot-trip",
        "spellGiven": "Sweep"
    },
    "Rapidpunch": {
        "name": "Rapidpunch",
        "borderColor": "#ccc",
        "desc": "Repeatedly punch a target.",
        "icon": "fire-punch",
        "spellGiven": "Rapidpunch"
    },
    "Boost": {
        "name": "Boost",
        "desc": "Boost your STR, DEX, and AGI by +3.",
        "borderColor": "#000",
        "spellGiven": "Boost",
        "icon": "fist"
    },
    "Shield": {
        "name": "Shield",
        "borderColor": "#000",
        "desc": "Increase your physical and magical resistance for a period of time.",
        "icon": "vibrating-shield",
        "spellGiven": "Shield"
    },
    "ArcaneHunger": {
        "name": "Arcane Hunger",
        "desc": "On spell hit, gain a stack of Arcane Hunger, increasing damage spell cost by +$20% per stack, but making you deal +$10% per stack. Max stacks scale with level.",
        "valuePerTier": 20,
        "icon": "mouth-watering"
    },
    "Cleanse": {
        "name": "Cleanse",
        "desc": "Remove all curses from a single target. Cost: 100 MP.",
        "borderColor": "#aa0",
        "spellGiven": "Cleanse",
        "icon": "warlock-eye"
    },
    "DispellingMissiles": {
        "name": "Dispelling Missiles",
        "desc": "Magic Missiles have a +$5$% chance to cast Dispel.",
        "valuePerTier": 5,
        "icon": "missile-swarm"
    }
}