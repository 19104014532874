{
    "Ancient": {
        "treeOrder": [
            "Ancient"
        ],
        "trees": {
            "Ancient": {
                "desc": "The Ancient tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AncientAnger",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientPotions",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientFortitude",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientGrip",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientSilence",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AbsorptiveArmor",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CommandingPower",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "QuickRecovery",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 10,
                                "name": "ArmorManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "OffensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "DefensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "EnergyManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "FireManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "IceManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "NecroticManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "PoisonManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "MachleumSkin",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 100,
                                "name": "SurvivalTechniques",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 20,
                                "name": "MachleumAura",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Ancient"
            }
        }
    },
    "Core": {
        "treeOrder": [
            "Core"
        ],
        "trees": {
            "Core": {
                "desc": "The Core tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DeathGrip",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "NaturalArmor",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarefulTouch",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EffectivePotions",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EtherRecombobulation",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "InternalFortitude",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarrotVision",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "BigBelt",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "AngryFace",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "SilentStrikes",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "RecombobulativeBarrier",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Core"
            }
        }
    },
    "Mage": {
        "treeOrder": [
            "Core",
            "Mage",
            "Elementalist",
            "Evoker",
            "Battlemage",
            "Ancient"
        ],
        "trees": {
            "Mage": {
                "desc": "The Mage tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "MagicMissile",
                                "treeName": "Mage"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CalmMind",
                                "treeName": "Mage"
                            },
                            {
                                "maxLevel": 5,
                                "name": "ManaPool",
                                "treeName": "Mage"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "BouncingMissiles",
                                "requires": "MagicMissile",
                                "treeName": "Mage"
                            },
                            {
                                "maxLevel": 5,
                                "name": "EnergyFocus",
                                "treeName": "Mage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Push",
                                "treeName": "Mage"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "ConjureHealing",
                                "treeName": "Mage"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "MissileBarrage",
                                "requires": "MagicMissile",
                                "treeName": "Mage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "MagicBolt",
                                "treeName": "Mage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "EnergyWave",
                                "treeName": "Mage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Teleport",
                                "treeName": "Mage"
                            },
                            {
                                "maxLevel": 5,
                                "name": "WandSpecialty",
                                "treeName": "Mage"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "ConcussiveBolt",
                                "requires": "MagicBolt",
                                "treeName": "Mage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "EnergyWaveWiden",
                                "requires": "EnergyWave",
                                "treeName": "Mage"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "BiggerBottles",
                                "requires": "ConjureHealing",
                                "treeName": "Mage"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Clearcasting",
                                "treeName": "Mage"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "ExpandedMemory",
                                "requires": "Teleport",
                                "treeName": "Mage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "MassTeleport",
                                "requires": "Teleport",
                                "treeName": "Mage"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Mage"
            },
            "Elementalist": {
                "desc": "The Elementalist tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Combust",
                                "treeName": "Elementalist"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Hail",
                                "treeName": "Elementalist"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "FireMist",
                                "treeName": "Elementalist"
                            },
                            {
                                "maxLevel": 5,
                                "name": "FireFocus",
                                "treeName": "Elementalist"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "IceFocus",
                                "treeName": "Elementalist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "IceMist",
                                "treeName": "Elementalist"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "ForgedFire",
                                "treeName": "Elementalist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FirethornsAura",
                                "treeName": "Elementalist"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "FrostspikesAura",
                                "treeName": "Elementalist"
                            },
                            {
                                "maxLevel": 5,
                                "name": "ChilledCore",
                                "treeName": "Elementalist"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "FireMistWiden",
                                "requires": "FireMist",
                                "treeName": "Elementalist"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "IceMistWiden",
                                "requires": "IceMist",
                                "treeName": "Elementalist"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Antipode",
                                "treeName": "Elementalist"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Elementalist"
            },
            "Evoker": {
                "desc": "The Evoker tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Identify",
                                "treeName": "Evoker"
                            },
                            {
                                "maxLevel": 1,
                                "name": "TrueSight",
                                "treeName": "Evoker"
                            },
                            {
                                "maxLevel": 1,
                                "name": "BarFire",
                                "treeName": "Evoker"
                            },
                            {
                                "maxLevel": 1,
                                "name": "BarFrost",
                                "treeName": "Evoker"
                            },
                            {
                                "maxLevel": 1,
                                "name": "BarWater",
                                "treeName": "Evoker"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Protection",
                                "treeName": "Evoker"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Absorption",
                                "treeName": "Evoker"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "DarkVision",
                                "treeName": "Evoker"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "ExpansiveKnowledge",
                                "treeName": "Evoker"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "ThermalBarrier",
                                "treeName": "Evoker"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Darkness",
                                "treeName": "Evoker"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FleetOfFoot",
                                "treeName": "Evoker"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Haste",
                                "treeName": "Evoker"
                            },
                            {
                                "maxLevel": 1,
                                "name": "MagicMirror",
                                "treeName": "Evoker"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Invisibility",
                                "treeName": "Evoker"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Evoker"
            },
            "Battlemage": {
                "desc": "The Battlemage tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "ConjureSword",
                                "treeName": "Battlemage"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "VolcanoStance",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ImbueFlame",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarBear",
                                "treeName": "Battlemage"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "ConjureShield",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "MagicalStrikes",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "GlacierStance",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ImbueFrost",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarWolf",
                                "treeName": "Battlemage"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Drain",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "RiftSlash",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "WizardStance",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ImbueEnergy",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarSpider",
                                "treeName": "Battlemage"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DrainSlash",
                                "requires": "Drain",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Asper",
                                "treeName": "Battlemage"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarSalamander",
                                "treeName": "Battlemage"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "LightenArmor",
                                "treeName": "Battlemage"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AsperSlash",
                                "requires": "Asper",
                                "treeName": "Battlemage"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "StrongMind",
                                "treeName": "Battlemage"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Battlemage"
            },
            "Ancient": {
                "desc": "The Ancient tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AncientAnger",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientPotions",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientFortitude",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientGrip",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientSilence",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AbsorptiveArmor",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CommandingPower",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "QuickRecovery",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 10,
                                "name": "ArmorManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "OffensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "DefensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "EnergyManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "FireManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "IceManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "NecroticManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "PoisonManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "MachleumSkin",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 100,
                                "name": "SurvivalTechniques",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 20,
                                "name": "MachleumAura",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Ancient"
            },
            "Core": {
                "desc": "The Core tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DeathGrip",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "NaturalArmor",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarefulTouch",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EffectivePotions",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EtherRecombobulation",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "InternalFortitude",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarrotVision",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "BigBelt",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "AngryFace",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "SilentStrikes",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "RecombobulativeBarrier",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Core"
            }
        },
        "allTreeTraits": {
            "DeathGrip": {
                "maxLevel": 5,
                "name": "DeathGrip",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "NaturalArmor": {
                "maxLevel": 5,
                "name": "NaturalArmor",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "CarefulTouch": {
                "maxLevel": 5,
                "name": "CarefulTouch",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "EffectivePotions": {
                "maxLevel": 5,
                "name": "EffectivePotions",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "EtherRecombobulation": {
                "maxLevel": 5,
                "name": "EtherRecombobulation",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "InternalFortitude": {
                "maxLevel": 5,
                "name": "InternalFortitude",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "CarrotVision": {
                "maxLevel": 5,
                "name": "CarrotVision",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "BigBelt": {
                "maxLevel": 1,
                "name": "BigBelt",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "AngryFace": {
                "maxLevel": 5,
                "name": "AngryFace",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "SilentStrikes": {
                "maxLevel": 5,
                "name": "SilentStrikes",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "RecombobulativeBarrier": {
                "maxLevel": 5,
                "name": "RecombobulativeBarrier",
                "treeName": "Core",
                "requiredLevel": 40
            },
            "MagicMissile": {
                "maxLevel": 1,
                "name": "MagicMissile",
                "treeName": "Mage",
                "requiredLevel": 0
            },
            "CalmMind": {
                "maxLevel": 5,
                "name": "CalmMind",
                "treeName": "Mage",
                "requiredLevel": 0
            },
            "ManaPool": {
                "maxLevel": 5,
                "name": "ManaPool",
                "treeName": "Mage",
                "requiredLevel": 0
            },
            "BouncingMissiles": {
                "maxLevel": 1,
                "name": "BouncingMissiles",
                "requires": "MagicMissile",
                "treeName": "Mage",
                "requiredLevel": 10
            },
            "EnergyFocus": {
                "maxLevel": 5,
                "name": "EnergyFocus",
                "treeName": "Mage",
                "requiredLevel": 10
            },
            "Push": {
                "maxLevel": 1,
                "name": "Push",
                "treeName": "Mage",
                "requiredLevel": 10
            },
            "ConjureHealing": {
                "maxLevel": 1,
                "name": "ConjureHealing",
                "treeName": "Mage",
                "requiredLevel": 10
            },
            "MissileBarrage": {
                "maxLevel": 1,
                "name": "MissileBarrage",
                "requires": "MagicMissile",
                "treeName": "Mage",
                "requiredLevel": 20
            },
            "MagicBolt": {
                "maxLevel": 1,
                "name": "MagicBolt",
                "treeName": "Mage",
                "requiredLevel": 20
            },
            "EnergyWave": {
                "maxLevel": 1,
                "name": "EnergyWave",
                "treeName": "Mage",
                "requiredLevel": 20
            },
            "Teleport": {
                "maxLevel": 1,
                "name": "Teleport",
                "treeName": "Mage",
                "requiredLevel": 20
            },
            "WandSpecialty": {
                "maxLevel": 5,
                "name": "WandSpecialty",
                "treeName": "Mage",
                "requiredLevel": 20
            },
            "ConcussiveBolt": {
                "maxLevel": 5,
                "name": "ConcussiveBolt",
                "requires": "MagicBolt",
                "treeName": "Mage",
                "requiredLevel": 30
            },
            "EnergyWaveWiden": {
                "maxLevel": 1,
                "name": "EnergyWaveWiden",
                "requires": "EnergyWave",
                "treeName": "Mage",
                "requiredLevel": 30
            },
            "BiggerBottles": {
                "maxLevel": 1,
                "name": "BiggerBottles",
                "requires": "ConjureHealing",
                "treeName": "Mage",
                "requiredLevel": 30
            },
            "Clearcasting": {
                "maxLevel": 1,
                "name": "Clearcasting",
                "treeName": "Mage",
                "requiredLevel": 40
            },
            "ExpandedMemory": {
                "maxLevel": 5,
                "name": "ExpandedMemory",
                "requires": "Teleport",
                "treeName": "Mage",
                "requiredLevel": 40
            },
            "MassTeleport": {
                "maxLevel": 1,
                "name": "MassTeleport",
                "requires": "Teleport",
                "treeName": "Mage",
                "requiredLevel": 40
            },
            "Combust": {
                "maxLevel": 1,
                "name": "Combust",
                "treeName": "Elementalist",
                "requiredLevel": 0
            },
            "Hail": {
                "maxLevel": 1,
                "name": "Hail",
                "treeName": "Elementalist",
                "requiredLevel": 0
            },
            "FireMist": {
                "maxLevel": 1,
                "name": "FireMist",
                "treeName": "Elementalist",
                "requiredLevel": 10
            },
            "FireFocus": {
                "maxLevel": 5,
                "name": "FireFocus",
                "treeName": "Elementalist",
                "requiredLevel": 10
            },
            "IceFocus": {
                "maxLevel": 5,
                "name": "IceFocus",
                "treeName": "Elementalist",
                "requiredLevel": 10
            },
            "IceMist": {
                "maxLevel": 1,
                "name": "IceMist",
                "treeName": "Elementalist",
                "requiredLevel": 10
            },
            "ForgedFire": {
                "maxLevel": 5,
                "name": "ForgedFire",
                "treeName": "Elementalist",
                "requiredLevel": 20
            },
            "FirethornsAura": {
                "maxLevel": 1,
                "name": "FirethornsAura",
                "treeName": "Elementalist",
                "requiredLevel": 20
            },
            "FrostspikesAura": {
                "maxLevel": 1,
                "name": "FrostspikesAura",
                "treeName": "Elementalist",
                "requiredLevel": 20
            },
            "ChilledCore": {
                "maxLevel": 5,
                "name": "ChilledCore",
                "treeName": "Elementalist",
                "requiredLevel": 20
            },
            "FireMistWiden": {
                "maxLevel": 1,
                "name": "FireMistWiden",
                "requires": "FireMist",
                "treeName": "Elementalist",
                "requiredLevel": 30
            },
            "IceMistWiden": {
                "maxLevel": 1,
                "name": "IceMistWiden",
                "requires": "IceMist",
                "treeName": "Elementalist",
                "requiredLevel": 30
            },
            "Antipode": {
                "maxLevel": 1,
                "name": "Antipode",
                "treeName": "Elementalist",
                "requiredLevel": 40
            },
            "Identify": {
                "maxLevel": 1,
                "name": "Identify",
                "treeName": "Evoker",
                "requiredLevel": 0
            },
            "TrueSight": {
                "maxLevel": 1,
                "name": "TrueSight",
                "treeName": "Evoker",
                "requiredLevel": 0
            },
            "BarFire": {
                "maxLevel": 1,
                "name": "BarFire",
                "treeName": "Evoker",
                "requiredLevel": 0
            },
            "BarFrost": {
                "maxLevel": 1,
                "name": "BarFrost",
                "treeName": "Evoker",
                "requiredLevel": 0
            },
            "BarWater": {
                "maxLevel": 1,
                "name": "BarWater",
                "treeName": "Evoker",
                "requiredLevel": 0
            },
            "Protection": {
                "maxLevel": 1,
                "name": "Protection",
                "treeName": "Evoker",
                "requiredLevel": 10
            },
            "Absorption": {
                "maxLevel": 1,
                "name": "Absorption",
                "treeName": "Evoker",
                "requiredLevel": 10
            },
            "DarkVision": {
                "maxLevel": 1,
                "name": "DarkVision",
                "treeName": "Evoker",
                "requiredLevel": 10
            },
            "ExpansiveKnowledge": {
                "maxLevel": 1,
                "name": "ExpansiveKnowledge",
                "treeName": "Evoker",
                "requiredLevel": 20
            },
            "ThermalBarrier": {
                "maxLevel": 5,
                "name": "ThermalBarrier",
                "treeName": "Evoker",
                "requiredLevel": 20
            },
            "Darkness": {
                "maxLevel": 1,
                "name": "Darkness",
                "treeName": "Evoker",
                "requiredLevel": 20
            },
            "FleetOfFoot": {
                "maxLevel": 1,
                "name": "FleetOfFoot",
                "treeName": "Evoker",
                "requiredLevel": 20
            },
            "Haste": {
                "maxLevel": 1,
                "name": "Haste",
                "treeName": "Evoker",
                "requiredLevel": 30
            },
            "MagicMirror": {
                "maxLevel": 1,
                "name": "MagicMirror",
                "treeName": "Evoker",
                "requiredLevel": 30
            },
            "Invisibility": {
                "maxLevel": 1,
                "name": "Invisibility",
                "treeName": "Evoker",
                "requiredLevel": 40
            },
            "ConjureSword": {
                "maxLevel": 1,
                "name": "ConjureSword",
                "treeName": "Battlemage",
                "requiredLevel": 0
            },
            "VolcanoStance": {
                "maxLevel": 1,
                "name": "VolcanoStance",
                "treeName": "Battlemage",
                "requiredLevel": 0
            },
            "ImbueFlame": {
                "maxLevel": 1,
                "name": "ImbueFlame",
                "treeName": "Battlemage",
                "requiredLevel": 0
            },
            "FindFamiliarBear": {
                "maxLevel": 1,
                "name": "FindFamiliarBear",
                "treeName": "Battlemage",
                "requiredLevel": 0
            },
            "ConjureShield": {
                "maxLevel": 1,
                "name": "ConjureShield",
                "treeName": "Battlemage",
                "requiredLevel": 10
            },
            "MagicalStrikes": {
                "maxLevel": 1,
                "name": "MagicalStrikes",
                "treeName": "Battlemage",
                "requiredLevel": 10
            },
            "GlacierStance": {
                "maxLevel": 1,
                "name": "GlacierStance",
                "treeName": "Battlemage",
                "requiredLevel": 10
            },
            "ImbueFrost": {
                "maxLevel": 1,
                "name": "ImbueFrost",
                "treeName": "Battlemage",
                "requiredLevel": 10
            },
            "FindFamiliarWolf": {
                "maxLevel": 1,
                "name": "FindFamiliarWolf",
                "treeName": "Battlemage",
                "requiredLevel": 10
            },
            "Drain": {
                "maxLevel": 1,
                "name": "Drain",
                "treeName": "Battlemage",
                "requiredLevel": 20
            },
            "RiftSlash": {
                "maxLevel": 1,
                "name": "RiftSlash",
                "treeName": "Battlemage",
                "requiredLevel": 20
            },
            "WizardStance": {
                "maxLevel": 1,
                "name": "WizardStance",
                "treeName": "Battlemage",
                "requiredLevel": 20
            },
            "ImbueEnergy": {
                "maxLevel": 1,
                "name": "ImbueEnergy",
                "treeName": "Battlemage",
                "requiredLevel": 20
            },
            "FindFamiliarSpider": {
                "maxLevel": 1,
                "name": "FindFamiliarSpider",
                "treeName": "Battlemage",
                "requiredLevel": 20
            },
            "DrainSlash": {
                "maxLevel": 5,
                "name": "DrainSlash",
                "requires": "Drain",
                "treeName": "Battlemage",
                "requiredLevel": 30
            },
            "Asper": {
                "maxLevel": 1,
                "name": "Asper",
                "treeName": "Battlemage",
                "requiredLevel": 30
            },
            "FindFamiliarSalamander": {
                "maxLevel": 1,
                "name": "FindFamiliarSalamander",
                "treeName": "Battlemage",
                "requiredLevel": 30
            },
            "LightenArmor": {
                "maxLevel": 1,
                "name": "LightenArmor",
                "treeName": "Battlemage",
                "requiredLevel": 40
            },
            "AsperSlash": {
                "maxLevel": 5,
                "name": "AsperSlash",
                "requires": "Asper",
                "treeName": "Battlemage",
                "requiredLevel": 40
            },
            "StrongMind": {
                "maxLevel": 5,
                "name": "StrongMind",
                "treeName": "Battlemage",
                "requiredLevel": 40
            },
            "AncientAnger": {
                "maxLevel": 5,
                "name": "AncientAnger",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientPotions": {
                "maxLevel": 5,
                "name": "AncientPotions",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientFortitude": {
                "maxLevel": 5,
                "name": "AncientFortitude",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientGrip": {
                "maxLevel": 5,
                "name": "AncientGrip",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientSilence": {
                "maxLevel": 5,
                "name": "AncientSilence",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AbsorptiveArmor": {
                "maxLevel": 5,
                "name": "AbsorptiveArmor",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "CommandingPower": {
                "maxLevel": 5,
                "name": "CommandingPower",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "QuickRecovery": {
                "maxLevel": 5,
                "name": "QuickRecovery",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "ArmorManifestation": {
                "maxLevel": 10,
                "name": "ArmorManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "OffensiveManifestation": {
                "maxLevel": 10,
                "name": "OffensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "DefensiveManifestation": {
                "maxLevel": 10,
                "name": "DefensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "EnergyManifestation": {
                "maxLevel": 20,
                "name": "EnergyManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "FireManifestation": {
                "maxLevel": 20,
                "name": "FireManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "IceManifestation": {
                "maxLevel": 20,
                "name": "IceManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "NecroticManifestation": {
                "maxLevel": 20,
                "name": "NecroticManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "PoisonManifestation": {
                "maxLevel": 20,
                "name": "PoisonManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "MachleumSkin": {
                "maxLevel": 20,
                "name": "MachleumSkin",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "SurvivalTechniques": {
                "maxLevel": 100,
                "name": "SurvivalTechniques",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "MachleumAura": {
                "maxLevel": 20,
                "name": "MachleumAura",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            }
        }
    },
    "Healer": {
        "treeOrder": [
            "Core",
            "Healer",
            "Diviner",
            "Druid",
            "Oracle",
            "Ancient"
        ],
        "trees": {
            "Healer": {
                "desc": "The Healer tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Cure",
                                "treeName": "Healer"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CalmMind",
                                "treeName": "Healer"
                            },
                            {
                                "maxLevel": 5,
                                "name": "ManaPool",
                                "treeName": "Healer"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "HealingFocus",
                                "requires": "Cure",
                                "treeName": "Healer"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Revive",
                                "requires": "Cure",
                                "treeName": "Healer"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Cleanse",
                                "treeName": "Healer"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Wellspring",
                                "treeName": "Healer"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Succor",
                                "treeName": "Healer"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "PowerwordHeal",
                                "requires": "Cure",
                                "treeName": "Healer"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Regen",
                                "treeName": "Healer"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "SnapHeal",
                                "requires": "Revive",
                                "treeName": "Healer"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "SecondWind",
                                "treeName": "Healer"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Clearcasting",
                                "treeName": "Healer"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Healer"
            },
            "Diviner": {
                "desc": "The Diviner tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Afflict",
                                "treeName": "Diviner"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Light",
                                "treeName": "Diviner"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Foodsense",
                                "treeName": "Diviner"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "NecroticFocus",
                                "treeName": "Diviner"
                            },
                            {
                                "maxLevel": 1,
                                "name": "HolyFire",
                                "treeName": "Diviner"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Spellshield",
                                "treeName": "Diviner"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Push",
                                "treeName": "Diviner"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "HolyAura",
                                "treeName": "Diviner"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "TotemSpecialty",
                                "treeName": "Diviner"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "HolyAffliction",
                                "treeName": "Diviner"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Dispel",
                                "treeName": "Diviner"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Diviner"
            },
            "Druid": {
                "desc": "The Druid tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Plague",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Antidote",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Stun",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Blind",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Vision",
                                "treeName": "Druid"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DiseaseFocus",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Disease",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Daze",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Snare",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarLight",
                                "treeName": "Druid"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "ContagiousPlague",
                                "requires": "Plague",
                                "treeName": "Druid"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "IrresistibleStun",
                                "requires": "Stun",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 5,
                                "name": "BlindingLight",
                                "requires": "Blind",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarWater",
                                "treeName": "Druid"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "DebilitatingDisease",
                                "requires": "Disease",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 5,
                                "name": "DazingOutlook",
                                "requires": "Daze",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Roots",
                                "requires": "Snare",
                                "treeName": "Druid"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarNature",
                                "treeName": "Druid"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "Pandemic",
                                "treeName": "Druid"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Druid"
            },
            "Oracle": {
                "desc": "The Oracle tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "TrueSight",
                                "treeName": "Oracle"
                            },
                            {
                                "maxLevel": 1,
                                "name": "BarFire",
                                "treeName": "Oracle"
                            },
                            {
                                "maxLevel": 1,
                                "name": "BarFrost",
                                "treeName": "Oracle"
                            },
                            {
                                "maxLevel": 1,
                                "name": "BarNecro",
                                "treeName": "Oracle"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "VitalEssence",
                                "treeName": "Oracle"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "NecroticWard",
                                "requires": "BarNecro",
                                "treeName": "Oracle"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Augury",
                                "treeName": "Oracle"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Aid",
                                "treeName": "Oracle"
                            },
                            {
                                "maxLevel": 1,
                                "name": "PowerwordBarFire",
                                "requires": "BarFire",
                                "treeName": "Oracle"
                            },
                            {
                                "maxLevel": 1,
                                "name": "PowerwordBarFrost",
                                "requires": "BarFrost",
                                "treeName": "Oracle"
                            },
                            {
                                "maxLevel": 1,
                                "name": "PowerwordBarNecro",
                                "requires": "BarNecro",
                                "treeName": "Oracle"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EffectiveSupporter",
                                "treeName": "Oracle"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Autoheal",
                                "treeName": "Oracle"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Oracle"
            },
            "Ancient": {
                "desc": "The Ancient tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AncientAnger",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientPotions",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientFortitude",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientGrip",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientSilence",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AbsorptiveArmor",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CommandingPower",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "QuickRecovery",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 10,
                                "name": "ArmorManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "OffensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "DefensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "EnergyManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "FireManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "IceManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "NecroticManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "PoisonManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "MachleumSkin",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 100,
                                "name": "SurvivalTechniques",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 20,
                                "name": "MachleumAura",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Ancient"
            },
            "Core": {
                "desc": "The Core tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DeathGrip",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "NaturalArmor",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarefulTouch",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EffectivePotions",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EtherRecombobulation",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "InternalFortitude",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarrotVision",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "BigBelt",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "AngryFace",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "SilentStrikes",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "RecombobulativeBarrier",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Core"
            }
        },
        "allTreeTraits": {
            "DeathGrip": {
                "maxLevel": 5,
                "name": "DeathGrip",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "NaturalArmor": {
                "maxLevel": 5,
                "name": "NaturalArmor",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "CarefulTouch": {
                "maxLevel": 5,
                "name": "CarefulTouch",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "EffectivePotions": {
                "maxLevel": 5,
                "name": "EffectivePotions",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "EtherRecombobulation": {
                "maxLevel": 5,
                "name": "EtherRecombobulation",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "InternalFortitude": {
                "maxLevel": 5,
                "name": "InternalFortitude",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "CarrotVision": {
                "maxLevel": 5,
                "name": "CarrotVision",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "BigBelt": {
                "maxLevel": 1,
                "name": "BigBelt",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "AngryFace": {
                "maxLevel": 5,
                "name": "AngryFace",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "SilentStrikes": {
                "maxLevel": 5,
                "name": "SilentStrikes",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "RecombobulativeBarrier": {
                "maxLevel": 5,
                "name": "RecombobulativeBarrier",
                "treeName": "Core",
                "requiredLevel": 40
            },
            "Cure": {
                "maxLevel": 1,
                "name": "Cure",
                "treeName": "Healer",
                "requiredLevel": 0
            },
            "CalmMind": {
                "maxLevel": 5,
                "name": "CalmMind",
                "treeName": "Healer",
                "requiredLevel": 0
            },
            "ManaPool": {
                "maxLevel": 5,
                "name": "ManaPool",
                "treeName": "Healer",
                "requiredLevel": 0
            },
            "HealingFocus": {
                "maxLevel": 5,
                "name": "HealingFocus",
                "requires": "Cure",
                "treeName": "Healer",
                "requiredLevel": 10
            },
            "Revive": {
                "maxLevel": 1,
                "name": "Revive",
                "requires": "Cure",
                "treeName": "Healer",
                "requiredLevel": 10
            },
            "Cleanse": {
                "maxLevel": 1,
                "name": "Cleanse",
                "treeName": "Healer",
                "requiredLevel": 10
            },
            "Wellspring": {
                "maxLevel": 1,
                "name": "Wellspring",
                "treeName": "Healer",
                "requiredLevel": 10
            },
            "Succor": {
                "maxLevel": 1,
                "name": "Succor",
                "treeName": "Healer",
                "requiredLevel": 10
            },
            "PowerwordHeal": {
                "maxLevel": 1,
                "name": "PowerwordHeal",
                "requires": "Cure",
                "treeName": "Healer",
                "requiredLevel": 20
            },
            "Regen": {
                "maxLevel": 1,
                "name": "Regen",
                "treeName": "Healer",
                "requiredLevel": 20
            },
            "SnapHeal": {
                "maxLevel": 1,
                "name": "SnapHeal",
                "requires": "Revive",
                "treeName": "Healer",
                "requiredLevel": 30
            },
            "SecondWind": {
                "maxLevel": 1,
                "name": "SecondWind",
                "treeName": "Healer",
                "requiredLevel": 30
            },
            "Clearcasting": {
                "maxLevel": 1,
                "name": "Clearcasting",
                "treeName": "Healer",
                "requiredLevel": 40
            },
            "Afflict": {
                "maxLevel": 1,
                "name": "Afflict",
                "treeName": "Diviner",
                "requiredLevel": 0
            },
            "Light": {
                "maxLevel": 1,
                "name": "Light",
                "treeName": "Diviner",
                "requiredLevel": 0
            },
            "Foodsense": {
                "maxLevel": 1,
                "name": "Foodsense",
                "treeName": "Diviner",
                "requiredLevel": 0
            },
            "NecroticFocus": {
                "maxLevel": 5,
                "name": "NecroticFocus",
                "treeName": "Diviner",
                "requiredLevel": 10
            },
            "HolyFire": {
                "maxLevel": 1,
                "name": "HolyFire",
                "treeName": "Diviner",
                "requiredLevel": 10
            },
            "Spellshield": {
                "maxLevel": 1,
                "name": "Spellshield",
                "treeName": "Diviner",
                "requiredLevel": 10
            },
            "Push": {
                "maxLevel": 1,
                "name": "Push",
                "treeName": "Diviner",
                "requiredLevel": 10
            },
            "HolyAura": {
                "maxLevel": 1,
                "name": "HolyAura",
                "treeName": "Diviner",
                "requiredLevel": 20
            },
            "TotemSpecialty": {
                "maxLevel": 5,
                "name": "TotemSpecialty",
                "treeName": "Diviner",
                "requiredLevel": 20
            },
            "HolyAffliction": {
                "maxLevel": 5,
                "name": "HolyAffliction",
                "treeName": "Diviner",
                "requiredLevel": 30
            },
            "Dispel": {
                "maxLevel": 1,
                "name": "Dispel",
                "treeName": "Diviner",
                "requiredLevel": 40
            },
            "Plague": {
                "maxLevel": 1,
                "name": "Plague",
                "treeName": "Druid",
                "requiredLevel": 0
            },
            "Antidote": {
                "maxLevel": 1,
                "name": "Antidote",
                "treeName": "Druid",
                "requiredLevel": 0
            },
            "Stun": {
                "maxLevel": 1,
                "name": "Stun",
                "treeName": "Druid",
                "requiredLevel": 0
            },
            "Blind": {
                "maxLevel": 1,
                "name": "Blind",
                "treeName": "Druid",
                "requiredLevel": 0
            },
            "Vision": {
                "maxLevel": 1,
                "name": "Vision",
                "treeName": "Druid",
                "requiredLevel": 0
            },
            "DiseaseFocus": {
                "maxLevel": 5,
                "name": "DiseaseFocus",
                "treeName": "Druid",
                "requiredLevel": 10
            },
            "Disease": {
                "maxLevel": 1,
                "name": "Disease",
                "treeName": "Druid",
                "requiredLevel": 10
            },
            "Daze": {
                "maxLevel": 1,
                "name": "Daze",
                "treeName": "Druid",
                "requiredLevel": 10
            },
            "Snare": {
                "maxLevel": 1,
                "name": "Snare",
                "treeName": "Druid",
                "requiredLevel": 10
            },
            "FindFamiliarLight": {
                "maxLevel": 1,
                "name": "FindFamiliarLight",
                "treeName": "Druid",
                "requiredLevel": 10
            },
            "ContagiousPlague": {
                "maxLevel": 1,
                "name": "ContagiousPlague",
                "requires": "Plague",
                "treeName": "Druid",
                "requiredLevel": 20
            },
            "IrresistibleStun": {
                "maxLevel": 5,
                "name": "IrresistibleStun",
                "requires": "Stun",
                "treeName": "Druid",
                "requiredLevel": 20
            },
            "BlindingLight": {
                "maxLevel": 5,
                "name": "BlindingLight",
                "requires": "Blind",
                "treeName": "Druid",
                "requiredLevel": 20
            },
            "FindFamiliarWater": {
                "maxLevel": 1,
                "name": "FindFamiliarWater",
                "treeName": "Druid",
                "requiredLevel": 20
            },
            "DebilitatingDisease": {
                "maxLevel": 1,
                "name": "DebilitatingDisease",
                "requires": "Disease",
                "treeName": "Druid",
                "requiredLevel": 30
            },
            "DazingOutlook": {
                "maxLevel": 5,
                "name": "DazingOutlook",
                "requires": "Daze",
                "treeName": "Druid",
                "requiredLevel": 30
            },
            "Roots": {
                "maxLevel": 1,
                "name": "Roots",
                "requires": "Snare",
                "treeName": "Druid",
                "requiredLevel": 30
            },
            "FindFamiliarNature": {
                "maxLevel": 1,
                "name": "FindFamiliarNature",
                "treeName": "Druid",
                "requiredLevel": 30
            },
            "Pandemic": {
                "maxLevel": 5,
                "name": "Pandemic",
                "treeName": "Druid",
                "requiredLevel": 40
            },
            "TrueSight": {
                "maxLevel": 1,
                "name": "TrueSight",
                "treeName": "Oracle",
                "requiredLevel": 0
            },
            "BarFire": {
                "maxLevel": 1,
                "name": "BarFire",
                "treeName": "Oracle",
                "requiredLevel": 0
            },
            "BarFrost": {
                "maxLevel": 1,
                "name": "BarFrost",
                "treeName": "Oracle",
                "requiredLevel": 0
            },
            "BarNecro": {
                "maxLevel": 1,
                "name": "BarNecro",
                "treeName": "Oracle",
                "requiredLevel": 0
            },
            "VitalEssence": {
                "maxLevel": 1,
                "name": "VitalEssence",
                "treeName": "Oracle",
                "requiredLevel": 10
            },
            "NecroticWard": {
                "maxLevel": 5,
                "name": "NecroticWard",
                "requires": "BarNecro",
                "treeName": "Oracle",
                "requiredLevel": 10
            },
            "Augury": {
                "maxLevel": 1,
                "name": "Augury",
                "treeName": "Oracle",
                "requiredLevel": 10
            },
            "Aid": {
                "maxLevel": 1,
                "name": "Aid",
                "treeName": "Oracle",
                "requiredLevel": 20
            },
            "PowerwordBarFire": {
                "maxLevel": 1,
                "name": "PowerwordBarFire",
                "requires": "BarFire",
                "treeName": "Oracle",
                "requiredLevel": 20
            },
            "PowerwordBarFrost": {
                "maxLevel": 1,
                "name": "PowerwordBarFrost",
                "requires": "BarFrost",
                "treeName": "Oracle",
                "requiredLevel": 20
            },
            "PowerwordBarNecro": {
                "maxLevel": 1,
                "name": "PowerwordBarNecro",
                "requires": "BarNecro",
                "treeName": "Oracle",
                "requiredLevel": 20
            },
            "EffectiveSupporter": {
                "maxLevel": 5,
                "name": "EffectiveSupporter",
                "treeName": "Oracle",
                "requiredLevel": 30
            },
            "Autoheal": {
                "maxLevel": 1,
                "name": "Autoheal",
                "treeName": "Oracle",
                "requiredLevel": 40
            },
            "AncientAnger": {
                "maxLevel": 5,
                "name": "AncientAnger",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientPotions": {
                "maxLevel": 5,
                "name": "AncientPotions",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientFortitude": {
                "maxLevel": 5,
                "name": "AncientFortitude",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientGrip": {
                "maxLevel": 5,
                "name": "AncientGrip",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientSilence": {
                "maxLevel": 5,
                "name": "AncientSilence",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AbsorptiveArmor": {
                "maxLevel": 5,
                "name": "AbsorptiveArmor",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "CommandingPower": {
                "maxLevel": 5,
                "name": "CommandingPower",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "QuickRecovery": {
                "maxLevel": 5,
                "name": "QuickRecovery",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "ArmorManifestation": {
                "maxLevel": 10,
                "name": "ArmorManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "OffensiveManifestation": {
                "maxLevel": 10,
                "name": "OffensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "DefensiveManifestation": {
                "maxLevel": 10,
                "name": "DefensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "EnergyManifestation": {
                "maxLevel": 20,
                "name": "EnergyManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "FireManifestation": {
                "maxLevel": 20,
                "name": "FireManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "IceManifestation": {
                "maxLevel": 20,
                "name": "IceManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "NecroticManifestation": {
                "maxLevel": 20,
                "name": "NecroticManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "PoisonManifestation": {
                "maxLevel": 20,
                "name": "PoisonManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "MachleumSkin": {
                "maxLevel": 20,
                "name": "MachleumSkin",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "SurvivalTechniques": {
                "maxLevel": 100,
                "name": "SurvivalTechniques",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "MachleumAura": {
                "maxLevel": 20,
                "name": "MachleumAura",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            }
        }
    },
    "Thief": {
        "treeOrder": [
            "Core",
            "Thief",
            "Rogue",
            "Shadowsong",
            "Assassin",
            "Ancient"
        ],
        "trees": {
            "Thief": {
                "desc": "The Thief tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DarkerShadows",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ImprovedHide",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ThiefGrip",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Identify",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Gemsense",
                                "treeName": "Thief"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "BouncingThrows",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Darkness",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 1,
                                "name": "DarkVision",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 5,
                                "name": "HiddenHealing",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 5,
                                "name": "ReplenishingShadows",
                                "treeName": "Thief"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Multithrow",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Disguise",
                                "treeName": "Thief"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Appraise",
                                "requires": "Identify",
                                "treeName": "Thief"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "DarknessWiden",
                                "requires": "Darkness",
                                "treeName": "Thief"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ChainKunai",
                                "treeName": "Thief"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "BoomerangArm",
                                "treeName": "Thief"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Thief"
            },
            "Rogue": {
                "desc": "The Rogue tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "ImprovedSteal",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 1,
                                "name": "TrueSight",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 1,
                                "name": "GentleStep",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Set",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Disarm",
                                "requires": "Set",
                                "treeName": "Rogue"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Mug",
                                "requires": "ImprovedSteal",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 5,
                                "name": "OffhandFinesse",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 5,
                                "name": "LockpickSpecialty",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ThrownTraps",
                                "requires": "Set",
                                "treeName": "Rogue"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "NimbleFingers",
                                "requires": "ImprovedSteal",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 1,
                                "name": "EagleEye",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Transmute",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 5,
                                "name": "StrongerTraps",
                                "requires": "Set",
                                "treeName": "Rogue"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Vortex",
                                "treeName": "Rogue"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "WiderTraps",
                                "requires": "Set",
                                "treeName": "Rogue"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "VortexWiden",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 5,
                                "name": "PhilosophersStone",
                                "requires": "Transmute",
                                "treeName": "Rogue"
                            },
                            {
                                "maxLevel": 5,
                                "name": "ReusableTraps",
                                "requires": "Set",
                                "treeName": "Rogue"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Rogue"
            },
            "Shadowsong": {
                "desc": "The Shadowsong tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "BouncingArrows",
                                "treeName": "Shadowsong"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "DeadlyDirgeSong",
                                "treeName": "Shadowsong"
                            },
                            {
                                "maxLevel": 5,
                                "name": "OffensiveEncore",
                                "treeName": "Shadowsong"
                            },
                            {
                                "maxLevel": 5,
                                "name": "DefensiveVoice",
                                "treeName": "Shadowsong"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "MultiShot",
                                "treeName": "Shadowsong"
                            },
                            {
                                "maxLevel": 1,
                                "name": "NightmareSerenadeSong",
                                "treeName": "Shadowsong"
                            },
                            {
                                "maxLevel": 5,
                                "name": "DirgeOfCerberus",
                                "requires": "DeadlyDirgeSong",
                                "treeName": "Shadowsong"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "EndlessQuiver",
                                "treeName": "Shadowsong"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "ShieldingVoice",
                                "treeName": "Shadowsong"
                            },
                            {
                                "maxLevel": 1,
                                "name": "TranquilTrillSong",
                                "treeName": "Shadowsong"
                            },
                            {
                                "maxLevel": 1,
                                "name": "PowerBalladSong",
                                "treeName": "Shadowsong"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "TripleShot",
                                "treeName": "Shadowsong"
                            },
                            {
                                "maxLevel": 1,
                                "name": "AriaOfRefugeSong",
                                "treeName": "Shadowsong"
                            },
                            {
                                "maxLevel": 1,
                                "name": "WistfulFugueSong",
                                "treeName": "Shadowsong"
                            },
                            {
                                "maxLevel": 5,
                                "name": "SoothingTranquility",
                                "requires": "TranquilTrillSong",
                                "treeName": "Shadowsong"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "StrongShots",
                                "treeName": "Shadowsong"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "ReflectingVoice",
                                "treeName": "Shadowsong"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Shadowsong",
                                "treeName": "Shadowsong"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Shadowsong"
            },
            "Assassin": {
                "desc": "The Assassin tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Poison",
                                "treeName": "Assassin"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Backstab",
                                "treeName": "Assassin"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Apply",
                                "treeName": "Assassin"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Venom",
                                "treeName": "Assassin"
                            },
                            {
                                "maxLevel": 5,
                                "name": "PoisonFocus",
                                "requires": "Poison",
                                "treeName": "Assassin"
                            },
                            {
                                "maxLevel": 5,
                                "name": "BetterBackstab",
                                "requires": "Backstab",
                                "treeName": "Assassin"
                            },
                            {
                                "maxLevel": 5,
                                "name": "ShadowDaggers",
                                "treeName": "Assassin"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "CorrosivePoison",
                                "requires": "Poison",
                                "treeName": "Assassin"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "ShadowSheath",
                                "treeName": "Assassin"
                            },
                            {
                                "maxLevel": 5,
                                "name": "EnhancedApplications",
                                "requires": "Apply",
                                "treeName": "Assassin"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "DegenerativeVenom",
                                "requires": "Venom",
                                "treeName": "Assassin"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarShadowClones",
                                "treeName": "Assassin"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Shadowmeld",
                                "treeName": "Assassin"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "ShadowSwap",
                                "treeName": "Assassin"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Debilitate",
                                "treeName": "Assassin"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Assassin"
            },
            "Ancient": {
                "desc": "The Ancient tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AncientAnger",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientPotions",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientFortitude",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientGrip",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientSilence",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AbsorptiveArmor",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CommandingPower",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "QuickRecovery",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 10,
                                "name": "ArmorManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "OffensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "DefensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "EnergyManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "FireManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "IceManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "NecroticManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "PoisonManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "MachleumSkin",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 100,
                                "name": "SurvivalTechniques",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 20,
                                "name": "MachleumAura",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Ancient"
            },
            "Core": {
                "desc": "The Core tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DeathGrip",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "NaturalArmor",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarefulTouch",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EffectivePotions",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EtherRecombobulation",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "InternalFortitude",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarrotVision",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "BigBelt",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "AngryFace",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "SilentStrikes",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "RecombobulativeBarrier",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Core"
            }
        },
        "allTreeTraits": {
            "DeathGrip": {
                "maxLevel": 5,
                "name": "DeathGrip",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "NaturalArmor": {
                "maxLevel": 5,
                "name": "NaturalArmor",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "CarefulTouch": {
                "maxLevel": 5,
                "name": "CarefulTouch",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "EffectivePotions": {
                "maxLevel": 5,
                "name": "EffectivePotions",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "EtherRecombobulation": {
                "maxLevel": 5,
                "name": "EtherRecombobulation",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "InternalFortitude": {
                "maxLevel": 5,
                "name": "InternalFortitude",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "CarrotVision": {
                "maxLevel": 5,
                "name": "CarrotVision",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "BigBelt": {
                "maxLevel": 1,
                "name": "BigBelt",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "AngryFace": {
                "maxLevel": 5,
                "name": "AngryFace",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "SilentStrikes": {
                "maxLevel": 5,
                "name": "SilentStrikes",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "RecombobulativeBarrier": {
                "maxLevel": 5,
                "name": "RecombobulativeBarrier",
                "treeName": "Core",
                "requiredLevel": 40
            },
            "DarkerShadows": {
                "maxLevel": 5,
                "name": "DarkerShadows",
                "treeName": "Thief",
                "requiredLevel": 0
            },
            "ImprovedHide": {
                "maxLevel": 1,
                "name": "ImprovedHide",
                "treeName": "Thief",
                "requiredLevel": 0
            },
            "ThiefGrip": {
                "maxLevel": 1,
                "name": "ThiefGrip",
                "treeName": "Thief",
                "requiredLevel": 0
            },
            "Identify": {
                "maxLevel": 1,
                "name": "Identify",
                "treeName": "Thief",
                "requiredLevel": 0
            },
            "Gemsense": {
                "maxLevel": 1,
                "name": "Gemsense",
                "treeName": "Thief",
                "requiredLevel": 0
            },
            "BouncingThrows": {
                "maxLevel": 5,
                "name": "BouncingThrows",
                "treeName": "Thief",
                "requiredLevel": 10
            },
            "Darkness": {
                "maxLevel": 1,
                "name": "Darkness",
                "treeName": "Thief",
                "requiredLevel": 10
            },
            "DarkVision": {
                "maxLevel": 1,
                "name": "DarkVision",
                "treeName": "Thief",
                "requiredLevel": 10
            },
            "HiddenHealing": {
                "maxLevel": 5,
                "name": "HiddenHealing",
                "treeName": "Thief",
                "requiredLevel": 10
            },
            "ReplenishingShadows": {
                "maxLevel": 5,
                "name": "ReplenishingShadows",
                "treeName": "Thief",
                "requiredLevel": 10
            },
            "Multithrow": {
                "maxLevel": 1,
                "name": "Multithrow",
                "treeName": "Thief",
                "requiredLevel": 20
            },
            "Disguise": {
                "maxLevel": 1,
                "name": "Disguise",
                "treeName": "Thief",
                "requiredLevel": 20
            },
            "Appraise": {
                "maxLevel": 1,
                "name": "Appraise",
                "requires": "Identify",
                "treeName": "Thief",
                "requiredLevel": 20
            },
            "DarknessWiden": {
                "maxLevel": 1,
                "name": "DarknessWiden",
                "requires": "Darkness",
                "treeName": "Thief",
                "requiredLevel": 30
            },
            "ChainKunai": {
                "maxLevel": 1,
                "name": "ChainKunai",
                "treeName": "Thief",
                "requiredLevel": 30
            },
            "BoomerangArm": {
                "maxLevel": 1,
                "name": "BoomerangArm",
                "treeName": "Thief",
                "requiredLevel": 40
            },
            "ImprovedSteal": {
                "maxLevel": 1,
                "name": "ImprovedSteal",
                "treeName": "Rogue",
                "requiredLevel": 0
            },
            "TrueSight": {
                "maxLevel": 1,
                "name": "TrueSight",
                "treeName": "Rogue",
                "requiredLevel": 0
            },
            "GentleStep": {
                "maxLevel": 1,
                "name": "GentleStep",
                "treeName": "Rogue",
                "requiredLevel": 0
            },
            "Set": {
                "maxLevel": 1,
                "name": "Set",
                "treeName": "Rogue",
                "requiredLevel": 0
            },
            "Disarm": {
                "maxLevel": 1,
                "name": "Disarm",
                "requires": "Set",
                "treeName": "Rogue",
                "requiredLevel": 0
            },
            "Mug": {
                "maxLevel": 1,
                "name": "Mug",
                "requires": "ImprovedSteal",
                "treeName": "Rogue",
                "requiredLevel": 10
            },
            "OffhandFinesse": {
                "maxLevel": 5,
                "name": "OffhandFinesse",
                "treeName": "Rogue",
                "requiredLevel": 10
            },
            "LockpickSpecialty": {
                "maxLevel": 5,
                "name": "LockpickSpecialty",
                "treeName": "Rogue",
                "requiredLevel": 10
            },
            "ThrownTraps": {
                "maxLevel": 1,
                "name": "ThrownTraps",
                "requires": "Set",
                "treeName": "Rogue",
                "requiredLevel": 10
            },
            "NimbleFingers": {
                "maxLevel": 5,
                "name": "NimbleFingers",
                "requires": "ImprovedSteal",
                "treeName": "Rogue",
                "requiredLevel": 20
            },
            "EagleEye": {
                "maxLevel": 1,
                "name": "EagleEye",
                "treeName": "Rogue",
                "requiredLevel": 20
            },
            "Transmute": {
                "maxLevel": 1,
                "name": "Transmute",
                "treeName": "Rogue",
                "requiredLevel": 20
            },
            "StrongerTraps": {
                "maxLevel": 5,
                "name": "StrongerTraps",
                "requires": "Set",
                "treeName": "Rogue",
                "requiredLevel": 20
            },
            "Vortex": {
                "maxLevel": 1,
                "name": "Vortex",
                "treeName": "Rogue",
                "requiredLevel": 30
            },
            "WiderTraps": {
                "maxLevel": 1,
                "name": "WiderTraps",
                "requires": "Set",
                "treeName": "Rogue",
                "requiredLevel": 30
            },
            "VortexWiden": {
                "maxLevel": 1,
                "name": "VortexWiden",
                "treeName": "Rogue",
                "requiredLevel": 40
            },
            "PhilosophersStone": {
                "maxLevel": 5,
                "name": "PhilosophersStone",
                "requires": "Transmute",
                "treeName": "Rogue",
                "requiredLevel": 40
            },
            "ReusableTraps": {
                "maxLevel": 5,
                "name": "ReusableTraps",
                "requires": "Set",
                "treeName": "Rogue",
                "requiredLevel": 40
            },
            "BouncingArrows": {
                "maxLevel": 5,
                "name": "BouncingArrows",
                "treeName": "Shadowsong",
                "requiredLevel": 0
            },
            "DeadlyDirgeSong": {
                "maxLevel": 1,
                "name": "DeadlyDirgeSong",
                "treeName": "Shadowsong",
                "requiredLevel": 0
            },
            "OffensiveEncore": {
                "maxLevel": 5,
                "name": "OffensiveEncore",
                "treeName": "Shadowsong",
                "requiredLevel": 0
            },
            "DefensiveVoice": {
                "maxLevel": 5,
                "name": "DefensiveVoice",
                "treeName": "Shadowsong",
                "requiredLevel": 0
            },
            "MultiShot": {
                "maxLevel": 1,
                "name": "MultiShot",
                "treeName": "Shadowsong",
                "requiredLevel": 10
            },
            "NightmareSerenadeSong": {
                "maxLevel": 1,
                "name": "NightmareSerenadeSong",
                "treeName": "Shadowsong",
                "requiredLevel": 10
            },
            "DirgeOfCerberus": {
                "maxLevel": 5,
                "name": "DirgeOfCerberus",
                "requires": "DeadlyDirgeSong",
                "treeName": "Shadowsong",
                "requiredLevel": 10
            },
            "EndlessQuiver": {
                "maxLevel": 5,
                "name": "EndlessQuiver",
                "treeName": "Shadowsong",
                "requiredLevel": 20
            },
            "ShieldingVoice": {
                "maxLevel": 5,
                "name": "ShieldingVoice",
                "treeName": "Shadowsong",
                "requiredLevel": 20
            },
            "TranquilTrillSong": {
                "maxLevel": 1,
                "name": "TranquilTrillSong",
                "treeName": "Shadowsong",
                "requiredLevel": 20
            },
            "PowerBalladSong": {
                "maxLevel": 1,
                "name": "PowerBalladSong",
                "treeName": "Shadowsong",
                "requiredLevel": 20
            },
            "TripleShot": {
                "maxLevel": 1,
                "name": "TripleShot",
                "treeName": "Shadowsong",
                "requiredLevel": 30
            },
            "AriaOfRefugeSong": {
                "maxLevel": 1,
                "name": "AriaOfRefugeSong",
                "treeName": "Shadowsong",
                "requiredLevel": 30
            },
            "WistfulFugueSong": {
                "maxLevel": 1,
                "name": "WistfulFugueSong",
                "treeName": "Shadowsong",
                "requiredLevel": 30
            },
            "SoothingTranquility": {
                "maxLevel": 5,
                "name": "SoothingTranquility",
                "requires": "TranquilTrillSong",
                "treeName": "Shadowsong",
                "requiredLevel": 30
            },
            "StrongShots": {
                "maxLevel": 5,
                "name": "StrongShots",
                "treeName": "Shadowsong",
                "requiredLevel": 40
            },
            "ReflectingVoice": {
                "maxLevel": 5,
                "name": "ReflectingVoice",
                "treeName": "Shadowsong",
                "requiredLevel": 40
            },
            "Shadowsong": {
                "maxLevel": 1,
                "name": "Shadowsong",
                "treeName": "Shadowsong",
                "requiredLevel": 40
            },
            "Poison": {
                "maxLevel": 1,
                "name": "Poison",
                "treeName": "Assassin",
                "requiredLevel": 0
            },
            "Backstab": {
                "maxLevel": 1,
                "name": "Backstab",
                "treeName": "Assassin",
                "requiredLevel": 0
            },
            "Apply": {
                "maxLevel": 1,
                "name": "Apply",
                "treeName": "Assassin",
                "requiredLevel": 0
            },
            "Venom": {
                "maxLevel": 1,
                "name": "Venom",
                "treeName": "Assassin",
                "requiredLevel": 10
            },
            "PoisonFocus": {
                "maxLevel": 5,
                "name": "PoisonFocus",
                "requires": "Poison",
                "treeName": "Assassin",
                "requiredLevel": 10
            },
            "BetterBackstab": {
                "maxLevel": 5,
                "name": "BetterBackstab",
                "requires": "Backstab",
                "treeName": "Assassin",
                "requiredLevel": 10
            },
            "ShadowDaggers": {
                "maxLevel": 5,
                "name": "ShadowDaggers",
                "treeName": "Assassin",
                "requiredLevel": 10
            },
            "CorrosivePoison": {
                "maxLevel": 1,
                "name": "CorrosivePoison",
                "requires": "Poison",
                "treeName": "Assassin",
                "requiredLevel": 20
            },
            "ShadowSheath": {
                "maxLevel": 5,
                "name": "ShadowSheath",
                "treeName": "Assassin",
                "requiredLevel": 20
            },
            "EnhancedApplications": {
                "maxLevel": 5,
                "name": "EnhancedApplications",
                "requires": "Apply",
                "treeName": "Assassin",
                "requiredLevel": 20
            },
            "DegenerativeVenom": {
                "maxLevel": 1,
                "name": "DegenerativeVenom",
                "requires": "Venom",
                "treeName": "Assassin",
                "requiredLevel": 30
            },
            "FindFamiliarShadowClones": {
                "maxLevel": 1,
                "name": "FindFamiliarShadowClones",
                "treeName": "Assassin",
                "requiredLevel": 30
            },
            "Shadowmeld": {
                "maxLevel": 1,
                "name": "Shadowmeld",
                "treeName": "Assassin",
                "requiredLevel": 30
            },
            "ShadowSwap": {
                "maxLevel": 5,
                "name": "ShadowSwap",
                "treeName": "Assassin",
                "requiredLevel": 40
            },
            "Debilitate": {
                "maxLevel": 1,
                "name": "Debilitate",
                "treeName": "Assassin",
                "requiredLevel": 40
            },
            "AncientAnger": {
                "maxLevel": 5,
                "name": "AncientAnger",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientPotions": {
                "maxLevel": 5,
                "name": "AncientPotions",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientFortitude": {
                "maxLevel": 5,
                "name": "AncientFortitude",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientGrip": {
                "maxLevel": 5,
                "name": "AncientGrip",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientSilence": {
                "maxLevel": 5,
                "name": "AncientSilence",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AbsorptiveArmor": {
                "maxLevel": 5,
                "name": "AbsorptiveArmor",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "CommandingPower": {
                "maxLevel": 5,
                "name": "CommandingPower",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "QuickRecovery": {
                "maxLevel": 5,
                "name": "QuickRecovery",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "ArmorManifestation": {
                "maxLevel": 10,
                "name": "ArmorManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "OffensiveManifestation": {
                "maxLevel": 10,
                "name": "OffensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "DefensiveManifestation": {
                "maxLevel": 10,
                "name": "DefensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "EnergyManifestation": {
                "maxLevel": 20,
                "name": "EnergyManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "FireManifestation": {
                "maxLevel": 20,
                "name": "FireManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "IceManifestation": {
                "maxLevel": 20,
                "name": "IceManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "NecroticManifestation": {
                "maxLevel": 20,
                "name": "NecroticManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "PoisonManifestation": {
                "maxLevel": 20,
                "name": "PoisonManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "MachleumSkin": {
                "maxLevel": 20,
                "name": "MachleumSkin",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "SurvivalTechniques": {
                "maxLevel": 100,
                "name": "SurvivalTechniques",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "MachleumAura": {
                "maxLevel": 20,
                "name": "MachleumAura",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            }
        }
    },
    "Warrior": {
        "treeOrder": [
            "Core",
            "Warrior",
            "Paladin",
            "Duelist",
            "Monk",
            "Ancient"
        ],
        "trees": {
            "Warrior": {
                "desc": "The Warrior tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Cleave",
                                "treeName": "Warrior"
                            },
                            {
                                "maxLevel": 5,
                                "name": "SharpVision",
                                "treeName": "Warrior"
                            },
                            {
                                "maxLevel": 5,
                                "name": "SwordTricks",
                                "treeName": "Warrior"
                            },
                            {
                                "maxLevel": 5,
                                "name": "FunkyMoves",
                                "treeName": "Warrior"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "ConsumingRage",
                                "treeName": "Warrior"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "Swashbuckler",
                                "requires": "SwordTricks",
                                "treeName": "Warrior"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DeepCuts",
                                "requires": "Cleave",
                                "treeName": "Warrior"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Ragerang",
                                "treeName": "Warrior"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "MadDash",
                                "treeName": "Warrior"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "TitanGrip",
                                "treeName": "Warrior"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "MagicalSpecialty",
                                "treeName": "Warrior"
                            },
                            {
                                "maxLevel": 1,
                                "name": "StaffSpecialty",
                                "treeName": "Warrior"
                            },
                            {
                                "maxLevel": 1,
                                "name": "BowSpecialty",
                                "treeName": "Warrior"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ThrowingSpecialty",
                                "treeName": "Warrior"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ShortswordSpecialty",
                                "treeName": "Warrior"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Warrior"
            },
            "Paladin": {
                "desc": "The Paladin tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Provoke",
                                "treeName": "Paladin"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "SterlingArmor",
                                "treeName": "Paladin"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "HolyProtection",
                                "treeName": "Paladin"
                            },
                            {
                                "maxLevel": 5,
                                "name": "SilverSkin",
                                "treeName": "Paladin"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Multistrike",
                                "treeName": "Paladin"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "MockingShout",
                                "requires": "Provoke",
                                "treeName": "Paladin"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "Blindstrike",
                                "treeName": "Paladin"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Thruststrike",
                                "treeName": "Paladin"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Multitarget",
                                "requires": "Multistrike",
                                "treeName": "Paladin"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "ReflectiveCoating",
                                "treeName": "Paladin"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Shieldbearer",
                                "treeName": "Paladin"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "Multifocus",
                                "requires": "Multistrike",
                                "treeName": "Paladin"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "MaceSpecialty",
                                "treeName": "Paladin"
                            },
                            {
                                "maxLevel": 1,
                                "name": "TwohandedSpecialty",
                                "treeName": "Paladin"
                            },
                            {
                                "maxLevel": 1,
                                "name": "PolearmSpecialty",
                                "treeName": "Paladin"
                            },
                            {
                                "maxLevel": 1,
                                "name": "DoubleThrust",
                                "requires": "Thruststrike",
                                "treeName": "Paladin"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Paladin"
            },
            "Duelist": {
                "desc": "The Duelist tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "RageStance",
                                "treeName": "Duelist"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "ForcefulStrike",
                                "treeName": "Duelist"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "ParryStance",
                                "treeName": "Duelist"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "OffhandFinesse",
                                "treeName": "Duelist"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "Riposte",
                                "requires": "ParryStance",
                                "treeName": "Duelist"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "ViciousAssault",
                                "requires": "RageStance",
                                "treeName": "Duelist"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "GlowingWeapon",
                                "requires": "ParryStance",
                                "treeName": "Duelist"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "BalancedGrip",
                                "treeName": "Duelist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Boost",
                                "treeName": "Duelist"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "SwordSpecialty",
                                "treeName": "Duelist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "AxeSpecialty",
                                "treeName": "Duelist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "DaggerSpecialty",
                                "treeName": "Duelist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "BoostedBoost",
                                "requires": "Boost",
                                "treeName": "Duelist"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Duelist"
            },
            "Monk": {
                "desc": "The Monk tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Jumpkick",
                                "treeName": "Monk"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "TurtleStance",
                                "treeName": "Monk"
                            },
                            {
                                "maxLevel": 5,
                                "name": "MartialAcuity",
                                "treeName": "Monk"
                            },
                            {
                                "maxLevel": 1,
                                "name": "TigerStance",
                                "treeName": "Monk"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Punchkick",
                                "requires": "Jumpkick",
                                "treeName": "Monk"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Sweep",
                                "treeName": "Monk"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "UnarmoredSavant",
                                "treeName": "Monk"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Shield",
                                "treeName": "Monk"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "StrongSweep",
                                "requires": "Sweep",
                                "treeName": "Monk"
                            },
                            {
                                "maxLevel": 1,
                                "name": "BrassKnuckles",
                                "treeName": "Monk"
                            },
                            {
                                "maxLevel": 5,
                                "name": "MartialAgility",
                                "treeName": "Monk"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Rapidpunch",
                                "treeName": "Monk"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "StunningFist",
                                "treeName": "Monk"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "ImprovedRapidpunch",
                                "treeName": "Monk"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "MartialSpecialty",
                                "treeName": "Monk"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Monk"
            },
            "Ancient": {
                "desc": "The Ancient tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AncientAnger",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientPotions",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientFortitude",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientGrip",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientSilence",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AbsorptiveArmor",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CommandingPower",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "QuickRecovery",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 10,
                                "name": "ArmorManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "OffensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "DefensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "EnergyManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "FireManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "IceManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "NecroticManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "PoisonManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "MachleumSkin",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 100,
                                "name": "SurvivalTechniques",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 20,
                                "name": "MachleumAura",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Ancient"
            },
            "Core": {
                "desc": "The Core tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DeathGrip",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "NaturalArmor",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarefulTouch",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EffectivePotions",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EtherRecombobulation",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "InternalFortitude",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarrotVision",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "BigBelt",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "AngryFace",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "SilentStrikes",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "RecombobulativeBarrier",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Core"
            }
        },
        "allTreeTraits": {
            "DeathGrip": {
                "maxLevel": 5,
                "name": "DeathGrip",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "NaturalArmor": {
                "maxLevel": 5,
                "name": "NaturalArmor",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "CarefulTouch": {
                "maxLevel": 5,
                "name": "CarefulTouch",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "EffectivePotions": {
                "maxLevel": 5,
                "name": "EffectivePotions",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "EtherRecombobulation": {
                "maxLevel": 5,
                "name": "EtherRecombobulation",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "InternalFortitude": {
                "maxLevel": 5,
                "name": "InternalFortitude",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "CarrotVision": {
                "maxLevel": 5,
                "name": "CarrotVision",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "BigBelt": {
                "maxLevel": 1,
                "name": "BigBelt",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "AngryFace": {
                "maxLevel": 5,
                "name": "AngryFace",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "SilentStrikes": {
                "maxLevel": 5,
                "name": "SilentStrikes",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "RecombobulativeBarrier": {
                "maxLevel": 5,
                "name": "RecombobulativeBarrier",
                "treeName": "Core",
                "requiredLevel": 40
            },
            "Cleave": {
                "maxLevel": 1,
                "name": "Cleave",
                "treeName": "Warrior",
                "requiredLevel": 0
            },
            "SharpVision": {
                "maxLevel": 5,
                "name": "SharpVision",
                "treeName": "Warrior",
                "requiredLevel": 0
            },
            "SwordTricks": {
                "maxLevel": 5,
                "name": "SwordTricks",
                "treeName": "Warrior",
                "requiredLevel": 0
            },
            "FunkyMoves": {
                "maxLevel": 5,
                "name": "FunkyMoves",
                "treeName": "Warrior",
                "requiredLevel": 0
            },
            "ConsumingRage": {
                "maxLevel": 1,
                "name": "ConsumingRage",
                "treeName": "Warrior",
                "requiredLevel": 10
            },
            "Swashbuckler": {
                "maxLevel": 5,
                "name": "Swashbuckler",
                "requires": "SwordTricks",
                "treeName": "Warrior",
                "requiredLevel": 10
            },
            "DeepCuts": {
                "maxLevel": 5,
                "name": "DeepCuts",
                "requires": "Cleave",
                "treeName": "Warrior",
                "requiredLevel": 20
            },
            "Ragerang": {
                "maxLevel": 1,
                "name": "Ragerang",
                "treeName": "Warrior",
                "requiredLevel": 20
            },
            "MadDash": {
                "maxLevel": 1,
                "name": "MadDash",
                "treeName": "Warrior",
                "requiredLevel": 20
            },
            "TitanGrip": {
                "maxLevel": 1,
                "name": "TitanGrip",
                "treeName": "Warrior",
                "requiredLevel": 30
            },
            "MagicalSpecialty": {
                "maxLevel": 1,
                "name": "MagicalSpecialty",
                "treeName": "Warrior",
                "requiredLevel": 40
            },
            "StaffSpecialty": {
                "maxLevel": 1,
                "name": "StaffSpecialty",
                "treeName": "Warrior",
                "requiredLevel": 40
            },
            "BowSpecialty": {
                "maxLevel": 1,
                "name": "BowSpecialty",
                "treeName": "Warrior",
                "requiredLevel": 40
            },
            "ThrowingSpecialty": {
                "maxLevel": 1,
                "name": "ThrowingSpecialty",
                "treeName": "Warrior",
                "requiredLevel": 40
            },
            "ShortswordSpecialty": {
                "maxLevel": 1,
                "name": "ShortswordSpecialty",
                "treeName": "Warrior",
                "requiredLevel": 40
            },
            "Provoke": {
                "maxLevel": 1,
                "name": "Provoke",
                "treeName": "Paladin",
                "requiredLevel": 0
            },
            "SterlingArmor": {
                "maxLevel": 5,
                "name": "SterlingArmor",
                "treeName": "Paladin",
                "requiredLevel": 0
            },
            "HolyProtection": {
                "maxLevel": 5,
                "name": "HolyProtection",
                "treeName": "Paladin",
                "requiredLevel": 10
            },
            "SilverSkin": {
                "maxLevel": 5,
                "name": "SilverSkin",
                "treeName": "Paladin",
                "requiredLevel": 10
            },
            "Multistrike": {
                "maxLevel": 1,
                "name": "Multistrike",
                "treeName": "Paladin",
                "requiredLevel": 10
            },
            "MockingShout": {
                "maxLevel": 1,
                "name": "MockingShout",
                "requires": "Provoke",
                "treeName": "Paladin",
                "requiredLevel": 20
            },
            "Blindstrike": {
                "maxLevel": 1,
                "name": "Blindstrike",
                "treeName": "Paladin",
                "requiredLevel": 20
            },
            "Thruststrike": {
                "maxLevel": 1,
                "name": "Thruststrike",
                "treeName": "Paladin",
                "requiredLevel": 20
            },
            "Multitarget": {
                "maxLevel": 1,
                "name": "Multitarget",
                "requires": "Multistrike",
                "treeName": "Paladin",
                "requiredLevel": 20
            },
            "ReflectiveCoating": {
                "maxLevel": 5,
                "name": "ReflectiveCoating",
                "treeName": "Paladin",
                "requiredLevel": 30
            },
            "Shieldbearer": {
                "maxLevel": 1,
                "name": "Shieldbearer",
                "treeName": "Paladin",
                "requiredLevel": 30
            },
            "Multifocus": {
                "maxLevel": 5,
                "name": "Multifocus",
                "requires": "Multistrike",
                "treeName": "Paladin",
                "requiredLevel": 30
            },
            "MaceSpecialty": {
                "maxLevel": 1,
                "name": "MaceSpecialty",
                "treeName": "Paladin",
                "requiredLevel": 40
            },
            "TwohandedSpecialty": {
                "maxLevel": 1,
                "name": "TwohandedSpecialty",
                "treeName": "Paladin",
                "requiredLevel": 40
            },
            "PolearmSpecialty": {
                "maxLevel": 1,
                "name": "PolearmSpecialty",
                "treeName": "Paladin",
                "requiredLevel": 40
            },
            "DoubleThrust": {
                "maxLevel": 1,
                "name": "DoubleThrust",
                "requires": "Thruststrike",
                "treeName": "Paladin",
                "requiredLevel": 40
            },
            "RageStance": {
                "maxLevel": 1,
                "name": "RageStance",
                "treeName": "Duelist",
                "requiredLevel": 0
            },
            "ForcefulStrike": {
                "maxLevel": 5,
                "name": "ForcefulStrike",
                "treeName": "Duelist",
                "requiredLevel": 0
            },
            "ParryStance": {
                "maxLevel": 1,
                "name": "ParryStance",
                "treeName": "Duelist",
                "requiredLevel": 0
            },
            "OffhandFinesse": {
                "maxLevel": 5,
                "name": "OffhandFinesse",
                "treeName": "Duelist",
                "requiredLevel": 10
            },
            "Riposte": {
                "maxLevel": 5,
                "name": "Riposte",
                "requires": "ParryStance",
                "treeName": "Duelist",
                "requiredLevel": 10
            },
            "ViciousAssault": {
                "maxLevel": 5,
                "name": "ViciousAssault",
                "requires": "RageStance",
                "treeName": "Duelist",
                "requiredLevel": 20
            },
            "GlowingWeapon": {
                "maxLevel": 5,
                "name": "GlowingWeapon",
                "requires": "ParryStance",
                "treeName": "Duelist",
                "requiredLevel": 20
            },
            "BalancedGrip": {
                "maxLevel": 1,
                "name": "BalancedGrip",
                "treeName": "Duelist",
                "requiredLevel": 30
            },
            "Boost": {
                "maxLevel": 1,
                "name": "Boost",
                "treeName": "Duelist",
                "requiredLevel": 30
            },
            "SwordSpecialty": {
                "maxLevel": 1,
                "name": "SwordSpecialty",
                "treeName": "Duelist",
                "requiredLevel": 40
            },
            "AxeSpecialty": {
                "maxLevel": 1,
                "name": "AxeSpecialty",
                "treeName": "Duelist",
                "requiredLevel": 40
            },
            "DaggerSpecialty": {
                "maxLevel": 1,
                "name": "DaggerSpecialty",
                "treeName": "Duelist",
                "requiredLevel": 40
            },
            "BoostedBoost": {
                "maxLevel": 1,
                "name": "BoostedBoost",
                "requires": "Boost",
                "treeName": "Duelist",
                "requiredLevel": 40
            },
            "Jumpkick": {
                "maxLevel": 1,
                "name": "Jumpkick",
                "treeName": "Monk",
                "requiredLevel": 0
            },
            "TurtleStance": {
                "maxLevel": 1,
                "name": "TurtleStance",
                "treeName": "Monk",
                "requiredLevel": 0
            },
            "MartialAcuity": {
                "maxLevel": 5,
                "name": "MartialAcuity",
                "treeName": "Monk",
                "requiredLevel": 0
            },
            "TigerStance": {
                "maxLevel": 1,
                "name": "TigerStance",
                "treeName": "Monk",
                "requiredLevel": 0
            },
            "Punchkick": {
                "maxLevel": 1,
                "name": "Punchkick",
                "requires": "Jumpkick",
                "treeName": "Monk",
                "requiredLevel": 10
            },
            "Sweep": {
                "maxLevel": 1,
                "name": "Sweep",
                "treeName": "Monk",
                "requiredLevel": 10
            },
            "UnarmoredSavant": {
                "maxLevel": 1,
                "name": "UnarmoredSavant",
                "treeName": "Monk",
                "requiredLevel": 10
            },
            "Shield": {
                "maxLevel": 1,
                "name": "Shield",
                "treeName": "Monk",
                "requiredLevel": 10
            },
            "StrongSweep": {
                "maxLevel": 5,
                "name": "StrongSweep",
                "requires": "Sweep",
                "treeName": "Monk",
                "requiredLevel": 20
            },
            "BrassKnuckles": {
                "maxLevel": 1,
                "name": "BrassKnuckles",
                "treeName": "Monk",
                "requiredLevel": 20
            },
            "MartialAgility": {
                "maxLevel": 5,
                "name": "MartialAgility",
                "treeName": "Monk",
                "requiredLevel": 20
            },
            "Rapidpunch": {
                "maxLevel": 1,
                "name": "Rapidpunch",
                "treeName": "Monk",
                "requiredLevel": 30
            },
            "StunningFist": {
                "maxLevel": 5,
                "name": "StunningFist",
                "treeName": "Monk",
                "requiredLevel": 30
            },
            "ImprovedRapidpunch": {
                "maxLevel": 5,
                "name": "ImprovedRapidpunch",
                "treeName": "Monk",
                "requiredLevel": 40
            },
            "MartialSpecialty": {
                "maxLevel": 1,
                "name": "MartialSpecialty",
                "treeName": "Monk",
                "requiredLevel": 40
            },
            "AncientAnger": {
                "maxLevel": 5,
                "name": "AncientAnger",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientPotions": {
                "maxLevel": 5,
                "name": "AncientPotions",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientFortitude": {
                "maxLevel": 5,
                "name": "AncientFortitude",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientGrip": {
                "maxLevel": 5,
                "name": "AncientGrip",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientSilence": {
                "maxLevel": 5,
                "name": "AncientSilence",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AbsorptiveArmor": {
                "maxLevel": 5,
                "name": "AbsorptiveArmor",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "CommandingPower": {
                "maxLevel": 5,
                "name": "CommandingPower",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "QuickRecovery": {
                "maxLevel": 5,
                "name": "QuickRecovery",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "ArmorManifestation": {
                "maxLevel": 10,
                "name": "ArmorManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "OffensiveManifestation": {
                "maxLevel": 10,
                "name": "OffensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "DefensiveManifestation": {
                "maxLevel": 10,
                "name": "DefensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "EnergyManifestation": {
                "maxLevel": 20,
                "name": "EnergyManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "FireManifestation": {
                "maxLevel": 20,
                "name": "FireManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "IceManifestation": {
                "maxLevel": 20,
                "name": "IceManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "NecroticManifestation": {
                "maxLevel": 20,
                "name": "NecroticManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "PoisonManifestation": {
                "maxLevel": 20,
                "name": "PoisonManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "MachleumSkin": {
                "maxLevel": 20,
                "name": "MachleumSkin",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "SurvivalTechniques": {
                "maxLevel": 100,
                "name": "SurvivalTechniques",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "MachleumAura": {
                "maxLevel": 20,
                "name": "MachleumAura",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            }
        }
    },
    "Arcanist": {
        "treeOrder": [
            "Core",
            "Arcanist",
            "Ancient"
        ],
        "trees": {
            "Arcanist": {
                "desc": "The Arcanist tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Invisibility",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 10,
                                "name": "EnergyFocus",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "MagicMissile",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Identify",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 10,
                                "name": "CalmMind",
                                "treeName": "Arcanist"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Absorption",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "TrueSight",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ArcaneHunger",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Vortex",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Teleport",
                                "treeName": "Arcanist"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Barkskin",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "DarkVision",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 2,
                                "name": "MissileBarrage",
                                "requires": "MagicMissile",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "ExpansiveKnowledge",
                                "requires": "Identify",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 10,
                                "name": "WandSpecialty",
                                "treeName": "Arcanist"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "MagicMirror",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarLight",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "WizardStance",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Transmute",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "Asper",
                                "treeName": "Arcanist"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 1,
                                "name": "Spellshield",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 1,
                                "name": "FindFamiliarDragon",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 5,
                                "name": "DispellingMissiles",
                                "requires": "MagicMissile",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 5,
                                "name": "HolyAffliction",
                                "treeName": "Arcanist"
                            },
                            {
                                "maxLevel": 5,
                                "name": "Clearcasting",
                                "treeName": "Arcanist"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Arcanist"
            },
            "Ancient": {
                "desc": "The Ancient tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AncientAnger",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientPotions",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientFortitude",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientGrip",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 5,
                                "name": "AncientSilence",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "AbsorptiveArmor",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CommandingPower",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "QuickRecovery",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 10,
                                "name": "ArmorManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "OffensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 10,
                                "name": "DefensiveManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "EnergyManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "FireManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "IceManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "NecroticManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "maxLevel": 20,
                                "name": "PoisonManifestation",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 20,
                                "name": "MachleumSkin",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 100,
                                "name": "SurvivalTechniques",
                                "isAncient": true,
                                "treeName": "Ancient"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 20,
                                "name": "MachleumAura",
                                "isAncient": true,
                                "treeName": "Ancient"
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Ancient"
            },
            "Core": {
                "desc": "The Core tree",
                "tree": [
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "DeathGrip",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "NaturalArmor",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarefulTouch",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 0
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EffectivePotions",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "EtherRecombobulation",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 10
                    },
                    {
                        "traits": [
                            {
                                "maxLevel": 5,
                                "name": "InternalFortitude",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "CarrotVision",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 1,
                                "name": "BigBelt",
                                "treeName": "Core"
                            }
                        ],
                        "requiredLevel": 20
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "AngryFace",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "SilentStrikes",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 30
                    },
                    {
                        "traits": [
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            },
                            {
                                "maxLevel": 5,
                                "name": "RecombobulativeBarrier",
                                "treeName": "Core"
                            },
                            {
                                "name": ""
                            },
                            {
                                "name": ""
                            }
                        ],
                        "requiredLevel": 40
                    }
                ],
                "name": "Core"
            }
        },
        "allTreeTraits": {
            "DeathGrip": {
                "maxLevel": 5,
                "name": "DeathGrip",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "NaturalArmor": {
                "maxLevel": 5,
                "name": "NaturalArmor",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "CarefulTouch": {
                "maxLevel": 5,
                "name": "CarefulTouch",
                "treeName": "Core",
                "requiredLevel": 0
            },
            "EffectivePotions": {
                "maxLevel": 5,
                "name": "EffectivePotions",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "EtherRecombobulation": {
                "maxLevel": 5,
                "name": "EtherRecombobulation",
                "treeName": "Core",
                "requiredLevel": 10
            },
            "InternalFortitude": {
                "maxLevel": 5,
                "name": "InternalFortitude",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "CarrotVision": {
                "maxLevel": 5,
                "name": "CarrotVision",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "BigBelt": {
                "maxLevel": 1,
                "name": "BigBelt",
                "treeName": "Core",
                "requiredLevel": 20
            },
            "AngryFace": {
                "maxLevel": 5,
                "name": "AngryFace",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "SilentStrikes": {
                "maxLevel": 5,
                "name": "SilentStrikes",
                "treeName": "Core",
                "requiredLevel": 30
            },
            "RecombobulativeBarrier": {
                "maxLevel": 5,
                "name": "RecombobulativeBarrier",
                "treeName": "Core",
                "requiredLevel": 40
            },
            "Invisibility": {
                "maxLevel": 1,
                "name": "Invisibility",
                "treeName": "Arcanist",
                "requiredLevel": 0
            },
            "EnergyFocus": {
                "maxLevel": 10,
                "name": "EnergyFocus",
                "treeName": "Arcanist",
                "requiredLevel": 0
            },
            "MagicMissile": {
                "maxLevel": 1,
                "name": "MagicMissile",
                "treeName": "Arcanist",
                "requiredLevel": 0
            },
            "Identify": {
                "maxLevel": 1,
                "name": "Identify",
                "treeName": "Arcanist",
                "requiredLevel": 0
            },
            "CalmMind": {
                "maxLevel": 10,
                "name": "CalmMind",
                "treeName": "Arcanist",
                "requiredLevel": 0
            },
            "Absorption": {
                "maxLevel": 1,
                "name": "Absorption",
                "treeName": "Arcanist",
                "requiredLevel": 10
            },
            "TrueSight": {
                "maxLevel": 1,
                "name": "TrueSight",
                "treeName": "Arcanist",
                "requiredLevel": 10
            },
            "ArcaneHunger": {
                "maxLevel": 1,
                "name": "ArcaneHunger",
                "treeName": "Arcanist",
                "requiredLevel": 10
            },
            "Vortex": {
                "maxLevel": 1,
                "name": "Vortex",
                "treeName": "Arcanist",
                "requiredLevel": 10
            },
            "Teleport": {
                "maxLevel": 1,
                "name": "Teleport",
                "treeName": "Arcanist",
                "requiredLevel": 10
            },
            "Barkskin": {
                "maxLevel": 1,
                "name": "Barkskin",
                "treeName": "Arcanist",
                "requiredLevel": 20
            },
            "DarkVision": {
                "maxLevel": 1,
                "name": "DarkVision",
                "treeName": "Arcanist",
                "requiredLevel": 20
            },
            "MissileBarrage": {
                "maxLevel": 2,
                "name": "MissileBarrage",
                "requires": "MagicMissile",
                "treeName": "Arcanist",
                "requiredLevel": 20
            },
            "ExpansiveKnowledge": {
                "maxLevel": 1,
                "name": "ExpansiveKnowledge",
                "requires": "Identify",
                "treeName": "Arcanist",
                "requiredLevel": 20
            },
            "WandSpecialty": {
                "maxLevel": 10,
                "name": "WandSpecialty",
                "treeName": "Arcanist",
                "requiredLevel": 20
            },
            "MagicMirror": {
                "maxLevel": 1,
                "name": "MagicMirror",
                "treeName": "Arcanist",
                "requiredLevel": 30
            },
            "FindFamiliarLight": {
                "maxLevel": 1,
                "name": "FindFamiliarLight",
                "treeName": "Arcanist",
                "requiredLevel": 30
            },
            "WizardStance": {
                "maxLevel": 1,
                "name": "WizardStance",
                "treeName": "Arcanist",
                "requiredLevel": 30
            },
            "Transmute": {
                "maxLevel": 1,
                "name": "Transmute",
                "treeName": "Arcanist",
                "requiredLevel": 30
            },
            "Asper": {
                "maxLevel": 1,
                "name": "Asper",
                "treeName": "Arcanist",
                "requiredLevel": 30
            },
            "Spellshield": {
                "maxLevel": 1,
                "name": "Spellshield",
                "treeName": "Arcanist",
                "requiredLevel": 40
            },
            "FindFamiliarDragon": {
                "maxLevel": 1,
                "name": "FindFamiliarDragon",
                "treeName": "Arcanist",
                "requiredLevel": 40
            },
            "DispellingMissiles": {
                "maxLevel": 5,
                "name": "DispellingMissiles",
                "requires": "MagicMissile",
                "treeName": "Arcanist",
                "requiredLevel": 40
            },
            "HolyAffliction": {
                "maxLevel": 5,
                "name": "HolyAffliction",
                "treeName": "Arcanist",
                "requiredLevel": 40
            },
            "Clearcasting": {
                "maxLevel": 5,
                "name": "Clearcasting",
                "treeName": "Arcanist",
                "requiredLevel": 40
            },
            "AncientAnger": {
                "maxLevel": 5,
                "name": "AncientAnger",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientPotions": {
                "maxLevel": 5,
                "name": "AncientPotions",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientFortitude": {
                "maxLevel": 5,
                "name": "AncientFortitude",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientGrip": {
                "maxLevel": 5,
                "name": "AncientGrip",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AncientSilence": {
                "maxLevel": 5,
                "name": "AncientSilence",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 0
            },
            "AbsorptiveArmor": {
                "maxLevel": 5,
                "name": "AbsorptiveArmor",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "CommandingPower": {
                "maxLevel": 5,
                "name": "CommandingPower",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "QuickRecovery": {
                "maxLevel": 5,
                "name": "QuickRecovery",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 10
            },
            "ArmorManifestation": {
                "maxLevel": 10,
                "name": "ArmorManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "OffensiveManifestation": {
                "maxLevel": 10,
                "name": "OffensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "DefensiveManifestation": {
                "maxLevel": 10,
                "name": "DefensiveManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 20
            },
            "EnergyManifestation": {
                "maxLevel": 20,
                "name": "EnergyManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "FireManifestation": {
                "maxLevel": 20,
                "name": "FireManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "IceManifestation": {
                "maxLevel": 20,
                "name": "IceManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "NecroticManifestation": {
                "maxLevel": 20,
                "name": "NecroticManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "PoisonManifestation": {
                "maxLevel": 20,
                "name": "PoisonManifestation",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 30
            },
            "MachleumSkin": {
                "maxLevel": 20,
                "name": "MachleumSkin",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "SurvivalTechniques": {
                "maxLevel": 100,
                "name": "SurvivalTechniques",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            },
            "MachleumAura": {
                "maxLevel": 20,
                "name": "MachleumAura",
                "isAncient": true,
                "treeName": "Ancient",
                "requiredLevel": 40
            }
        }
    }
}