<app-window
  windowName="combatdebug"
  [head]="headTemplate"
  [body]="bodyTemplate"
  [canHide]="true"
  [initialHide]="true"
  [alwaysOnTop]="true"
  [defaultX]="450"
  [defaultY]="100"
  [defaultWidth]="372"
  [defaultHeight]="510"
></app-window>

<ng-template #headTemplate>
  <span class="window-has-more-buttons">
    <span>Combat Debug Logging</span>
  </span>
</ng-template>

<ng-template #bodyTemplate>
  @if (!isLogging) {
  <div class="actions">
    <mat-form-field class="full-width action padding">
      <input
        matInput
        type="number"
        placeholder="# logs"
        [(ngModel)]="totalToLog"
        [disabled]="isLogging"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
    <div class="action">
      <button mat-raised-button color="primary" (click)="toggleLogging()">
        Start Logging
      </button>
    </div>
    <div class="action"></div>
  </div>
  }

  <!-- -->
  @if (isLogging) {
  <div class="actions">
    <div class="action text">
      {{ totalLogged | number }} / {{ totalToLog | number }}
    </div>
    <div class="action">
      <button mat-raised-button color="primary" (click)="toggleLogging()">
        Stop Logging
      </button>
    </div>
    <div class="action">
      <button mat-raised-button color="secondary" (click)="downloadCSV()">
        CSV
      </button>
    </div>
  </div>
  } @if (player(); as player) {
  <div class="stats">
    @for (stat of allStats; track stat) {
    <span
      class="stat"
      [class.hidden]="!player.totalStats[stat.key]"
      [matTooltip]="stat.key"
    >
      {{ stat.display }} {{ player.totalStats[stat.key] }}
    </span>
    }
  </div>
  }

  <!-- -->
  @if (visibleLogInfoForTable.length > 0) {
  <table class="table">
    <thead>
      <tr>
        <th>Type</th>
        <th>Times</th>
        <th>Total Damage</th>
      </tr>
    </thead>
    <tbody>
      @for (log of visibleLogInfoForTable; track log) {
      <tr>
        <td>{{ log.type }}</td>
        <td>{{ log.times | number }}</td>
        <td>{{ log.totalDamage | number }}</td>
      </tr>
      }
    </tbody>
  </table>
  }
</ng-template>
