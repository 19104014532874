<h1 mat-dialog-title>About Land of the Rair</h1>

<div mat-dialog-content>

  <h2>Changelog</h2>

  <p>For changes, you can check the <a href="https://github.com/LandOfTheRair/LandOfTheRair/blob/master/CHANGELOG.md" target="_blank">changelog</a>. While this changelog encompasses most changes, fixes and enhancements, periods of frequent rebalancing are sometimes not tracked.</p>

  <h2>Contributing</h2>

  <p>Want to contribute? Not sure how? There are so many ways you can get involved, whether that be <a href="https://rair.land/docs/subscriber-benefits/" target="_blank">financially</a>, or otherwise. If you want to help the game grow, you can spread the word, contribute maps (we have a great set of <a href="https://rair.land/docs/modkit/" target="_blank">mod tools</a>!), or contribute code. Want to learn more about how you can contribute? <a href="https://github.com/LandOfTheRair/.github/blob/main/CONTRIBUTING.md" target="_blank">Check out this great guide</a>!</p>

  <h2>Credits</h2>

  <p>For a running list of credits regarding major libraries used, art, music, and player created content, <a href="https://rair.land/docs/credits/" target="_blank">check out the credits page on rair.land</a>!</p>

  <h2>Version</h2>

  <p>{{ version }}</p>

  <h2>License</h2>

  <p>Land of the Rair is <a href="https://github.com/LandOfTheRair/LandOfTheRair/blob/master/LICENSE" target="_blank">GPL 3.0</a> licensed, but is <a href="https://github.com/LandOfTheRair/LandOfTheRair" target="_blank">entirely open source</a>!</p>

</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>
