<app-window
  windowName="trainer"
  [head]="headTemplate"
  [body]="bodyTemplate"
  [canHide]="true"
  [initialHide]="true"
  [alwaysOnTop]="true"
  [defaultX]="670"
  [defaultY]="120"
  [defaultWidth]="600"
  [defaultHeight]="444"
></app-window>

<ng-template #headTemplate>
  {{ trainerData()?.npcName || 'Unknown' }} (Trainer)
</ng-template>

<ng-template #bodyTemplate>
  @let trainerInfo = trainerData();
  <div class="trainer-container">
    <div class="trainer-intro">
      <app-npc [sprite]="trainerInfo.npcSprite"></app-npc>

      Hello, adventurer! I can train you to a maximum of level
      {{ trainerInfo.npcMaxLevel }}/{{ trainerInfo.npcMaxSkill }}. I can also
      assess your skills and aid in your training if you so desire!
    </div>

    <div class="buttons">
      @if (player(); as player) {
      <!-- -->
      @if (!player.gainingAXP) {
      <button mat-raised-button color="primary" (click)="train()">
        Level Up
      </button>
      }

      <!-- -->
      @if (player.gainingAXP) {
      <button mat-raised-button color="primary" (click)="ancient()">
        Ancient
      </button>
      } }
      <button
        mat-raised-button
        color="secondary"
        [disabled]="!activeSkill"
        (click)="assess()"
      >
        Assess Skill
      </button>
      <button
        mat-raised-button
        color="secondary"
        [disabled]="!activeSkill"
        (click)="trainSkill()"
      >
        Train Skill
      </button>
      <button mat-raised-button color="warn" (click)="resetTraits()">
        Reset Traits
      </button>
      @if (trainerInfo.npcCanRevive) {
      <button mat-raised-button color="secondary" (click)="recall()">
        Recall Me
      </button>
      <button mat-raised-button color="secondary" (click)="cleanse()">
        Cleanse Me
      </button>
      }

      <!-- -->
      @if (trainerInfo.npcGuildTeleport) {
      <button mat-raised-button color="secondary" (click)="warp()">
        Warp Me
      </button>
      }
    </div>

    <div class="skills">
      @for (skill of skills; track skill) {
      <div
        class="skill"
        (click)="activeSkill = skill"
        [class.active]="activeSkill === skill"
      >
        <app-skill-icon
          [skill]="skill"
          size="large"
          [round]="true"
        ></app-skill-icon>
      </div>
      }
    </div>
  </div>
</ng-template>
