/* eslint-disable */
export const BUILDVARS = {
  "hashes": {
    "creatures": "9c8a67603edc5ce0c6ebd194f737414b",
    "decor": "6b385dc791b0e787673ebbf0d006382e",
    "effects": "c5093c92497b26dd77e0098daada3c52",
    "items": "7a0ef657b471d699779398d85ccca3ad",
    "swimming": "1a4540483aad852eba348d47a6550a27",
    "terrain": "b711c873655488ede38aacceb7ef59e1",
    "walls": "0a970dea273a4b462b43cdcb74db6d96",
    "itemsanimations": "29729463ac627e78a8c6424bfd13e6b5",
    "decoranimations": "215226d02d1d80c138dfb7f2ca000da0",
    "terrainanimations": "b4c69315a3b5f41213062881b6b57eb4"
  },
  "version": {
    "dirty": true,
    "raw": "b594d98-dirty",
    "hash": "b594d98",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "b594d98-dirty",
    "semverString": null
  }
};