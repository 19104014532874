<!-- <app-game-container> will contain all of the game windows -->
<div class="game" [class.hidden]="gameService.inGame() === false">
  <app-map></app-map>
  <app-command-line></app-command-line>
  <app-adventure-log></app-adventure-log>
  <app-inventory-belt></app-inventory-belt>
  <app-inventory-pouch></app-inventory-pouch>
  <app-inventory-sack></app-inventory-sack>
  <app-equipment-main></app-equipment-main>
  <app-equipment-quick></app-equipment-quick>
  <app-player-status></app-player-status>
  <app-character-list></app-character-list>
  <app-macro-bar></app-macro-bar>
  <app-ground></app-ground>
  <app-trainer></app-trainer>
  <app-vendor></app-vendor>
  <app-bank></app-bank>
  <app-traits></app-traits>
  <app-quests></app-quests>
  <app-equipment-view-target></app-equipment-view-target>
  <app-locker></app-locker>
  <app-party></app-party>
  <app-market></app-market>
  <app-tradeskill></app-tradeskill>
  <app-combatdebug></app-combatdebug>
</div>
