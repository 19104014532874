<app-window
  windowName="equipmentViewTarget"
  [head]="headTemplate"
  [body]="bodyTemplate"
  [canHide]="true"
  [initialHide]="true"
  [alwaysOnTop]="true"
  [defaultX]="450"
  [defaultY]="100"
  [defaultWidth]="350"
  [defaultHeight]="385"
></app-window>

<ng-template #headTemplate>
  Viewing @if (char(); as char) {
  {{ char.name }}
  }
</ng-template>

<ng-template #bodyTemplate>
  @if (char(); as char) {
  <app-equipment-viewonly
    [player]="char"
    [hideGold]="true"
  ></app-equipment-viewonly>
  }
</ng-template>
