<h1 mat-dialog-title>New Spells Learned</h1>

<div mat-dialog-content>
  <div>
    You have learned the following spells, would you like to add them to your macro groups?
  </div>

  <div>
    <em>Note: If a macro will not fit in a bar, it will be automatically assigned to the first available location.</em>
  </div>

  <div class="row-container">
    @for (spell of data.newSpells; track spell) {
      <div class="row">
        <div class="icon-name">
          <app-icon [name]="spell.icon" size="xsmall" [fgColor]="spell.color" [bgColor]="spell.bgColor || '#ccc'"></app-icon>
          <span>{{ spell.name }}</span>
        </div>
        <div class="row-buttons">
          <button mat-button (click)="dontAddToBar(spell)">No</button>
          <button mat-button (click)="addToBar(spell)" color="primary">Yes</button>
          <mat-form-field>
            <mat-label>Macro Group</mat-label>
            <mat-select [(value)]="macroBarsByName[spell.name]">
              <mat-option>Any</mat-option>
              @for (bar of macroBarsAddable; track bar) {
                <mat-option [value]="bar.name">{{ bar.name }}</mat-option>
              }
              <mat-option value="__NEW">New Group</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="noToAll()">No To All</button>
  <button mat-button mat-dialog-close color="primary" (click)="yesToAll()">Yes To All</button>
</div>