<app-window
  windowName="adventureLog"
  [head]="headTemplate"
  [body]="bodyTemplate"
  [canHide]="false"
  [defaultX]="4"
  [defaultY]="401"
  [defaultWidth]="380"
  [defaultHeight]="308"
></app-window>

<ng-template #headTemplate>Adventure Log</ng-template>

<ng-template #bodyTemplate>
  @let userLogMode = logMode();

  <div class="buttons">
    @for (tab of chatTabs; track tab) {
    <button
      class="tab-button"
      mat-raised-button
      [color]="tab === userLogMode ? 'primary' : ''"
      (click)="changeTab(tab)"
    >
      {{ tab }}
    </button>
    }
  </div>

  <div class="log-area">
    @for (message of messages(); track $index) {
    <div
      class="message"
      [ngClass]="message.messageTypes"
      [class.hidden]="!isMessageVisible(userLogMode, message)"
      [innerHTML]="message.display"
    ></div>
    }
  </div>
</ng-template>
