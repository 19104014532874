'use strict';

exports.__esModule = true;
exports.URL = exports.TEXT = exports.NL = exports.EMAIL = exports.MAILTOEMAIL = exports.Base = undefined;
var _createTokenClass = require('./create-token-class');
var _class = require('../../utils/class');
var _text = require('./text');

/******************************************************************************
	Multi-Tokens
	Tokens composed of arrays of TextTokens
******************************************************************************/

// Is the given token a valid domain token?
// Should nums be included here?
function isDomainToken(token) {
  return token instanceof _text.DOMAIN || token instanceof _text.TLD;
}

/**
	Abstract class used for manufacturing tokens of text tokens. That is rather
	than the value for a token being a small string of text, it's value an array
	of text tokens.

	Used for grouping together URLs, emails, hashtags, and other potential
	creations.

	@class MultiToken
	@abstract
*/
var MultiToken = (0, _createTokenClass.createTokenClass)();
MultiToken.prototype = {
  /**
  	String representing the type for this token
  	@property type
  	@default 'TOKEN'
  */
  type: 'token',
  /**
  	Is this multitoken a link?
  	@property isLink
  	@default false
  */
  isLink: false,
  /**
  	Return the string this token represents.
  	@method toString
  	@return {String}
  */
  toString: function toString() {
    var result = [];
    for (var i = 0; i < this.v.length; i++) {
      result.push(this.v[i].toString());
    }
    return result.join('');
  },
  /**
  	What should the value for this token be in the `href` HTML attribute?
  	Returns the `.toString` value by default.
  		@method toHref
  	@return {String}
  */
  toHref: function toHref() {
    return this.toString();
  },
  /**
  	Returns a hash of relevant values for this token, which includes keys
  	* type - Kind of token ('url', 'email', etc.)
  	* value - Original text
  	* href - The value that should be added to the anchor tag's href
  		attribute
  		@method toObject
  	@param {String} [protocol] `'http'` by default
  	@return {Object}
  */
  toObject: function toObject() {
    var protocol = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'http';
    return {
      type: this.type,
      value: this.toString(),
      href: this.toHref(protocol)
    };
  }
};

/**
	Represents an arbitrarily mailto email address with the prefix included
	@class MAILTO
	@extends MultiToken
*/
var MAILTOEMAIL = (0, _class.inherits)(MultiToken, (0, _createTokenClass.createTokenClass)(), {
  type: 'email',
  isLink: true
});

/**
	Represents a list of tokens making up a valid email address
	@class EMAIL
	@extends MultiToken
*/
var EMAIL = (0, _class.inherits)(MultiToken, (0, _createTokenClass.createTokenClass)(), {
  type: 'email',
  isLink: true,
  toHref: function toHref() {
    return 'mailto:' + this.toString();
  }
});

/**
	Represents some plain text
	@class TEXT
	@extends MultiToken
*/
var TEXT = (0, _class.inherits)(MultiToken, (0, _createTokenClass.createTokenClass)(), {
  type: 'text'
});

/**
	Multi-linebreak token - represents a line break
	@class NL
	@extends MultiToken
*/
var NL = (0, _class.inherits)(MultiToken, (0, _createTokenClass.createTokenClass)(), {
  type: 'nl'
});

/**
	Represents a list of tokens making up a valid URL
	@class URL
	@extends MultiToken
*/
var URL = (0, _class.inherits)(MultiToken, (0, _createTokenClass.createTokenClass)(), {
  type: 'url',
  isLink: true,
  /**
  	Lowercases relevant parts of the domain and adds the protocol if
  	required. Note that this will not escape unsafe HTML characters in the
  	URL.
  		@method href
  	@param {String} protocol
  	@return {String}
  */
  toHref: function toHref() {
    var protocol = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'http';
    var hasProtocol = false;
    var hasSlashSlash = false;
    var tokens = this.v;
    var result = [];
    var i = 0;

    // Make the first part of the domain lowercase
    // Lowercase protocol
    while (tokens[i] instanceof _text.PROTOCOL) {
      hasProtocol = true;
      result.push(tokens[i].toString().toLowerCase());
      i++;
    }

    // Skip slash-slash
    while (tokens[i] instanceof _text.SLASH) {
      hasSlashSlash = true;
      result.push(tokens[i].toString());
      i++;
    }

    // Lowercase all other characters in the domain
    while (isDomainToken(tokens[i])) {
      result.push(tokens[i].toString().toLowerCase());
      i++;
    }

    // Leave all other characters as they were written
    for (; i < tokens.length; i++) {
      result.push(tokens[i].toString());
    }
    result = result.join('');
    if (!(hasProtocol || hasSlashSlash)) {
      result = protocol + '://' + result;
    }
    return result;
  },
  hasProtocol: function hasProtocol() {
    return this.v[0] instanceof _text.PROTOCOL;
  }
});
exports.Base = MultiToken;
exports.MAILTOEMAIL = MAILTOEMAIL;
exports.EMAIL = EMAIL;
exports.NL = NL;
exports.TEXT = TEXT;
exports.URL = URL;