import { Observable, Subject } from 'rxjs';
const defaultProtocols = [];
const defaultWebsocketFactory = (url, protocols) => new WebSocket(url, protocols);
export const normalClosureMessage = 'Normal closure';
export default function makeWebSocketObservable(url, {
  protocols = defaultProtocols,
  makeWebSocket = defaultWebsocketFactory
} = {
  protocols: defaultProtocols,
  makeWebSocket: defaultWebsocketFactory
}) {
  return new Observable(observer => {
    let inputSubscription;
    const messages = new Subject();
    const getWebSocketResponses = input => {
      if (inputSubscription) {
        setClosedStatus();
        const error = new Error('Web socket message factory function called more than once');
        observer.error(error);
        throw error;
      } else {
        inputSubscription = input.subscribe(data => {
          socket.send(data);
        });
        return messages;
      }
    };
    const socket = makeWebSocket(url, protocols);
    let isSocketOpen = false;
    let forcedClose = false;
    const setClosedStatus = () => {
      isSocketOpen = false;
    };
    socket.onopen = () => {
      isSocketOpen = true;
      if (forcedClose) {
        socket.close();
      } else {
        observer.next(getWebSocketResponses);
      }
    };
    socket.onmessage = message => {
      messages.next(message.data);
    };
    socket.onerror = error => {
      setClosedStatus();
      observer.error(new Error(error.message));
    };
    socket.onclose = event => {
      // prevent observer.complete() being called after observer.error(...)
      if (!isSocketOpen) return;
      setClosedStatus();
      if (forcedClose) {
        observer.complete();
        messages.complete();
      } else {
        observer.error(new Error(event.code === 1000 ? normalClosureMessage : event.reason));
      }
    };
    return () => {
      forcedClose = true;
      if (inputSubscription) inputSubscription.unsubscribe();
      if (isSocketOpen) {
        setClosedStatus();
        socket.close();
      }
    };
  });
}
