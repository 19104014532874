<app-window
  windowName="lobby"
  [head]="headTemplate"
  [body]="bodyTemplate"
  [canMinimize]="true"
  [defaultX]="4"
  [defaultY]="80"
  [defaultWidth]="800"
  [defaultHeight]="500"
></app-window>

<ng-template #headTemplate>
  Global Chat [{{ users().length }} online@if (discordCount) {
  <span>, {{ discordCount }} Discord Player(s)</span>
  }]
</ng-template>

<ng-template #bodyTemplate>
  <div class="container">
    <div class="left-side">
      <div class="user-list">
        <ul>
          @for (user of users(); track user.username) {
          <li class="user">
            @if (user.tier) {
            <span class="user-marking tier-{{ user.tier }}"></span>
            }
            <span class="user-name">{{ user.username }}</span>
            @if (user.inGame) {
            <img
              src="assets/extra/in-game.png"
              height="16"
              width="16"
              class="in-game"
            />
            }
          </li>
          }
        </ul>
      </div>
    </div>

    <div class="right-side">
      <div class="motd">
        System:
        <span [innerHTML]="motd() || 'No MOTD.' | linkify"></span>
      </div>

      <div class="output-area">
        @for (message of messages(); track $index) {
        <div class="message">
          <span class="timestamp">
            [{{ message.timestamp | date : 'hh:mm' }}]
          </span>
          <span class="username">
            {{ message.from }}@if (message.source) {
            <span>[{{ message.source }}]</span>
            }:
          </span>
          <span
            class="usermessage"
            [innerHTML]="message.message | linkify | replaceDiscordEmoji : 16"
          ></span>
        </div>
        }
      </div>

      <div class="input-area">
        <input
          class="input"
          type="text"
          [(ngModel)]="currentMessage"
          [ngModelOptions]="{ standalone: true }"
          (keydown.enter)="sendMessage()"
          placeholder="Chat message here!"
        />

        <button
          mat-flat-button
          [disabled]="!currentMessage"
          (click)="sendMessage()"
          color="accent"
          class="button"
        >
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
